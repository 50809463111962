import * as action_types from "./actionTypes";
import * as action from "./action";

const API_BASE_URL = process.env.REACT_APP_SERVER_URL;

// Login
export const LoginAction = ({ data, api, enqueueSnackbar }) => {
  return (dispatch) => {
    api({
      url: `${API_BASE_URL}salesperson/login`,
      method: "POST",
      data,
    })
      .then((result) => {
        if (result.data.message) {
          const message = result.data.message;
          enqueueSnackbar(message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
        localStorage.setItem("sales_person_token", result.data.data.token);
        localStorage.setItem("sales_person_name", result.data.data.name);
        localStorage.setItem(
          "sales_person_nick_name",
          result.data.data.nick_name
        );

        dispatch(
          action.userSessionAuthenticate(action_types.userSessionAuthenticate)
        );
        return dispatch(
          action.SalesPersonLoginResult(
            action_types.storeLoginToken,
            result.data.data.token,
            result.data.data.nick_name,
            result.data.data.name
          )
        );
      })
      .catch((err) => {
        if (err.response) {
          const message = err.response.data.message;
          enqueueSnackbar(message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      });
  };
};

// Logout
export const LogoutAction = ({ api }) => {
  return (dispatch) => {
    api({
      url: `${API_BASE_URL}salesperson/logout`,
      method: "POST",
    })
      .then((result) => {
        if (result.status === 200) {
          return dispatch(
            action.UpdateLoginResult(
              action_types.removeLoginToken,
              result.data.data.token
            )
          );
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
};

// Country Listing
export const getCountry = async ({ api }) => {
  const response = await api({
    url: `${API_BASE_URL}getCountry`,
    method: `GET`,
  });

  return response?.data;
};

// Customer Listing
export const fetchCustomerList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}salesperson/getCustomerList`,
    method: "POST",
    data,
  });
  return response.data;
};

// Sales Person Listing
export const fetchSalesPersonList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}salesperson/getSalesPersonList`,
    method: "POST",
    data,
  });

  return response?.data;
};

// Sales Person Profile
export const fetchProfileInfo = async ({ api }) => {
  const response = await api({
    url: `${API_BASE_URL}salesperson/getProfile`,
    method: "POST",
  });

  return response?.data;
};

// Product Listing (Campaign)
export const fetchProductList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}salesperson/getProductList`,
    method: "POST",
    data,
  });

  return response?.data;
};

export const fetchSalesList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}salesperson/get-all-sales-list`,
    method: "POST",
    data,
    params: {
      member_id: data.member_id,
      invoice_no: data.invoice_no,
      RefTxCode: data.RefTxCode,
      vmore_ref_no: data.vmore_ref_no,
      date_from: data.date_from ? data.date_from : "",
      date_to: data.date_to ? data.date_to : "",
      isVoid: data.isVoid,
    },
  });

  return response.data;
};

// Create Form - customer
export const postCreateForm = async ({
  api,
  data,
  url,
  method,
  enqueueSnackbar,
}) => {
  const response = await api({
    url: `${API_BASE_URL}salesperson/${url}`,
    method: method,
    data,
  }).catch((err) => {
    const message = err.response.data.message;
    document.getElementById("splash-screen").classList.add("hidden");
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  });

  return response;
};

// Edit Form - profile
export const postEditForm = async ({
  api,
  data,
  url,
  method,
  enqueueSnackbar,
}) => {
  // console.log(data);
  const response = await api({
    url: `${API_BASE_URL}salesperson/${url}`,
    method: method,
    data,
  }).catch((err) => {
    console.log(err);
    const message = err.response.data.message;
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  });

  return response?.data;
};

// General Data
export const getSysGeneralData = async ({ api, type }) => {
  const response = await api({
    url: `${API_BASE_URL}getSystemGeneralData`,
    method: "POST",
    params: {
      type: type,
    },
  });
  return response?.data;
};

// Handle Table Per Rows Change
export const handleDataTablePerRowsChange = async ({ api, url, data }) => {
  const response = await api({
    url: `${API_BASE_URL}salesperson/${url}`,
    method: "POST",
    data,
  });
  return response?.data;
};

// dashboard

export const getDashboardSalesData = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}salesperson/getSalesAmount`,
    method: "POST",
    data,
  });
  return response?.data;
};

// Installment Listing
export const fetchInstallmentList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}salesperson/getInstallmentList`,
    method: "POST",
    data,
  });
  return response?.data;
};

// Installment Details
export const fetchInstallmentDetails = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}salesperson/getInstallmentDetails`,
    method: "POST",
    data,
  });
  return response?.data;
};

export const fetchEcommerceOrderList = async ({ api, data }) => {
	const response = await api({
	  url: `${API_BASE_URL}salesperson/get-ecommerce-sales-list`,
	  method: "POST",
	  data,
	});
  
	return response.data;
  };