import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid,
  useTheme,
  TextField,
  Autocomplete,
} from "@mui/material";
import TextFieldComponent from "../../FormAddInputComponent/TextField";
import AutocompleteComponent from "../../FormAddInputComponent/Autocomplete";
import SelectOptionComponent from "../../FormAddInputComponent/SelectOption";
import DatePickerComponent from "../../FormAddInputComponent/DatePickerField";
import {
  AppBarSpacer,
  CardHeaderCss,
  ButtonCss,
} from "../../../css/Admin-css/listing-css";
import CollapsedBreadcrumbs, {
  BreadcrumbsContext,
} from "../../../layouts/admin/breadcrumb";
import { useForm } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetchCustomerList,
  fetchProductList,
  postCreateForm,
  getSysGeneralData,
} from "../../../actions/admin/actionCreators";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export default function InstallmentSalesForm(props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [breadcrumbArr, setBreadcrumbArr] = React.useState([
    {
      text: "Installment",
      link: "/admin/installment-list",
    },
    {
      text: "Installment Form",
      link: "/admin/installment/create",
    },
  ]);
  const [memberId, setMemberId] = React.useState("");
  const [productId, setProductId] = React.useState("");
  const [productPrice, setProductPrice] = React.useState("");
  const [averagePrice, setAveragePrice] = React.useState(0);
  const [discountPrice, setDiscountPrice] = React.useState(0);
  const [fixedAmount, setFixedAmount] = React.useState(0);
  const [period, setPeriod] = React.useState([]);
  const [memberOptions, setMemberOptions] = React.useState([]);
  const [productOptions, setProductOptions] = React.useState([]);
  const [paymentOptions, setPaymentOptions] = React.useState([]);
  const [paymentType, setPaymentType] = React.useState([]);
  const [docDate, setDocDate] = React.useState([]);

  const memberLoading = memberOptions.length === 0;
  const productLoading = productOptions.length === 0;
  const api = useContext(AxiosContext);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    shouldFocusError: true,
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const onSubmitHandler = handleSubmit(async (data) => {
    document.getElementById("splash-screen").classList.remove("hidden");
    let url = "postInstallment";

    if (!memberId) {
      enqueueSnackbar("Member field is required", {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });

      return false;
    }
    if (!productId) {
      enqueueSnackbar("Product field is required", {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });

      return false;
    }
    data["member_id"] = memberId;
    data["prd_master_id"] = productId;

    data["details"].map((detail, key) => {
      data["details"][key]["date"] = detail.date
        ? convertUTCDateToLocalDate(detail.date)
        : "";
    });

    const response = await postCreateForm({
      api: api,
      data: data,
      method: "POST",
      url: url,
      enqueueSnackbar,
    });
    if (response) {
      document.getElementById("splash-screen").classList.add("hidden");

      enqueueSnackbar(response.data.msg, {
        variant: response.data.msgType,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      if (response.data.msgType == "success") {
        navigate("/admin/installment-list");
      }
    }
  });

  const periodOnChange = (event) => {
    setValue("period", event.target.value);
    if (event.target.value >= 1) {
      let periodArray = [];
      for (let index = 0; index < event.target.value; index++) {
        periodArray.push(index);
      }
      setPeriod(periodArray);
      countPrice(getValues("fixed_amount"));
      countInstallmentPrice();
    } else {
      enqueueSnackbar("Min Installment Period is 1 month", {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });

      return false;
    }
  };

  const countInstallmentPrice = (type = "") => {
    if (productPrice && getValues("period")) {
      const periodVal = getValues("period");
      const fixVal = getValues("fixed_amount");
      const average = (productPrice - fixVal) / periodVal;
      let total_amount = average;

      if (type == "change_value") {
        if (getValues(`[details][0][total_amount]`) != average) {
          total_amount =
            (productPrice - fixVal - getValues(`[details][0][total_amount]`)) /
            (periodVal - 1);
          period.map((val, index) => {
            if (index != 0) {
              setValue(
                `[details][${index}][total_amount]`,
                total_amount.toFixed(2)
              );
            }
          });
        }
      } else {
        setAveragePrice(average);
      }
    }
  };

  const totalAmountOnChange = (event) => {
    setValue(event.target.getAttribute("name"), event.target.value);
    if (event.target.getAttribute("name") == "[details][0][total_amount]") {
      countPrice(getValues("fixed_amount"));
      countInstallmentPrice("change_value");
    }
  };

  const onChangeHandler = (event) => {
    setValue("fixed_amount", event.target.value);
    setFixedAmount(event.target.value);
    countPrice(event.target.value);
    countInstallmentPrice();
  };

  const countPrice = (fix_amount = 0) => {
    if (productPrice > 0) {
      const discount = productPrice - fix_amount;
      console.log(discount);
      if (discount != productPrice) {
        setDiscountPrice(discount.toFixed(2));
      } else {
        setDiscountPrice("");
      }
    } else {
      setFixedAmount("");
      setValue("fixed_amount", "");
      setDiscountPrice("");
    }
  };

  const onSelectChangeHandler = (name, index) => (event) => {
    const value = event.target.value;
    if (name == "payment_type_id") {
      let payment_array = [...paymentType];
      payment_array[index] = value;
      setPaymentType(payment_array);
    }

    setValue(`[details][${index}][payment_type_id]`, value);
  };

  function convertUTCDateToLocalDate(date) {
    // var newDate = new Date(
    //   date.getTime() - date.getTimezoneOffset() * 60 * 1000
    // );
    var formatDate =
      date.getFullYear() +
      "-" +
      parseInt(date.getMonth() + 1) +
      "-" +
      date.getDate();
    return formatDate;
  }

  const onDateChangeHandler = (value, name, index) => {
    if (name == "date") {
      let doc_array = [...docDate];
      doc_array[index] = value;
      setDocDate(doc_array);
    }
    setValue(`[details][${index}][date]`, value);
  };

  const getMemberList = async () => {
    const response = await fetchCustomerList({
      api: api,
    });
    if (response) {
      setMemberOptions(response.data.data);
    }
  };

  const getProductList = async () => {
    const response = await fetchProductList({
      api: api,
    });
    if (response) {
      setProductOptions(response.data.data);
    }
  };

  const getPaymentType = async () => {
    const response = await getSysGeneralData({
      api: api,
      type: "payment-type",
    });
    if (response) {
      setPaymentOptions(response.data.data);
    }
  };

  React.useEffect(() => {
    let active = true;

    if (!memberLoading) {
      return undefined;
    }

    (async () => {
      if (active) {
        getMemberList();
      }
    })();

    return () => {
      active = false;
    };
  }, [memberLoading]);

  React.useEffect(() => {
    let active = true;

    if (!productLoading) {
      return undefined;
    }

    (async () => {
      if (active) {
        getProductList();
      }
    })();

    return () => {
      active = false;
    };
  }, [productLoading]);

  React.useEffect(() => {
    countPrice(getValues("fixed_amount"));
    countInstallmentPrice();
  }, [productPrice]);

  React.useEffect(() => {
    period.map((val, index) => {
      setValue(`[details][${index}][total_amount]`, averagePrice.toFixed(2));
    });
  }, [averagePrice]);

  React.useEffect(() => {
    getPaymentType();
  }, []);

  return (
    <>
      <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]}>
        <CollapsedBreadcrumbs />
      </BreadcrumbsContext.Provider>
      <Card>
        <CardHeaderCss title="Installment Form" />
        <CardContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <AutocompleteComponent
                  id="search-member"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) =>
                    option.full_name +
                    `(${option.email} | ${option.contact_no})`
                  }
                  options={memberOptions}
                  loading={memberLoading}
                  onChange={(event, newInputValue) => {
                    setMemberId(newInputValue.id);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.full_name} ({option.email} | {option.contact_no})
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Member"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {memberLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <Link to="/admin/customer/create">
                  <Typography variant="span">Create New Customer</Typography>
                </Link>
              </Grid>
              <Grid item xs={12} md={6}>
                <AutocompleteComponent
                  id="search-campaign"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => option.name}
                  options={productOptions}
                  loading={productLoading}
                  onChange={(event, newInputValue) => {
                    setProductId(newInputValue.id);
                    setProductPrice(newInputValue.unit_price);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Campaign"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {productLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <Link to="/admin/product/create">
                  <Typography variant="span">Create New Campaign</Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextFieldComponent
                  control={control}
                  name="period"
                  placeholder="Installment Period (month)"
                  label="Installment Period"
                  marginType="normal"
                  type="tel"
                  errors={errors}
                  setValue={setValue}
                  onChangeHandler={periodOnChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={discountPrice ? 6 : 12}>
                    <TextFieldComponent
                      control={control}
                      name="fixed_amount"
                      placeholder="Amount"
                      marginType="normal"
                      label="Discount Amount"
                      type="tel"
                      errors={errors}
                      setValue={setValue}
                      onChangeHandler={onChangeHandler}
                      value={fixedAmount}
                    />
                  </Grid>
                  {discountPrice ? (
                    <Grid item xs={6} md={6}>
                      <TextFieldComponent
                        control={control}
                        name="price"
                        marginType="normal"
                        label="Price After Discount"
                        type="tel"
                        errors={errors}
                        setValue={setValue}
                        disabled
                      />
                      {setValue("price", discountPrice)}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
            <hr />
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h6">Installment Details</Typography>
              </Grid>
            </Grid>
            {period.map((value, index) => {
              return (
                <Grid container spacing={2} key={index}>
                  <Grid
                    item
                    xs={2}
                    md={1}
                    style={{ alignSelf: "center", textAlign: "center" }}
                  >
                    <span>{index + 1}.</span>
                  </Grid>
                  <Grid item xs={10} md={3}>
                    <TextFieldComponent
                      control={control}
                      name={`[details][${index}][total_amount]`}
                      placeholder="Amount"
                      label="Amount"
                      marginType="normal"
                      type="number"
                      errors={errors}
                      setValue={setValue}
                      onChangeHandler={totalAmountOnChange}
                      data-key={index}
                    />
                  </Grid>
                  <Grid item xs={10} md={3}>
                    <DatePickerComponent
                      control={control}
                      name={`[details][${index}][date]`}
                      label="Invoice Date"
                      errors={errors}
                      marginType="normal"
                      value={docDate[index] ?? ""}
                      onChange={(value) => {
                        onDateChangeHandler(value, "date", index);
                      }}
                      setValue={setValue}
                    />
                  </Grid>
                  <Grid item xs={10} md={3}>
                    <SelectOptionComponent
                      control={control}
                      name={`[details][${index}][payment_type_id]`}
                      placeholder="Payment Type"
                      label="Payment Type"
                      option={paymentOptions}
                      errors={errors}
                      setValue={setValue}
                      onChangeHandler={onSelectChangeHandler(
                        "payment_type_id",
                        index
                      )}
                      value={paymentType[index] ?? ""}
                    />
                  </Grid>
                </Grid>
              );
            })}
            <CardActions
              style={{ justifyContent: "center", marginBottom: "16px" }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={onSubmitHandler}
              >
                Save
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </>
  );
}
