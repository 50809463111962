import React, { useContext, useState, useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { AppBarSpacer } from "../../../css/Admin-css/listing-css";
import InstallmentDatatable from "../DataTable/dt-installmentList";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useForm } from "react-hook-form";
import SearchField from "../DataTable/SearchField/SearchField";

import {
  fetchProductList,
  handleDataTablePerRowsChange,
  fetchInstallmentList,
} from "../../../actions/admin/actionCreators";
import CollapsedBreadcrumbs, {
  BreadcrumbsContext,
} from "../../../layouts/admin/breadcrumb";

export default function InstallmentList() {
  const api = useContext(AxiosContext);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [startFrom, setStart] = useState(0);
  const [pending, setPending] = useState(true);
  const [product_id, setProduct] = useState([]);
  const [breadcrumbArr, setBreadcrumbArr] = React.useState([
    {
      text: "Installment List",
      link: "",
    },
  ]);

  const searchField = [
    {
      name: "date_from",
      label: "Installment Date From",
      placeholder: "Installment Date From",
      marginType: "normal",
      type: "date_picker",
    },
    {
      name: "date_to",
      label: "Installment Date To",
      placeholder: "Installment Date To",
      marginType: "normal",
      type: "date_picker",
    },
    {
      name: "installment_no",
      label: "Installment No",
      placeholder: "Installment No",
      marginType: "normal",
      type: "text",
    },
    {
      name: "product_id",
      label: "Campaign",
      placeholder: "Campaign",
      marginType: "none",
      type: "select",
      children: product_id,
    },
    {
        name: "email",
        label: "Email",
        placeholder: "example@email.com",
        marginType: "normal",
        type: "text",
    },
  ];

  const { handleSubmit, control, reset, getValues, setValue } = useForm({
    defaultValues: {
      date_from: null,
      date_to: null,
      installment_no: "",
      product_id: "",
      email: "",
    },
  });

  const getProductData = async () => {
    const productList = [];
    const response = await fetchProductList({
      api: api,
    });
    if (response) {
      if (response.status == 200) {
        response.data.data.map((val) => {
          productList.push({
            value: val.id,
            desc: val.name,
          });
        });
      }
    }
    setProduct(productList);
  };

  function convertUTCDateToLocalDate(date) {
    // var newDate = new Date(
    //   date.getTime() - date.getTimezoneOffset() * 60 * 1000
    // );
    var formatDate =
      date.getFullYear() +
      "-" +
      parseInt(date.getMonth() + 1) +
      "-" +
      date.getDate();
    return formatDate;
  }

  const onSubmit = handleSubmit(async (param) => {
    param["date_from"] = param["date_from"]
      ? convertUTCDateToLocalDate(param["date_from"])
      : "";
    param["date_to"] = param["date_to"]
      ? convertUTCDateToLocalDate(param["date_to"])
      : "";
    param["page"] = startFrom;
    param["per_page"] = perPage;

    const response = await fetchInstallmentList({
      api: api,
      data: param,
    });

    setData(response.data.data);
    setTotalRows(response.data.total_records);
  });

  const onReset = () => {
    reset();
    onSubmit();
  };

  const handlePageChange = async (page) => {
    const startPage = (page - 1) * perPage;
    setStart(startPage);
  };

  const handlePerRowsChange = async (newPerPage) => {
    const param = {};

    param["page"] = 0;
    param["per_page"] = newPerPage;
    param["date_from"] = getValues("date_from");
    param["date_to"] = getValues("date_to");
    param["installment_no"] = getValues("installment_no");
    param["product_id"] = getValues("product_id");
    param["email"] = getValues("email");

    const response = await handleDataTablePerRowsChange({
      api: api,
      url: "getInstallmentList",
      data: param,
    });

    setData(response.data.data);
    setPerPage(newPerPage);
  };

  const getInstallmentData = async () => {
    setPending(true);

    const param = {};
    param["page"] = startFrom;
    param["per_page"] = perPage;
    param["date_from"] = getValues("date_from");
    param["date_to"] = getValues("date_to");
    param["installment_no"] = getValues("installment_no");
    param["product_id"] = getValues("product_id");
    param["email"] = getValues("email");

    const response = await fetchInstallmentList({
      api,
      data,
    });

    if (response) {
      if (response.status == 200) {
        setPending(false);
        if (response.data.total_records > 0) {
          setData(response.data.data);
          setTotalRows(response.data.total_records);
        }
      }
    }
  };
  useEffect(() => {
    getProductData();
    getInstallmentData();
  }, [startFrom]);
  return (
    <>
      <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]}>
        <CollapsedBreadcrumbs />
      </BreadcrumbsContext.Provider>
      <div className="row">
        <div className="col-md-12">
          <SearchField
            control={control}
            onCreate="/admin/installment-sales-form"
            searchField={searchField}
            onSubmit={onSubmit}
            onReset={onReset}
            getValues={getValues}
            setValue={setValue}
          />

          <AppBarSpacer />
          <Card>
            <CardContent>
              <InstallmentDatatable
                totalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fileteredData={data}
                startFrom={startFrom}
                pending={pending}
              ></InstallmentDatatable>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}
