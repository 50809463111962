import React, { useContext, useEffect, useState } from "react";
import { Card, CardActions, CardContent, Button, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import { postEditForm, fetchEditInfoList } from "../../../actions/admin/actionCreators";
import TextFieldComponent from "../../FormAddInputComponent/TextField";
import SelectOptionComponent from "../../FormAddInputComponent/SelectOption";
import { AppBarSpacer, CardHeaderCss } from "../../../css/Admin-css/listing-css";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";

export default function PaymentTypeUpdate(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const api = useContext(AxiosContext);
    const { enqueueSnackbar } = useSnackbar();
    const [status, setStatus] = useState("");
    const location = useLocation();
    const { payment_type_id } = location?.state ? location.state : 0;

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "all",
        defaultValues: {
			code: "",
			name: "",
			seq_no: 1,
            status: "",
		},
    });

    const statusList = [
        { id: "A", name: "Active" },
        { id: "I", name: "Inactive" },
    ];

    const [breadcrumbArr, setBreadcrumbArr] = React.useState([
        {
            text: "Payment Type",
            link: "/admin/payment-type-list",
        },
        {
            text: "Update Payment Type",
            link: "",
        },
    ]);

    const onSubmit = handleSubmit(async (data) => {
        data["payment_type_id"] = payment_type_id;

        document.getElementById("splash-screen").classList.remove("hidden");

        const response = await postEditForm({
            api: api,
            data: data,
            method: "POST",
            url: "updatePaymentType",
            enqueueSnackbar,
        });

        if (response) {
            if (response.status === 200) {
                enqueueSnackbar(response.data.msg, {
                    variant: response.data.msgType,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
                if (response.data.msgType == 'success') navigate("/admin/payment-type-list");
            }
        }
        document.getElementById("splash-screen").classList.add("hidden");
    });

    const getPaymentTypeInfo = async (id) => {
        const response = await fetchEditInfoList({
            api: api,
            url: "getPaymentTypeList",
            data: {
                payment_type_id: id,
            },
            enqueueSnackbar,
            dispatch,
        });

        if (response) {
            if (response.data.data) {
                setValue("code", response.data.data[0].code);
                setValue("name", response.data.data[0].name);
                setValue("seq_no", response.data.data[0].seq_no);
                setValue("status", response.data.data[0].status);
                setStatus(response.data.data[0].status);
            }
        }
    };

    const onSelectChangeHandler = (name) => (event) => {
        const value = event.target.value;
        if (name == "status") {
            setStatus(value);
        }
        setValue(name, value);
    };

    useEffect(() => {
        if (payment_type_id) {
            getPaymentTypeInfo(payment_type_id);
        }
    }, []);

    return (
        <>
            <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]} >
                <CollapsedBreadcrumbs />
            </BreadcrumbsContext.Provider>
            <Card>
                <CardHeaderCss title="Payment Type Form" />
                <CardContent>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="code"
                                    marginType="normal"
                                    label="Code"
                                    type="text"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "This field is required",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="name"
                                    marginType="normal"
                                    label="Name"
                                    type="text"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "This field is required",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="seq_no"
                                    placeholder="Single Number like 1 or 2 or 3"
                                    marginType="normal"
                                    label="Sequence No"
                                    type="number"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "This field is required",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <SelectOptionComponent
                                    control={control}
                                    name="status"
                                    placeholder="Status"
                                    label="Status"
                                    option={statusList}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "This field is required",
                                        },
                                    }}
                                    errors={errors}
                                    onChangeHandler={onSelectChangeHandler("status")}
                                    value={status}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <AppBarSpacer />
            <CardActions
                style={{ justifyContent: "center", marginBottom: "16px" }}
            >
                <div align="center">
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={onSubmit}
                    >
                        Update
                    </Button>
                </div>
            </CardActions>
        </>
    );
}
