import React, { useContext, useEffect, useState } from "react";
import { CardContent } from "@mui/material";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../layouts/salesPerson/breadcrumb";
import { Grid, Typography, Chip, Tab, Paper, Tabs } from "@mui/material";
import {TabPanel, TabContext} from '@mui/lab';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import LineChart  from './lineChart';
import { getDashboardSalesData } from "../../actions/salesPerson/actionCreators";
import { AxiosContext } from "../../app/assets/axios/salesPerson/AxiosComponent";


export default function Dashboard() {
    const api = useContext(AxiosContext);
    const { t } = useTranslation('common');
    const [breadcrumbArr, setBreadcrumbArr] = React.useState([{
        text: "Dashboard",
        link: "",
    }]);
    const Item = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(5),
      }));
      const StyledTabs = styled(Tabs)(() => ({
        root: {
          "& div.MuiTabs-scroller": {
            "& .MuiTabs-flexContainer": {
              justifyContent: 'end'
            }
          }
        }
      }));
    const [sTabValue, setSTabValue] = useState('sales_quarter');
    const shandleChange = (event, newValue) => {
        setSTabValue(newValue);
      };
    const [state, setState] = useState({
        sales: []
      });

      const getSalesData = async () => {
        const response = await getDashboardSalesData({
          api: api,
          data: [],
        });
  
        if (response) {
          if (response.status == 200) {
            setState((prevState) => {
              return {
                ...prevState,
                sales: response.data,
              };
            });
          }
        }
      }
  
      useEffect(() => {
        getSalesData();
      }, []);

    return (
        <>
            <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]} >
                <CollapsedBreadcrumbs />
            </BreadcrumbsContext.Provider>
            <div align="center">
                <h1>{t('salesperson.label.welcome', { project_name: 'META CRM' })}</h1>
                <CardContent style={{ padding: "24px 24px 0 24px" }}>
                </CardContent>
            </div>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 5 }} style={{marginTop: '10px'}}>
              <Grid item xs={12}>
                <Item style={{height: '100%'}}>
                  <Grid container style={{marginBottom: '20px'}}>
                    <Grid item md={6}>
                      <Typography variant="h6">Total Sales</Typography>
                      <Typography variant="p" style={{color: '#A1A5B7'}}>Sales from all Campaigns</Typography>
                    </Grid>
                    {/* <Grid item md={6} style={{textAlign: 'end', alignSelf: 'center'}}>
                      <RouterLink to='/admin/total-sales-list' style={{textDecoration: 'none'}}>
                        <Button variant="contained" color="primary" >View all</Button>
                      </RouterLink>
                    </Grid> */}
                  </Grid>
                  <Grid container style={{marginBottom: '20px'}}>
                    <Grid item xs={12} md={8}>
                      <TabContext value={sTabValue}>
                      <Grid container>
                        <Grid item md={6}>
                        <div style={{display: 'flex'}}>
                          <Typography variant="h5" style={{ color: '#A1A5B7', paddingRight: '1rem'}}>$ </Typography>
                          <Typography variant="h4" style={{marginBottom: '20px'}}>{ Object.keys(state.sales).length > 0 ? Intl.NumberFormat('en-US').format(state.sales.total_sales_amount) : 0}
                          </Typography>
                          </div>
                        </Grid>
                        <Grid item md={6} style={{textAlign: 'end'}}>
                          <StyledTabs value={sTabValue} onChange={shandleChange} TabIndicatorProps={{ style: { display: 'none' } }}>
                            <Tab label="Quarter" value="sales_quarter" style={{fontWeight: '600'}} />
                            <Tab label="Month" value="sales_month" style={{fontWeight: '600'}} />
                            <Tab label="Week" value="sales_week" style={{fontWeight: '600'}} />
                          </StyledTabs>
                        </Grid>
                      </Grid>
                      <Grid container>
                          <Grid item xs={12}>
                            <TabPanel value="sales_quarter">
                              {
                                Object.keys(state.sales).length > 0 ? (
                                  // <LineChart type="year" data={state.sales.yearly_sales_list}/>
                                  <LineChart type="month" data={state.sales.quarterly_sales_list}/>
                                ) : ('')
                              }
                              </TabPanel>
                              <TabPanel value="sales_month">
                              {
                                Object.keys(state.sales).length > 0 ? (
                                  <LineChart type="month" data={state.sales.monthly_sales_list}/>
                                ) : ('')
                              }
                              </TabPanel>
                              <TabPanel value="sales_week">
                              {
                                Object.keys(state.sales).length > 0 ? (
                                  <LineChart type="week" data={state.sales.weekly_sales_list}/>
                                ) : ('')
                              }
                              </TabPanel>
                          </Grid>
                      </Grid>
                      </TabContext>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid container direction="column" justifyContent="space-between" spacing={2} style={{background: '#FAFAFA', padding: '2rem', minHeight: '100%'}}>
                        <Grid item xs={4}>
                          <div style={{display: 'flex'}}>
                          <Typography variant="h5" style={{ color: '#A1A5B7', paddingRight: '1rem'}}>$ </Typography>
                          <Typography variant="h4">{ Object.keys(state.sales).length > 0 ? Intl.NumberFormat('en-US').format(state.sales.daily_sales_amount) : 0} { 
                                (Object.keys(state.sales).length > 0) ? 
                                  Math.sign(state.sales.daily_sales_percentage) == 1 ?
                                    (<Chip icon={ <ArrowUpward /> } label={state.sales.daily_sales_percentage +' %'} color="success"/>) : (<Chip icon={ <ArrowDownward /> } label={state.sales.daily_sales_percentage +' %'} color="error"/>) 
                                  : ('')}
                            </Typography>
                          </div>
                          <Typography variant="p" style={{marginBottom: '20px', color: '#A1A5B7'}}>Today Sales</Typography>
                        </Grid>
                        <Grid item xs={4}>
                        <div style={{display: 'flex'}}>
                          <Typography variant="h5" style={{ color: '#A1A5B7', paddingRight: '1rem'}}>$ </Typography>
                          <Typography variant="h4">{ Object.keys(state.sales).length > 0 ? Intl.NumberFormat('en-US').format(state.sales.monthly_sales_amount) : 0} { 
                                (Object.keys(state.sales).length > 0) ? 
                                  Math.sign(state.sales.monthly_sales_percentage) == 1 ?
                                    (<Chip icon={ <ArrowUpward /> } label={state.sales.monthly_sales_percentage +' %'} color="success"/>) : (<Chip icon={ <ArrowDownward /> } label={state.sales.monthly_sales_percentage +' %'} color="error"/>) 
                                  : ('')}
                            </Typography>
                          </div>
                          <Typography variant="p" style={{marginBottom: '20px', color: '#A1A5B7'}}>Monthly Sales</Typography>
                        </Grid>
                        <Grid item xs={4}>
                        <div style={{display: 'flex'}}>
                          <Typography variant="h5" style={{ color: '#A1A5B7', paddingRight: '1rem'}}>$ </Typography>
                          {/* <Typography variant="h4">{ Object.keys(state.sales).length > 0 ? Intl.NumberFormat('en-US').format(state.sales.yearly_sales_amount) : 0} { 
                                (Object.keys(state.sales).length > 0) ? 
                                  Math.sign(state.sales.yearly_sales_percentage) == 1 ?
                                    (<Chip icon={ <ArrowUpward /> } label={state.sales.yearly_sales_percentage +' %'} color="success"/>) : (<Chip icon={ <ArrowDownward /> } label={state.sales.yearly_sales_percentage +' %'} color="error"/>) 
                                  : ('')}
                            </Typography>
                          </div>
                          <Typography variant="p" style={{marginBottom: '20px', color: '#A1A5B7'}}>Yearly Sales</Typography> */}
                          <Typography variant="h4">{ Object.keys(state.sales).length > 0 ? Intl.NumberFormat('en-US').format(state.sales.quarterly_sales_amount) : 0} { 
                                (Object.keys(state.sales).length > 0) ? 
                                  Math.sign(state.sales.quarterly_sales_percentage) == 1 ?
                                    (<Chip icon={ <ArrowUpward /> } label={state.sales.quarterly_sales_percentage +' %'} color="success"/>) : (<Chip icon={ <ArrowDownward /> } label={state.sales.quarterly_sales_percentage +' %'} color="error"/>) 
                                  : ('')}
                            </Typography>
                          </div>
                          <Typography variant="p" style={{marginBottom: '20px', color: '#A1A5B7'}}>Quarterly Sales</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
            </Grid>
        </>
    );
}
