import React, { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Card, CardContent, Grid } from "@mui/material";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CardHeaderCss } from "../../../css/Admin-css/listing-css";
import SelectOptionComponent from "../../FormAddInputComponent/SelectOption";

function UpdatePaymentType(props) {
    console.log(props.paymentType);
    const { control, setValue } = useForm();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "selected_item",
    });

    const onModalCleanup = () => {
        setValue("selected_item", []);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal size="sm" open={props.show} backdrop="static">
            <Box sx={style}>
                <Card>
                    <CardHeaderCss title="Update Payment Type"/>
                    <CardContent>
                        <form
                            className="form"
                            id="update_payment_type_form"
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} style={{ paddingTop: 0, paddingBottom: 10 }}>
                                    <SelectOptionComponent
                                        control={control}
                                        name="payment_type"
                                        placeholder="Payment Type"
                                        label="Payment Type"
                                        option={props.paymentTypeData}
                                        // rules={{ required: "Payment type is required" }}
                                        errors={props.errors}
                                        setValue={setValue}
                                        onChangeHandler={props.onSelectChangeHandler(
                                            "payment_type"
                                        )}
                                        value={ props?.paymentType ? props.paymentType : 0 }
                                    />
                                </Grid>
                            </Grid>
                            <Button 
                                variant="contained" 
                                color="primary"
                                onClick={props.onSubmitPaymentTypeHandler}
                            >
                                Submit
                            </Button>
                            <Button variant="secondary" onClick={() => props.handleShowUpdatePaymentType(false, 0, onModalCleanup)}>
                                Close
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    );
}

export default React.memo(UpdatePaymentType);
