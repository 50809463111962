import SignIn from "../../components/AdminModule/Login/Login";
import Dashboard from "../../components/AdminModule/Dashboard/dashboard";
import CustomerList from "../../components/AdminModule/Customer/Customer-list";
import PendingAssignCustomerList from "../../components/AdminModule/Customer/Pending-assign-customer-list";
import CreateCustomer from "../../components/AdminModule/Customer/Create-customer";
import Import from "../../components/AdminModule/Import/Import";
import AssignSalesPerson from "../../components/AdminModule/Customer/Assign-salesperson";
import Product from "../../components/AdminModule/Product/Product-list";
import EditProduct from "../../components/AdminModule/Product/Edit-Product";
import CreateProduct from "../../components/AdminModule/Product/Create-Product";
import SalespersonList from "../../components/AdminModule/SalesPerson/Salesperson-list";
import CreateSalesperson from "../../components/AdminModule/SalesPerson/Create-Salesperson";
import EditSalesperson from "../../components/AdminModule/SalesPerson/Edit-Salesperson";
import User from "../../components/AdminModule/User/User-list";
import CreateUser from "../../components/AdminModule/User/Create-user";
import EditUser from "../../components/AdminModule/User/Edit-user";
import Country from "../../components/AdminModule/System/Country-list";
import EditCountry from "../../components/AdminModule/System/Edit-Country";
import CreateCountry from "../../components/AdminModule/System/Create-Country";
import Translation from "../../components/AdminModule/System/Translation-list";
import Audit from "../../components/AdminModule/System/Audit-list";
import PaymentType from "../../components/AdminModule/System/Payment-Type-list";
import EditPaymentType from "../../components/AdminModule/System/Edit-Payment-Type";
import CreatePaymentType from "../../components/AdminModule/System/Create-Payment-Type";
import SalesList from "../../components/AdminModule/Sales/Sales-list";
import TotalSalesList from "../../components/AdminModule/Sales/Total-Sales-list";
import InstallmentSalesForm from "../../components/AdminModule/Installment/Installment-Sales-Form";
import InstallmentList from "../../components/AdminModule/Installment/Installment-List";
import InstallmentUpdate from "../../components/AdminModule/Installment/Installment-Update";
import UserGroup from "../../components/AdminModule/UserGroup/user-group";
import UserGroupSetup from "../../components/AdminModule/UserGroup/user-group-setup";
import MenuSetup from "../../components/AdminModule/Menu/menu-list";
import EcommerceOrderList from "../../components/AdminModule/Sales/Ecommerce-Order-list";

const routes = [
  {
    path: "/admin/login",
    exact: true,
    auth: false,
    element: <SignIn />,
  },
  {
    path: "/admin/dashboard",
    exact: true,
    auth: true,
    element: <Dashboard />,
  },
  {
    path: "/admin/customer-list",
    exact: true,
    auth: true,
    element: <CustomerList />,
  },
  {
    path: "/admin/pending-assign-customer-list",
    exact: true,
    auth: true,
    element: <PendingAssignCustomerList />,
  },
  {
    path: "/admin/customer/create",
    exact: true,
    auth: true,
    element: <CreateCustomer />,
  },
  {
    path: "/admin/import",
    exact: true,
    auth: true,
    element: <Import />,
  },
  {
    path: "/admin/assign-salesperson",
    exact: true,
    auth: true,
    element: <AssignSalesPerson />,
  },
  {
    path: "/admin/product-list",
    exact: true,
    auth: true,
    element: <Product />,
  },
  {
    path: "/admin/product/update",
    exact: true,
    auth: true,
    element: <EditProduct />,
  },
  {
    path: "/admin/product/create",
    exact: true,
    auth: true,
    element: <CreateProduct />,
  },
  {
    path: "/admin/sales-person-list",
    exact: true,
    auth: true,
    element: <SalespersonList />,
  },
  {
    path: "/admin/sales-person/create",
    exact: true,
    auth: true,
    element: <CreateSalesperson />,
  },
  {
    path: "/admin/sales-person/update",
    exact: true,
    auth: true,
    element: <EditSalesperson />,
  },
  {
    path: "/admin/user-list",
    exact: true,
    auth: true,
    element: <User />,
  },
  {
    path: "/admin/user/create",
    exact: true,
    auth: true,
    element: <CreateUser />,
  },
  {
    path: "/admin/user/update",
    exact: true,
    auth: true,
    element: <EditUser />,
  },
  {
    path: "/admin/country-list",
    exact: true,
    auth: true,
    element: <Country />,
  },
  {
    path: "/admin/country/update",
    exact: true,
    auth: true,
    element: <EditCountry />,
  },
  {
    path: "/admin/country/create",
    exact: true,
    auth: true,
    element: <CreateCountry />,
  },
  {
    path: "/admin/translation-list",
    exact: true,
    auth: true,
    element: <Translation />,
  },
  {
    path: "/admin/audit-list",
    exact: true,
    auth: true,
    element: <Audit />,
  },
  {
    path: "/admin/payment-type-list",
    exact: true,
    auth: true,
    element: <PaymentType />,
  },
  {
    path: "/admin/payment-type/update",
    exact: true,
    auth: true,
    element: <EditPaymentType />,
  },
  {
    path: "/admin/payment-type/create",
    exact: true,
    auth: true,
    element: <CreatePaymentType />,
  },
  {
    path: "/admin/sales-list",
    exact: true,
    auth: true,
    element: <SalesList />,
  },
  {
    path: "/admin/total-sales-list",
    exact: true,
    auth: true,
    element: <TotalSalesList />,
  },
  {
    path: "/admin/installment-list",
    exact: true,
    auth: true,
    element: <InstallmentList />,
  },
  {
    path: "/admin/installment-sales-form",
    exact: true,
    auth: true,
    element: <InstallmentSalesForm />,
  },
  {
    path: "/admin/installment-sales-form/update",
    exact: true,
    auth: true,
    element: <InstallmentUpdate />,
  },
  {
    path: "/admin/user-group",
    exact: true,
    auth: true,
    element: <UserGroup />,
  },
  {
    path: "/admin/user-group-setup",
    exact: true,
    auth: true,
    element: <UserGroupSetup />,
  },
  {
    path: "/admin/menu-setup",
    exact: true,
    auth: true,
    element: <MenuSetup />,
  },
  {
    path: "/admin/ecommerce-order-list",
    exact: true,
    auth: true,
    element: <EcommerceOrderList />,
  },
];

export default routes;
