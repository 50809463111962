import React, { useContext, useState, useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { AppBarSpacer } from "../../../css/Admin-css/listing-css";
import SalesPersonListDatatable from "../DataTable/dt-salesPersonList";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useForm } from "react-hook-form";
import SearchField from "../DataTable/SearchField/SearchField";

import {
    fetchSalesPersonList,
    handleDataTablePerRowsChange,
} from "../../../actions/admin/actionCreators";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";
import { useTranslation } from "react-i18next";

export default function SalesPersonList() {
    const api = useContext(AxiosContext);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [startFrom, setStart] = useState(0);
    const [pending, setPending] = useState(true);
    const { t } = useTranslation('common');
    const [breadcrumbArr, setBreadcrumbArr] = React.useState([
        {
            text: "Sales Person",
            link: "",
        },
    ]);

    const searchField = [
        {
            name: "nick_name",
            label: t('general.label.username'),
            placeholder: t('general.label.username'),
            marginType: "normal",
            type: "text",
        },
        {
            name: "full_name",
            label:  t('general.label.name'),
            placeholder: t('general.label.name'),
            marginType: "normal",
            type: "text",
        },
        {
            name: "email",
            label: t('general.label.email'),
            placeholder: t('general.label.email'),
            marginType: "normal",
            type: "text",
        },
        {
            name: "contact_no",
            label: t('general.label.mobile_no'),
            placeholder: t('general.label.mobile_no'),
            marginType: "normal",
            type: "text",
        },
    ];

    const { handleSubmit, control, reset, getValues } = useForm({
        defaultValues: {
            nick_name: "",
            full_name: "",
            email: "",
            contact_no: "",
        },
    });

    const onSubmit = handleSubmit(async (param) => {
        param["page"] = startFrom;
        param["per_page"] = perPage;

        const response = await fetchSalesPersonList({
            api: api,
            data: param,
        });

        if (response) {
            if (response.status === 200) {
                if (response.data.total_records > 0) {
                    setData(response.data.data);
                    setTotalRows(response.data.total_records);
                }
            }
        }
    });

    const onReset = () => {
        reset();
        onSubmit();
    };

    const getSalesPersonListData = async () => {
        setPending(true);

        const param = {};
        param["page"] = startFrom;
        param["per_page"] = perPage;
        param["email"] = getValues("email");
        param["nick_name"] = getValues("nick_name");
        param["full_name"] = getValues("full_name");
        param["contact_no"] = getValues("contact_no");

        const response = await fetchSalesPersonList({
            api: api,
            data: param,
        });

        if (response) {
            if (response.status === 200) {
                setPending(false);
                if (response.data.total_records > 0) {
                    setData(response.data.data);
                    setTotalRows(response.data.total_records);
                }
            }
        }
    };

    const handlePageChange = async (page) => {
        const startPage = (page - 1) * perPage;
        setStart(startPage);
    };

    const handlePerRowsChange = async (newPerPage) => {
        const param = {};
        param["page"]       = 0;
        param["per_page"]   = newPerPage;
        param["email"]      = getValues("email");
        param["nick_name"]  = getValues("nick_name");
        param["full_name"]  = getValues("full_name");
        param["contact_no"] = getValues("contact_no");

        const response = await handleDataTablePerRowsChange({
            api: api,
            url: "getSalesPersonList?",
            data: param,
        });

        setData(response.data.data);
        setPerPage(newPerPage);
    };

    useEffect(() => {
        getSalesPersonListData();
        // subheader.setBreadcrumbs([{pathname: "/sales-person-list", title: "Sales Person"}]);
    }, [startFrom]);

    return (
        <>
            <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]} >
                <CollapsedBreadcrumbs />
            </BreadcrumbsContext.Provider>
            <div className="row">
                <div className="col-md-12">
                    <SearchField
                        control={control}
                        searchField={searchField}
                        onSubmit={onSubmit}
                        onReset={onReset}
                        onCreate="/admin/sales-person/create"
                    />
                    <AppBarSpacer />
                    <Card>
                        <CardContent>
                            <SalesPersonListDatatable
                                totalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                fileteredData={data}
                                startFrom={startFrom}
                                pending={pending}
                            ></SalesPersonListDatatable>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </>

    );
}
