import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid,
  useTheme,
} from "@mui/material";
import TextFieldComponent from "../../FormAddInputComponent/TextField";
import SelectOptionComponent from "../../FormAddInputComponent/SelectOption";
import DatePickerComponent from "../../FormAddInputComponent/DatePickerField";
import DatableTable from "../DataTable/dt-customerPurchaseHistoryList";
import EcommerceDatableTable from "../DataTable/dt-ecommerceOrderList";
import UpdatePaymentTypeModal from "./UpdatePaymentType.modal";

import { useForm } from "react-hook-form";
import {
  getCountry,
  fetchSalesPersonList,
  getSysGeneralData,
  postCreateForm,
  postEditForm,
  fetchCustomerList,
  fetchProductList,
  fetchSalesList,
  fetchEcommerceOrderList,
} from "../../../actions/admin/actionCreators";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import {
  AppBarSpacer,
  CardHeaderCss,
  ButtonCss,
} from "../../../css/Admin-css/listing-css";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import CollapsedBreadcrumbs, {
  BreadcrumbsContext,
} from "../../../layouts/admin/breadcrumb";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddIcon from "@mui/icons-material/Add";

export default function CreateCustomer(props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [status, setCustomerStatus] = useState([]);
  const [statusCode, setStatusCode] = useState("");
  const [salesPerson, setSalesPerson] = useState([]);
  const [salesPersonId, setSalesPersonId] = useState("");
  const [product, setProduct] = useState([]);
  const [productId, setProductId] = useState("");
  const [price, setPrice] = useState("");
  const [fixedAmount, setFixedAmount] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [birthday, setBirthday] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [CreateCustomer, setCreateCustomer] = useState(true);
  const [startFrom, setStart] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [purchaseHistoryData, setPurchaseHistoryData] = useState([]);
  const [startFromEcommerce, setStartEcommerce] = useState(0);
	const [totalRowsEcommerce] = useState(0);
	const [EcommerceOrdersData, setEcommerceOrdersData] = useState([]);
  const [show, setShow] = useState({
    show: false,
    updatePaymentTypeShow: false,
  });
  const [paymentType, setPaymentType] = useState(0);
  const [paymentTypeData, setPaymentTypeData] = useState([]);
  const [adsSales, setAdsSales] = useState(0);
  const [addingSales, setAddingSales] = useState(false);
  const [salesId, setSalesId] = useState("");
  const api = useContext(AxiosContext);
  const MySwal = withReactContent(Swal);
  const theme = useTheme();
  const [breadcrumbArr, setBreadcrumbArr] = React.useState([
    {
      text: "Customer",
      link: "/admin/customer-list",
    },
    {
      text: "Customer Form",
      link: "/admin/customer/create",
    },
  ]);
  const location = useLocation();
  const { ent_member_id } = location?.state ? location.state : 0;

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    shouldFocusError: true,
    reValidateMode: "onChange",
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      remark: "",
      addr: "",
      city: "",
      state: "",
      zip: "",
    },
  });

  const getCustomerInfo = async () => {
    const response = await fetchCustomerList({
      api: api,
      data: {
        member_id: ent_member_id,
      },
    });
    if (response) {
      const customerInfo = response.data.data[0];
      setValue("first_name", customerInfo.first_name);
      setValue("last_name", customerInfo.last_name);
      setValue("email", customerInfo.email);
      setValue("mobile", customerInfo.contact_no);
      setValue("country_id", customerInfo.country_id);
      setValue("status", customerInfo.status);
      setValue("sales_person", customerInfo.company_id);
      setValue(
        "birthday",
        convertUTCDateToLocalDate(customerInfo.formatted_birth_date)
      );
      setValue("remark", customerInfo.remark);
      setValue("addr", customerInfo.addr);
      setValue("city", customerInfo.city);
      setValue("state", customerInfo.state);
      setValue("zip", customerInfo.zip);
      setCountryId(customerInfo.country_id);
      setStatusCode(customerInfo.status);
      if (customerInfo.company_id == 0) {
        customerInfo.company_id = "";
      }
      setSalesPersonId(customerInfo.company_id);
      setBirthday(customerInfo.formatted_birth_date);
    }
  };

  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date);
    var formatDate =
      newDate.getFullYear() +
      "-" +
      parseInt(newDate.getMonth() + 1) +
      "-" +
      newDate.getDate();
    return formatDate;
  }

  const onSubmitPaymentTypeHandler = async () => {
    document.getElementById("splash-screen").classList.remove("hidden");

    var data = {};
    let url = "";

    if (addingSales) {
      url = "addSales";
      data["member_id"] = ent_member_id;
      data["campaign"] = productId;
      data["invoice_date"] = convertUTCDateToLocalDate(invoiceDate);
      data["fixed_amount"] = fixedAmount;
    } else {
      url = "updateSalesPaymentType";
      data["sales_id"] = salesId;
    }

    data["payment_type_id"] = paymentType;
    data["ads_sales"] = adsSales;

    const response = await postEditForm({
      api: api,
      data: data,
      method: "POST",
      url: url,
      enqueueSnackbar,
    });

    if (response) {
      if (response.status === 200) {
        enqueueSnackbar(response.data.msg, {
          variant: response.data.msgType,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        getPurchaseHistoryData();
      }
    }
    document.getElementById("splash-screen").classList.add("hidden");

    setShow((prevState) => ({
      ...prevState,
      updatePaymentTypeShow: false,
    }));
  };

  const onSubmitHandler = handleSubmit(async (data) => {
    document.getElementById("splash-screen").classList.remove("hidden");
    let url = "postCustomer";
    if (!CreateCustomer) {
      data.ent_member_id = ent_member_id;
      url = "updateCustomer";
    }

    data["birthday"] = convertUTCDateToLocalDate(data["birthday"]);

    const response = await postCreateForm({
      api: api,
      data: data,
      method: "POST",
      url: url,
      enqueueSnackbar,
    });
    if (response) {
      document.getElementById("splash-screen").classList.add("hidden");
      if (response.status === 200) {
        enqueueSnackbar(response.data.data.msg, {
          variant: response.data.data.msgType,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        if (response.data.data.msgType == "success") {
          navigate("/admin/customer-list");
        }
      }
    }
  });

  const onSelectChangeHandler = (name) => (event) => {
    const value = event.target.value;
    if (name == "country_id") {
      setCountryId(value);
    } else if (name == "status") {
      setStatusCode(value);
    } else if (name == "sales_person") {
      setSalesPersonId(value);
    } else if (name == "campaign") {
      setProductId(value);
    } else if (name == "payment_type") {
      setPaymentType(value);
    } else if (name == "ads_sales") {
      setAdsSales(event.target.checked == true ? 1 : 0);
    }
    setValue(name, value);
  };

  const onDateChangeHandler = (value, name) => {
    if (name == "birthday") {
      setBirthday(value);
    } else if (name == "invoice_date") {
      setInvoiceDate(value);
    }
    setValue(name, value);
  };

  const handlePageChange = async (page) => {
    const startPage = (page - 1) * perPage;
    setStart(startPage);
  };

  const handlePerRowsChange = async (newPerPage) => {
    const param = {};
    param["page"] = 0;
    //param["per_page"] = newPerPage == NaN ? data.length : newPerPage;

    // const response = await handleDataTablePerRowsChange({
    // 	api: api,
    // 	url: "getImportData",
    // 	data: param,
    // });

    // setData(response.data.data);
    // setTotalRows(response.data.totalRecord);
    // setPerPage(newPerPage);
  };

  const handlePageChangeEcommerce = async (page) => {
		const startPageEcommerce = (page - 1) * perPage;
		setStartEcommerce(startPageEcommerce);
	};

  const handleRefund = (id) => {
    MySwal.fire({
      title: <strong>Are you sure to refund this sales?</strong>,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.danger.main,
      confirmButtonText: "Yes!",
      customClass: {
        confirmButton: "confirm-button-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        proceedRefund(id);
      }
    });
  };

  const proceedRefund = async (id) => {
    document.getElementById("splash-screen").classList.remove("hidden");

    var data = {};

    data["id"] = id;

    const response = await postEditForm({
      api: api,
      data: data,
      method: "POST",
      url: "updateSalesStatus",
      enqueueSnackbar,
    });

    if (response) {
      if (response.status === 200) {
        enqueueSnackbar(response.data.msg, {
          variant: response.data.msgType,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        getPurchaseHistoryData();
      }
    }
    document.getElementById("splash-screen").classList.add("hidden");
  };

  const getPurchaseHistoryData = async () => {
    const param = {};
    param["member_id"] = ent_member_id;
    const response = await fetchSalesList({
      api: api,
      data: param,
    });
    setPurchaseHistoryData(response.data.data);
  };

  const getEcommerceOrdersData = async () => {
		const param = {};
		param["crm_member_id"] = ent_member_id;
		param["status"] = 'A';
		const response = await fetchEcommerceOrderList({
			api: api,
			data: param,
		});
		setEcommerceOrdersData(response.data.data);
	};

  const handleShowUpdatePaymentType = (
    show,
    id,
    callback = null,
    payment_type,
    ads_sales,
    adding_sales = false
  ) => {
    setPaymentType(payment_type);
    setAdsSales(ads_sales);
    setAddingSales(adding_sales);
    setSalesId(id);
    setProductId("");
    setInvoiceDate(null);
    setPrice("");
    setDiscountPrice("");
    setShow((prevState) => ({
      ...prevState,
      updatePaymentTypeShow: show,
    }));
  };

  const getPaymentTypeData = async () => {
    const response = await getSysGeneralData({
      api: api,
      type: "payment-type",
    });

    setPaymentTypeData(response.data.data);
  };

  useEffect(() => {
    product.map((prd, index) => {
      if (prd.id == productId) {
        setPrice(prd.unit_price);
      }
    });
  }, [productId]);

  useEffect(() => {
    getPaymentTypeData();
    const getCountryData = async () => {
      const response = await getCountry({
        api: api,
      });

      setCountry(response.data);
    };

    const getCustomerStatusData = async () => {
      const response = await getSysGeneralData({
        api: api,
        type: "member-status",
      });

      setCustomerStatus(response.data.data);
    };

    const getSalesPersonData = async () => {
      const param = {};
      param["status"] = "A";
      const response = await fetchSalesPersonList({
        api: api,
        data: param,
      });
      setSalesPerson(response.data.data);
    };

    const getProductData = async () => {
      const param = {};
      param["status"] = "A";
      const response = await fetchProductList({
        api: api,
        data: param,
      });
      setProduct(response.data.data);
    };

    getCountryData();
    getCustomerStatusData();
    getSalesPersonData();
    getProductData();
    // const ent_member_id = props.location.state
    // 	? props.location.state.ent_member_id
    // 	: 0;

    if (ent_member_id) {
      setCreateCustomer(false);
      getCustomerInfo();
      getPurchaseHistoryData();
      getEcommerceOrdersData();
    } else {
      setCreateCustomer(true);
    }

    return () => {
      return;
    };
  }, []);

  return (
    <>
      <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]}>
        <CollapsedBreadcrumbs />
      </BreadcrumbsContext.Provider>
      <Card>
        <CardHeaderCss title="Customer Form" />
        <CardContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                <TextFieldComponent
                  control={control}
                  name="first_name"
                  placeholder="First Name"
                  marginType="normal"
                  label="First Name"
                  type="text"
                  rules={{
                    required: "First name is required",
                  }}
                  errors={errors}
                  setValue={setValue}
                  // disabled={CreateCustomer ? false : true }
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                <TextFieldComponent
                  control={control}
                  name="last_name"
                  placeholder="Last Name"
                  marginType="normal"
                  label="Last Name"
                  type="text"
                  rules={
                    {
                      // required: "Last name is required",
                    }
                  }
                  errors={errors}
                  setValue={setValue}
                  // disabled={CreateCustomer ? false : true }
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                <TextFieldComponent
                  control={control}
                  name="email"
                  placeholder="Email Address"
                  marginType="normal"
                  label="Email Address"
                  helperText="We'll never share your email with anyone else"
                  style={{ margin: 8 }}
                  type="email"
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "You must provide a valid email address!",
                    },
                  }}
                  errors={errors}
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                <TextFieldComponent
                  control={control}
                  name="mobile"
                  placeholder="Mobile No."
                  marginType="normal"
                  label="Mobile No."
                  helperText="We'll never share your mobile no. with anyone else"
                  style={{ margin: 8 }}
                  type="text"
                  InputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  rules={{
                    required: "Mobile No. is required",
                  }}
                  errors={errors}
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                <DatePickerComponent
                  control={control}
                  name="birthday"
                  label="Date of Birth"
                  errors={errors}
                  marginType="normal"
                  value={birthday}
                  rules={{
                    required: "Date of Birth is required",
                  }}
                  onChange={(value) => {
                    onDateChangeHandler(value, "birthday");
                  }}
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                <SelectOptionComponent
                  control={control}
                  name="country_id"
                  placeholder="Country"
                  label="Country"
                  option={country}
                  rules={{ required: "Country is required" }}
                  errors={errors}
                  setValue={setValue}
                  onChangeHandler={onSelectChangeHandler("country_id")}
                  value={countryId}
                />
              </Grid>
              {CreateCustomer ? (
                <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                  <SelectOptionComponent
                    control={control}
                    name="campaign"
                    placeholder="Campaign"
                    label="Campaign"
                    option={product}
                    // rules={{ required: "Campaign is required" }}
                    errors={errors}
                    setValue={setValue}
                    onChangeHandler={onSelectChangeHandler("campaign")}
                    value={productId}
                  />
                </Grid>
              ) : (
                ""
              )}
              {CreateCustomer ? (
                <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                  <DatePickerComponent
                    control={control}
                    name="invoice_date"
                    label="Invoice Date"
                    errors={errors}
                    marginType="normal"
                    value={invoiceDate}
                    // rules={{ required: "Invoice Data is required" }}
                    onChange={(value) => {
                      onDateChangeHandler(value, "invoice_date");
                    }}
                    setValue={setValue}
                  />
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                <SelectOptionComponent
                  control={control}
                  name="sales_person"
                  placeholder="Sales Person"
                  label="Sales Person"
                  option={salesPerson}
                  rules={{
                    required: "Sales Person is required",
                  }}
                  errors={errors}
                  setValue={setValue}
                  onChangeHandler={onSelectChangeHandler("sales_person")}
                  value={salesPersonId}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                <SelectOptionComponent
                  control={control}
                  name="status"
                  placeholder="Customer Status"
                  label="Customer Status"
                  option={status}
                  rules={{ required: "Status is required" }}
                  errors={errors}
                  setValue={setValue}
                  onChangeHandler={onSelectChangeHandler("status")}
                  value={statusCode}
                  type="sysGeneral"
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                <TextFieldComponent
                  control={control}
                  name="remark"
                  placeholder="Remark"
                  marginType="normal"
                  label="Remark"
                  style={{ margin: 8 }}
                  type="text"
                  errors={errors}
                  setValue={setValue}
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Card>
        <CardHeaderCss title="Address Form" />
        <CardContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                <TextFieldComponent
                  control={control}
                  name="addr"
                  placeholder="Address"
                  marginType="normal"
                  label="Address"
                  type="text"
                  errors={errors}
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                <TextFieldComponent
                  control={control}
                  name="city"
                  placeholder="City"
                  marginType="normal"
                  label="City"
                  type="text"
                  errors={errors}
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                <TextFieldComponent
                  control={control}
                  name="state"
                  placeholder="State"
                  marginType="normal"
                  label="State"
                  type="text"
                  errors={errors}
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                <TextFieldComponent
                  control={control}
                  name="zip"
                  placeholder="Zip"
                  label="Zip"
                  marginType="normal"
                  type="number"
                  errors={errors}
                  setValue={setValue}
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Card>
        <CardHeaderCss title="Campaign/Product History" />
        {CreateCustomer ? (
          ""
        ) : (
          <div>
            <AppBarSpacer />
            <ButtonCss
              variant="contained"
              size="large"
              onClick={() =>
                handleShowUpdatePaymentType(true, "", "", 0, 0, true)
              }
            >
              <AddIcon />
              &nbsp;&nbsp;Add
            </ButtonCss>
          </div>
        )}
        <CardContent>
          <DatableTable
            totalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            fileteredData={purchaseHistoryData}
            startFrom={startFrom}
            handleRefund={handleRefund}
            handleShowUpdatePaymentType={handleShowUpdatePaymentType}
          />
        </CardContent>
      </Card>
      <AppBarSpacer />
      {CreateCustomer ? (
        ""
      ) : (
        <div>
          <Card>
            <CardHeaderCss title="Ecommerce Orders" />
            <CardContent>
              <EcommerceDatableTable
                totalRows={totalRowsEcommerce}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChangeEcommerce}
                fileteredData={EcommerceOrdersData}
                startFrom={startFromEcommerce}
              />
            </CardContent>
          </Card>
          <AppBarSpacer />
        </div>
      )}
      <CardActions style={{ justifyContent: "center", marginBottom: "16px" }}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={onSubmitHandler}
        >
          {/* Submit */}
          {CreateCustomer ? "Submit" : "Update"}
        </Button>
      </CardActions>
      <UpdatePaymentTypeModal
        show={show.updatePaymentTypeShow}
        handleShowUpdatePaymentType={handleShowUpdatePaymentType}
        onSelectChangeHandler={onSelectChangeHandler}
        onSubmitPaymentTypeHandler={onSubmitPaymentTypeHandler}
        paymentType={paymentType}
        paymentTypeData={paymentTypeData}
        adsSales={adsSales}
        addingSales={addingSales}
        product={product}
        productId={productId}
        price={price}
        fixedAmount={fixedAmount}
        setFixedAmount={setFixedAmount}
        discountPrice={discountPrice}
        setDiscountPrice={setDiscountPrice}
        invoiceDate={invoiceDate}
        onDateChangeHandler={onDateChangeHandler}
        salesId={salesId}
        errors={errors}
      />
    </>
  );
}
