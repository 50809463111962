import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Info from "@mui/icons-material/Info";
import TextFieldComponent from "../../FormAddInputComponent/TextField";
import SelectOptionComponent from "../../FormAddInputComponent/SelectOption";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import { AppBarSpacer } from "../../../css/Admin-css/listing-css";
import { ListItemGap } from "../../../css/Admin-css/admin-css";
import ImportDataListDatatable from "../DataTable/dt-importDataList";
import CollapsedBreadcrumbs, {
	BreadcrumbsContext,
} from "../../../layouts/admin/breadcrumb";
import {
	uploadCsv,
	handleDataTablePerRowsChange,
	postImportData,
	fetchProductList,
} from "../../../actions/admin/actionCreators";
import {
	Card,
	CardContent,
	Button,
	Grid,
	List,
	ListItemText,
	ListItemIcon,
	Alert,
	useTheme,
	FormControlLabel,
	Checkbox,
} from "@mui/material";

export default function Import() {
	const api = useContext(AxiosContext);
	const navigate = useNavigate();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [data, setData] = useState([]);
	const [product, setProduct] = useState([]);
	const [productId, setProductId] = useState("");
	const [adsSales, setAdsSales] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(50);
	const [startFrom, setStart] = useState(0);
	const [file, setFile] = useState();
	const [buttonHide, setbuttonHide] = useState(true);
	const [{ alt_doc, src_doc }, setPreviewDocument] = useState({
		src_doc: "",
		alt_doc: "",
	});
	const MySwal = withReactContent(Swal);
	const theme = useTheme();

	const {
		control,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		shouldFocusError: true,
		reValidateMode: "onChange",
		defaultValues: {
			name: "",
			file: "",
		},
	});

	const [breadcrumbArr, setBreadcrumbArr] = React.useState([
		{
			text: "Import CSV Form",
			link: "/admin/import",
		},
	]);

	const handleOnChange = (e) => {
		setbuttonHide(true);
		setValue("file", e.target.files[0].name);
		setFile(e.target.files[0]);
		setPreviewDocument({
			alt_doc: e.target.files[0].name,
		});
	};

	const handleOnImport = async (formData) => {
		document.getElementById("splash-screen").classList.remove("hidden");
		const response = await uploadCsv({
			api: api,
			data: formData,
			method: "POST",
			url: "uploadCSV",
			enqueueSnackbar,
		});

		if (response) {
			setbuttonHide(false);
			if (response.status === 200) {
				enqueueSnackbar(response.msg, {
					variant: response.status_type,
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
				});
				setData(response.data.data);
				setTotalRows(response.data.totalRecord);
				// document
				// 	.getElementById("splash-screen")
				// 	.classList.add("hidden");
			}
		}
        document
			.getElementById("splash-screen")
			.classList.add("hidden");
	};

	const handleOnSubmit = handleSubmit((data) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("campaign", productId);

		MySwal.fire({
			title: <strong>Are you sure?</strong>,
			// html: <label>You won't be able to revert this!</label>,
			icon: 'info',
			showCancelButton: true,
			confirmButtonColor: theme.palette.primary.main,
			cancelButtonColor: theme.palette.danger.main,
			confirmButtonText: 'Yes, import now !',
			customClass: {
				confirmButton: 'confirm-button-class',
			}
		}).then((result) => {
			if (result.isConfirmed) {
				handleOnImport(formData);
			}
		});
	});

	const handleOnPost = async () => {
		const formData = new FormData();
		formData.append("ads_sales", adsSales);
		
		document.getElementById("splash-screen").classList.remove("hidden");
		const response = await postImportData({
			api: api,
			data: formData,
			method: "POST",
			url: "saveImportMemberData",
			enqueueSnackbar,
		});

		document.getElementById("splash-screen").classList.add("hidden");
		if (response) {
			if (response.status === 200) {
				enqueueSnackbar(response.msg, {
					variant: response.status_type,
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
				});
				navigate("/admin/customer-list");
			}
		}
	};

	const handlePageChange = async (page) => {
		const startPage = (page - 1) * perPage;
		setStart(startPage);
	};

	const handlePerRowsChange = async (newPerPage) => {
		const param = {};
		param["page"] = 0;
		param["per_page"] = newPerPage == NaN ? data.length : newPerPage;

		const response = await handleDataTablePerRowsChange({
			api: api,
			url: "getImportData",
			data: param,
		});

		setData(response.data.data);
		setTotalRows(response.data.totalRecord);
		setPerPage(newPerPage);
	};

	const UploadButton = () => (
		<Button variant="contained" component="label">
			Upload
			<input
				accept={".csv"}
				type="file"
				hidden
				onChange={handleOnChange}
			/>
		</Button>
	);

	const onSelectChangeHandler = (name) => (event) => {
		const value = event.target.value;
		if (name == "campaign") {
			setProductId(value);
		}
		if (name == "ads_sales") {
			setAdsSales(event.target.checked == true ? 1 : 0);
		}
		setValue(name, value);
	};

	const getProductData = async () => {
		const param = {};
		param["status"] = "A";
		const response = await fetchProductList({
			api: api,
			data: param,
		});
		setProduct(response.data.data);
	};

	useEffect(() => {
		getProductData();
	}, [startFrom]);

	return (
		<>
			<BreadcrumbsContext.Provider
				value={[breadcrumbArr, setBreadcrumbArr]}
			>
				<CollapsedBreadcrumbs />
			</BreadcrumbsContext.Provider>
			<div className="row">
				<div className="col-md-12">
					<Card>
						<CardContent>
							<form>
								<Grid container spacing={4}>
									<Grid
										item
										xs={12}
										md={6}
										style={{ paddingTop: 0 }}
									>
										<SelectOptionComponent
											control={control}
											name="campaign"
											placeholder="Campaign Name"
											label="Campaign Name"
											option={product}
											rules={{
												required:
													"Campaign Name is required",
											}}
											errors={errors}
											setValue={setValue}
											onChangeHandler={onSelectChangeHandler(
												"campaign"
											)}
											value={productId}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
										style={{ paddingTop: 0 }}
									>
										<TextFieldComponent
											control={control}
											name="file"
											marginType="normal"
											label="Upload Document"
											type="text"
											disabled
											InputProps={{
												endAdornment: <UploadButton />,
											}}
											rules={{
												required: {
													value: true,
													message:
														"this field is required",
												},
											}}
											errors={errors}
										/>
									</Grid>
								</Grid>

								<Grid container spacing={2}>
									<Grid item xs={12} md={12}>
										<Alert
											variant="outlined"
											severity="info"
										>
											<List>
												<ListItemGap>
													<ListItemIcon
														style={{
															minWidth: "30px",
														}}
													>
														<Info
															style={{
																color: "#AD8E64",
															}}
															fontSize="small"
														/>
													</ListItemIcon>
													<ListItemText primary="The only allowed file type is a .csv (not a .numbers or .xlsx)" />
												</ListItemGap>
												<ListItemGap>
													<ListItemIcon
														style={{
															minWidth: "30px",
														}}
													>
														<Info
															style={{
																color: "#AD8E64",
															}}
															fontSize="small"
														/>
													</ListItemIcon>
													<ListItemText primary="The file must be not exceed 20MB in size" />
												</ListItemGap>
												<ListItemGap>
													<ListItemIcon
														style={{
															minWidth: "30px",
														}}
													>
														<Info
															style={{
																color: "#AD8E64",
															}}
															fontSize="small"
														/>
													</ListItemIcon>
													<ListItemText primary="The import might take a while, system will show a table when it's done" />
												</ListItemGap>
												<ListItemGap>
													<ListItemIcon
														style={{
															minWidth: "30px",
														}}
													>
														<Info
															style={{
																color: "#AD8E64",
															}}
															fontSize="small"
														/>
													</ListItemIcon>
													<ListItemText primary="Check out the table data before confirm save into system" />
												</ListItemGap>
											</List>
										</Alert>
									</Grid>
								</Grid>

								<AppBarSpacer />

								<div align="center">
									{!buttonHide ? (
										<FormControlLabel control={<Checkbox control={control}
											name="ads_sales"
											rules={{}}
											errors={errors}
											onChange={onSelectChangeHandler(
												"ads_sales"
											)} />} label="Ads Sales" />
									) : ''}
								</div>

								<AppBarSpacer />

								<div align="center">
									{!buttonHide ? (
										<Button
											color="primary"
											variant="contained"
											size="large"
											onClick={handleOnPost}
										>
											Proceed Save Now
										</Button>
									) : (
										<Button
											variant="contained"
											color="primary"
											size="large"
											onClick={handleOnSubmit}
										>
											Import Now
										</Button>
									)}
								</div>
							</form>
							<AppBarSpacer />

							<ImportDataListDatatable
								totalRows={totalRows}
								onChangeRowsPerPage={handlePerRowsChange}
								onChangePage={handlePageChange}
								fileteredData={data}
								startFrom={startFrom}
							/>
						</CardContent>
					</Card>
				</div>
			</div>
		</>
	);
}
