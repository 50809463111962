import React, { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Card,
  CardContent,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { CardHeaderCss } from "../../../css/Admin-css/listing-css";
import SelectOptionComponent from "../../FormAddInputComponent/SelectOption";
import DatePickerComponent from "../../FormAddInputComponent/DatePickerField";
import TextFieldComponent from "../../FormAddInputComponent/TextField";

function UpdatePaymentType(props) {
  const { control, setValue } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "selected_item",
  });

  const onModalCleanup = () => {
    setValue("selected_item", []);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const onChangeHandler = (event) => {
    setValue("fixed_amount", event.target.value);
    props.setFixedAmount(event.target.value);
    countPrice(event.target.value);
  };

  const countPrice = (fixedAmount = 0) => {
    if (props.price > 0) {
      const discount = props.price - fixedAmount;
      if (discount != props.price) {
        props.setDiscountPrice(discount.toFixed(2));
      } else {
        props.setDiscountPrice("");
      }
    } else {
      props.setFixedAmount("");
      setValue("fixed_amount", "");
      props.setDiscountPrice("");
    }
  };
  useEffect(() => {
    setValue("prd_price", props.price);
    countPrice();
  }, [props.price]);

  return (
    <Modal size="sm" open={props.show} backdrop="static">
      <Box sx={style}>
        <Card>
          <CardHeaderCss
            title={props?.addingSales ? "Add History" : "Update Details"}
          />
          <CardContent>
            <form className="form" id="update_payment_type_form">
              {props?.addingSales ? (
                <div>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{ paddingTop: 0, paddingBottom: 10 }}
                    >
                      <SelectOptionComponent
                        control={control}
                        name="campaign"
                        placeholder="Campaign"
                        label="Campaign"
                        option={props.product}
                        errors={props.errors}
                        setValue={setValue}
                        onChangeHandler={props.onSelectChangeHandler(
                          "campaign"
                        )}
                        value={props.productId}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{ paddingTop: 0, paddingBottom: 10 }}
                    >
                      <TextFieldComponent
                        control={control}
                        name="prd_price"
                        placeholder="%"
                        marginType="normal"
                        label="Product Price"
                        type="tel"
                        errors={props.errors}
                        setValue={setValue}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{ paddingTop: 0, paddingBottom: 10 }}
                    >
                      <DatePickerComponent
                        control={control}
                        name="invoice_date"
                        label="Invoice Date"
                        errors={props.errors}
                        marginType="normal"
                        value={props.invoiceDate}
                        onChange={(value) => {
                          props.onDateChangeHandler(value, "invoice_date");
                        }}
                        setValue={setValue}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={6}
                      md={props.discountPrice ? 6 : 12}
                      style={{ paddingTop: 0, paddingBottom: 10 }}
                    >
                      <TextFieldComponent
                        control={control}
                        name="fixed_amount"
                        placeholder="Amount"
                        marginType="normal"
                        label="Discount Amount"
                        type="tel"
                        errors={props.errors}
                        setValue={setValue}
                        onChangeHandler={onChangeHandler}
                        value={props.fixedAmount}
                      />
                    </Grid>
                    {props.discountPrice ? (
                      <Grid
                        item
                        xs={6}
                        md={6}
                        style={{ paddingTop: 0, paddingBottom: 10 }}
                      >
                        <TextFieldComponent
                          control={control}
                          name="price"
                          marginType="normal"
                          label="Price After Discount"
                          type="tel"
                          errors={props.errors}
                          setValue={setValue}
                          disabled
                        />
                        {setValue("price", props.discountPrice)}
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </div>
              ) : (
                ""
              )}
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingTop: 0, paddingBottom: 10 }}
                >
                  <SelectOptionComponent
                    control={control}
                    name="payment_type"
                    placeholder="Payment Type"
                    label="Payment Type"
                    option={props.paymentTypeData}
                    // rules={{ required: "Payment type is required" }}
                    errors={props.errors}
                    setValue={setValue}
                    onChangeHandler={props.onSelectChangeHandler(
                      "payment_type"
                    )}
                    value={props?.paymentType ? props.paymentType : 0}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingTop: 0, paddingBottom: 10 }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        control={control}
                        name="ads_sales"
                        checked={props?.adsSales ? true : false}
                        errors={props.errors}
                        onChange={props.onSelectChangeHandler("ads_sales")}
                      />
                    }
                    label="Ads Sales"
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                onClick={props.onSubmitPaymentTypeHandler}
              >
                Submit
              </Button>
              <Button
                variant="secondary"
                onClick={() =>
                  props.handleShowUpdatePaymentType(false, 0, onModalCleanup)
                }
              >
                Close
              </Button>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}

export default React.memo(UpdatePaymentType);
