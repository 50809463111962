import React, { useEffect } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import AsideComponent from './aside';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, responsiveFontSizes, styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import HeaderComponent from "./header";
import SignIn from "../../components/SalesPersonModule/Login/Login.jsx";
import MainRoutes from "../../routes/salesPerson/main.js";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: '0px',
    width: '100vw',

    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
      width: `calc(100vw - ${drawerWidth}px)`,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

let theme = createTheme({
    palette: {
      primary: {
        main: '#AD8E64',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#F5F1ED',
        contrastText: '#AD8E64'
      },
      white:{
        main: '#FFFFFF',
      },
      danger:{
        main: '#FF5A5A',
      },
      grey:{
        main: '#E4E6EF',
        contrastText: '#3F4254'
      }
    },
    typography: {
        h1: { fontSize: 60 },
        h2: { fontSize: 40 },
        h3: { fontSize: 35 },
        h4: { fontSize: 30 },
        h5: { fontSize: 25 },
        h6: { fontSize: 20 },
        p: {fontSize: 14},
        span: { fontSize: 14 },
        div: { fontSize: 14 },
    }, 
    spacing: 4
});

theme = responsiveFontSizes(theme);

const MasterLayout = () => {
    const [open, setOpen] = React.useState(true);
    // const [breadcrumbArr, setBreadcrumbArr] = React.useState([]);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const navigate = useNavigate();
    // const isLoading = useSelector((state) => state.GeneralReducer.isLoading);

    const temp = useSelector((state) => ({
        isLoggedIn: state.SalesPersonLoginReducer.isLoggedIn,
        userSession: state.SalesPersonErrorReducer.token_expired,
    }));

    useEffect(() => {
      if (!temp.isLoggedIn) {
        navigate("/salesperson/login");
      }
    }, [navigate, temp.isLoggedIn, temp.userSession]);
    
    return (
    <ThemeProvider theme={theme}>
      <Box id="splash-screen" className="hidden" >
        <CircularProgress color="primary"/>
      </Box>
        {temp.isLoggedIn ? (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open} color="white">
                    <HeaderComponent action={handleDrawerOpen} status={open}/>
                </AppBar>
                <Main open={open}>
                    <DrawerHeader />
                    <AsideComponent action={handleDrawerClose} status={open}/>
                    {/* <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]}> */}
                      <MainRoutes/>
                    {/* </BreadcrumbsContext.Provider> */}
                </Main>
            </Box>
        ) : (
          <Routes>
            <Route path="/salesperson/login" exact={true} element={<SignIn/>} auth={false} />
          </Routes>
        )} 
    </ThemeProvider>
  );
};

export default MasterLayout;