import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { createContext, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

export const BreadcrumbsContext = createContext();

export default function CollapsedBreadcrumbs() {
    const [breadcrumbArr, setBreadcrumbArr] = useContext(BreadcrumbsContext);

    const result = breadcrumbArr.map((value, key) => {
        return (
             key == breadcrumbArr.length - 1 
              ?  
                  ( <Typography color="inherit" key={key}>{value.text}</Typography> )
              :  
                  ( <Link underline="hover" color="primary" key={key} component={RouterLink} to={value.link}>
                      {value.text}
                  </Link> )
        );
    });
    return <Breadcrumbs maxItems={2} aria-label="breadcrumb" style={{ paddingBottom: 10 }}>{result}</Breadcrumbs>;
}