import React, { useContext, useState, useEffect } from "react";

import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";
import { Card, CardHeader, CardContent, Typography, Button, Grid, useTheme } from "@mui/material";
import { AppBarSpacer, CardHeaderCss } from "../../../css/Admin-css/listing-css";
import "../../../css/Admin-css/tree-style.css";
import Tree, { TreeNode } from 'rc-tree';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TextFieldComponent from "../../FormAddInputComponent/TextField";
import SelectOptionComponent from "../../FormAddInputComponent/SelectOption";
import { useForm } from "react-hook-form";
import { getUserGroupMenuData, postMenuSetup } from "../../../actions/admin/actionCreators";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function MenuSetup() {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedNode, setSelectedNode] = useState();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedNodeName, setSelectedNodeName] = useState();
  const treeRef = React.createRef();
  const MySwal = withReactContent(Swal);
  const theme = useTheme();
  const {
    watch,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "all",
  });

  const [menuData, setMenuData] = useState([]);
  const [treeMenuData, setTreeMenuData] = useState([]);
  const [expandedMenuKeys, setExpandedMenuKeys] = useState([]);
  const [newNodes, setNewNodes] = useState([]);
  const [parentId, setParentId] = useState('');
  const api = useContext(AxiosContext);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [breadcrumbArr, setBreadcrumbArr] = React.useState([
    {
      text: "Admin",
      link: "/admin/user-list",
    },
    {
      text: "Menu Setup",
      link: "/admin/menu-setup",
    },
  ]);

  const statusList = [
    { id: "A", name: "Active" },
    { id: "I", name: "Inactive" },
  ];

  function buildTree(data, parentId) {
    const tree = [];

    data.map((item, index) => {
      if (item.parent_id === parentId || (parentId == 0 && index == 0)) {
        item.key = item.id;
        item.title = <div style={{ display: 'flex', alignItems: 'center' }}><Typography>{item.name}</Typography> <Typography style={{ fontSize: 12 }}>({item.seq_no})</Typography></div>;

        const children = buildTree(data, item.id);
        if (children.length) {
          item.children = children;
        }
        tree.push(item);
      }
    });

    return tree;
  }

  const getUserMenuData = async () => {
    const response = await getUserGroupMenuData({
      api: api,
      data: {}
    });

    if (response) {
      if (response.status == 200) {
        setMenuData(response.data);
      }
    }
  }

  const onExpandMenu = expandedKeys => {
    setExpandedMenuKeys(expandedKeys);
  };

  const onSelectChangeHandler = (name) => (event) => {
    const value = event.target.value;
    if (name == "status") {
      setStatus(value);
    }
    setValue(name, value);
  };

  function proceedOnSelect(selectedKey) {
    setSelectedNode(selectedKey);
    setSelectedKeys([selectedKey]);

    let newData = [...menuData];

    newData.map((dt, key) => {
      if (dt.key === selectedKey) {
        setValue('parent_name', dt.parent_name ?? '-');
        setValue('name', dt.name);
        setValue('seq_no', dt.seq_no);
        setValue('icn', dt.icn ?? '');
        setValue('file_path', dt.file_path ?? '');
        setValue('status', dt.status);
        setStatus(dt.status);
        setParentId(dt.parent_id ?? 0);
        setSelectedNodeName(dt.name);
      }
    });
  }

  const onSelect = info => {
    proceedOnSelect(info[0]);
  };

  const onDrop = event => {
    MySwal.fire({
      title: <strong>Are you sure?</strong>,
      html: <label>You won't be able to revert this!</label>,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.danger.main,
      confirmButtonText: 'Yes, move now !',
      customClass: {
        confirmButton: 'confirm-button-class',
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        let postData = {};
        postData.action = 'update';
        postData.menu_id = event.dragNode.id;
        postData.parent_id = event.node.id;
        await submitPostMenuSetup(postData);
        handleExpandNode(event.node.id);
      }
    });
  }

  function handleExpandNode(selectedNode) {
    let newExpandedMenuKeys = expandedMenuKeys;
    if (!newExpandedMenuKeys.includes(selectedNode)) {
      newExpandedMenuKeys.push(selectedNode);
    }
    setExpandedMenuKeys(newExpandedMenuKeys);
  }

  const handleAddMenu = () => {
    if (selectedNode) {
      let dataArray = menuData;
      let item = [];

      item.id = Date.now();
      item.key = item.id;
      item.parent_name = selectedNodeName;
      item.parent_id = selectedNode;
      item.name = 'New Node';
      item.seq_no = '';
      item.icn = '';
      item.file_path = '';
      item.status = 'A';

      dataArray.push({ ...item });

      // insert new node array
      let newNodeArray = newNodes;
      if (!newNodeArray.includes(item.id)) {
        newNodeArray.push(item.id);
      }
      setNewNodes(newNodeArray);

      // expand parent if not expanded
      handleExpandNode(selectedNode);

      setTreeMenuData(buildTree(dataArray, 0));
      proceedOnSelect(item.key);
    } else {
      enqueueSnackbar('Please select node first', {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  }

  const handleDeleteMenu = () => {
    if (selectedNode) {
      MySwal.fire({
        title: <strong>Are you sure?</strong>,
        html: <label>You won't be able to revert this!</label>,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: theme.palette.primary.main,
        cancelButtonColor: theme.palette.danger.main,
        confirmButtonText: 'Yes, delete now !',
        customClass: {
          confirmButton: 'confirm-button-class',
        }
      }).then((result) => {
        if (result.isConfirmed) {
          if (newNodes.includes(selectedNode)) {
            // delete new node
            getUserMenuData();
          } else {
            // delete existing node
            let postData = {};
            postData.action = 'delete';
            postData.menu_id = selectedNode;
            submitPostMenuSetup(postData);
          }
        }
      });
    } else {
      enqueueSnackbar('Please select node first', {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  }

  async function submitPostMenuSetup(data) {
    const response = await postMenuSetup({
      api: api,
      data: data,
      enqueueSnackbar,
    });

    if (response) {
      if (response.status === 200) {
        enqueueSnackbar(response.msg, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        await getUserMenuData();
        if(response['data']['id']){
          proceedOnSelect(response['data']['id']);
        }
      } else {
        enqueueSnackbar(response.msg, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      }
    }
  }

  const onSubmit = handleSubmit(async (data) => {
    if (selectedNode) {
      if (newNodes.includes(selectedNode)) {
        // add
        data.action = 'add';
      } else {
        // update
        data.action = 'update';
        data.menu_id = selectedNode;
      }

      data.parent_id = parentId;
      submitPostMenuSetup(data);
    } else {
      enqueueSnackbar('Please select node first', {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  });

  useEffect(() => {
    getUserMenuData();
  }, []);

  useEffect(() => {
    proceedSetTreeMenuData(menuData);
    proceedOnSelect(selectedNode);
  }, [menuData]);

  function proceedSetTreeMenuData(data) {
    let newTreeData = buildTree(data, 0);
    setTreeMenuData([...newTreeData]);

    if (!expandedMenuKeys.length) {
      setExpandedMenuKeys([1]);
    }
  }

  return (
    <>
      <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]}>
        <CollapsedBreadcrumbs />
      </BreadcrumbsContext.Provider>
      <Card>
        <CardHeaderCss title="Menu Setup" />
        <CardContent>
          <Grid container>
            <Grid item md={6} xs={12} >
              <Typography variant="h6">Menu List</Typography>
              <Button variant="contained" color="primary" onClick={handleAddMenu} style={{ marginRight: '1rem' }}>Add New Menu</Button>
              <Button variant="outlined" color="primary" onClick={handleDeleteMenu}>Delete Menu</Button>
              <Tree
                ref={treeRef}
                className="myCls"
                onExpand={onExpandMenu}
                defaultExpandAll
                expandedKeys={expandedMenuKeys}
                treeData={treeMenuData}
                selectedKeys={selectedKeys}
                onSelect={onSelect}
                showLine
                showIcon={false}
                draggable
                onDrop={onDrop}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h6">Menu Form</Typography>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                    <TextFieldComponent
                      control={control}
                      name="parent_name"
                      marginType="normal"
                      label="Parent Menu"
                      type="text"
                      readOnly
                      errors={errors}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                    <TextFieldComponent
                      control={control}
                      name="name"
                      marginType="normal"
                      label="Menu Name"
                      type="text"
                      rules={{
                        required: {
                          value: true,
                          message: "this field is required",
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                    <TextFieldComponent
                      control={control}
                      name="seq_no"
                      marginType="normal"
                      label="Sequence No."
                      type="number"
                      rules={{
                        required: {
                          value: true,
                          message: "this field is required",
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                    <TextFieldComponent
                      control={control}
                      name="icn"
                      marginType="normal"
                      placeholder="Menu"
                      helperText="Refer this link to get the Menu Icon Name: https://mui.com/material-ui/material-icons/"
                      label="Menu Icon"
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                    <TextFieldComponent
                      control={control}
                      name="file_path"
                      marginType="normal"
                      placeholder="admin/menu-setup"
                      label="Menu Link"
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                    <SelectOptionComponent
                      control={control}
                      name="status"
                      placeholder="Status"
                      label="Status"
                      option={statusList}
                      onChangeHandler={onSelectChangeHandler("status")}
                      value={status}
                      rules={{
                        required: {
                          value: true,
                          message: "this field is required",
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                </Grid>
                <AppBarSpacer />
              </form>
              <div style={{ marginTop: '1rem' }}>
                <Button variant="contained" color="primary" onClick={onSubmit}>Update</Button>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
