import React, { useContext, useState, useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { AppBarSpacer } from "../../../css/Admin-css/listing-css";
import AuditListDatatable from "../DataTable/dt-auditList";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useForm } from "react-hook-form";
import SearchField from "../DataTable/SearchField/SearchField";

import {
    getAuditSearchOptions,
    fetchAuditList,
    handleDataTablePerRowsChange,
} from "../../../actions/admin/actionCreators";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";

export default function AuditList() {
    const api = useContext(AxiosContext);
    const [data, setData] = useState([]);
    const [eventTypeData, setEventType] = useState([]);
    const [eventTableData, setEventTable] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [startFrom, setStart] = useState(0);
    const [pending, setPending] = useState(true);
    const [breadcrumbArr, setBreadcrumbArr] = React.useState([
        {
            text: "Audit",
            link: "",
        },
    ]);

    const searchField = [
        {
            name: "event_type",
            label: "Event Type",
            placeholder: "Event Type",
            marginType: "normal",
            type: "select",
            children: eventTypeData,
        },
        {
            name: "event_table",
            label: "Event Table",
            placeholder: "Event Table",
            marginType: "normal",
            type: "select",
            children: eventTableData,
        },
        {
            name: "search_member",
            label: "Search Member",
            placeholder: "Full Name / Email",
            marginType: "normal",
            type: "text",
        },
    ];

    const { handleSubmit, control, reset, getValues } = useForm({
        defaultValues: {
            event_type: "",
            event_table: "",
            search_member: "",
        },
    });

    const onSubmit = handleSubmit(async (param) => {
        param["page"] = startFrom;
        param["per_page"] = perPage;

        const response = await fetchAuditList({
            api: api,
            data: param,
        });

        if (response) {
            if (response.status === 200) {
                setData(response.data.data);
                setTotalRows(response.data.total_records);
            }
        }
    });

    const onReset = () => {
        reset();
        onSubmit();
    };

    const getAuditSearchData = async () => {
        const eventTypeList = [];
        const eventTableList = [];
        const response = await getAuditSearchOptions({
            api: api,
        });
        if (response) {
            if (response.status == 200) {
                response.data.event_type.map((val) => {
                    eventTypeList.push({
                        value: val.event_type,
                        desc: val.event_type,
                    });
                });
                response.data.event_table.map((val) => {
                    eventTableList.push({
                        value: val.event_table,
                        desc: val.event_table,
                    });
                });
            }
        }
        setEventType(eventTypeList);
        setEventTable(eventTableList);
    };

    const getAuditListData = async () => {
        setPending(true);

        const param = {};
        param["page"] = startFrom;
        param["per_page"] = perPage;
        param["event_type"] = getValues("event_type");
        param["event_table"] = getValues("event_table");
        param["search_member"] = getValues("search_member");

        const response = await fetchAuditList({
            api: api,
            data: param,
        });

        if (response) {
            if (response.status === 200) {
                setPending(false);
                setData(response.data.data);
                setTotalRows(response.data.total_records);
            }
        }
    };

    const handlePageChange = async (page) => {
        const startPage = (page - 1) * perPage;
        setStart(startPage);
    };

    const handlePerRowsChange = async (newPerPage) => {
        const param = {};
        param["page"] = 0;
        param["per_page"] = newPerPage;
        param["event_type"] = getValues("event_type");
        param["event_table"] = getValues("event_table");
        param["search_member"] = getValues("search_member");

        const response = await handleDataTablePerRowsChange({
            api: api,
            url: "getAuditList?",
            data: param,
        });

        setData(response.data.data);
        setPerPage(newPerPage);
    };

    useEffect(() => {
        getAuditSearchData();
        getAuditListData();
    }, [startFrom]);

    return (
        <>
            <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]} >
                <CollapsedBreadcrumbs />
            </BreadcrumbsContext.Provider>
            <div className="row">
                <div className="col-md-12">
                    <SearchField
                        control={control}
                        searchField={searchField}
                        onSubmit={onSubmit}
                        onReset={onReset}
                    />

                    <AppBarSpacer />

                    <Card>
                        <CardContent>
                            <AuditListDatatable
                                totalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                fileteredData={data}
                                startFrom={startFrom}
                                pending={pending}
                            ></AuditListDatatable>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </>

    );
}
