import React, { useRef, useContext, useEffect, useState } from "react";
import { Card, CardActions, CardContent, Button, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { AxiosContext } from "../../../app/assets/axios/salesPerson/AxiosComponent";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import { postEditForm, fetchProfileInfo } from "../../../actions/salesPerson/actionCreators";
import TextFieldComponent from "../../FormAddInputComponent/TextField";
import { AppBarSpacer, CardHeaderCss } from "../../../css/Admin-css/listing-css";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/salesPerson/breadcrumb";

export default function UserUpdate(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const api = useContext(AxiosContext);
    const { enqueueSnackbar } = useSnackbar();
    const password = useRef({});
    const location = useLocation();
    // const { ent_company_id } = location?.state ? location.state : 0;

    const {
        watch,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "all",
        defaultValues: {
			name: "",
			nick_name: "",
			email: "",
			contact_no: "",
			password: "",
            confirm_password: "",
		},
    });

    const [breadcrumbArr, setBreadcrumbArr] = React.useState([
        {
            text: "Profile",
            link: "",
        },
    ]);

    const onSubmit = handleSubmit(async (data) => {
        // data["ent_company_id"] = ent_company_id;
        // data["id"] = ent_company_id;

        document.getElementById("splash-screen").classList.remove("hidden");

        const response = await postEditForm({
            api: api,
            data: data,
            method: "POST",
            url: "updateProfile",
            enqueueSnackbar,
        });

        if (response) {
            if (response.status === 200) {
                enqueueSnackbar(response.data.msg, {
                    variant: response.data.msgType,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
                if (response.data.msgType == 'success') navigate("/");
            }
        }
        document.getElementById("splash-screen").classList.add("hidden");
    });

    const getProfileInfo = async () => {
        const response = await fetchProfileInfo({
            api: api,
            url: "getProfile",
            enqueueSnackbar,
            dispatch,
        });

        if (response) {
            if (response.data.data) {
                setValue("name", response.data.data[0].name);
                setValue("nick_name", response.data.data[0].nick_name);
                setValue("contact_no", response.data.data[0].contact_no);
                setValue("email", response.data.data[0].email);
            }
        }
    };

    password.current = watch("password");

    useEffect(() => {
        getProfileInfo();
    }, []);

    return (
        <>
            <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]} >
                <CollapsedBreadcrumbs />
            </BreadcrumbsContext.Provider>
            <Card>
                <CardHeaderCss title="Profile" />
                <CardContent>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="nick_name"
                                    marginType="normal"
                                    label="Username"
                                    type="text"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "This field is required",
                                        },
                                    }}
                                    errors={errors}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="name"
                                    marginType="normal"
                                    label="Full Name"
                                    type="text"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "This field is required",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="email"
                                    marginType="normal"
                                    label="Email Address"
                                    helperText="We'll never share your email with anyone else"
                                    style={{ margin: 8 }}
                                    type="email"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "This field is required",
                                        },
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "invalid email address"
                                        }
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="contact_no"
                                    placeholder="Mobile No."
                                    marginType="normal"
                                    label="Mobile No."
                                    type="text"
                                    rules={{ required: true }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="password"
                                    placeholder="Password"
                                    marginType="normal"
                                    label="Password"
                                    type="password"
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "This field is required",
                                        },
                                        minLength: {
                                            value: 8,
                                            message: "Password must have at least 8 characters",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="confirm_password"
                                    placeholder="Confirm Password"
                                    marginType="normal"
                                    label="Confirm Password"
                                    type="password"
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "This field is required",
                                        },
                                        minLength: {
                                            value: 8,
                                            message: "Password must have at least 8 characters",
                                        },
                                        validate: (value) =>
                                            value === password.current ||
                                            "The passwords do not match",
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <AppBarSpacer />
            <CardActions
                style={{ justifyContent: "center", marginBottom: "16px" }}
            >
                <div align="center">
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={onSubmit}
                    >
                        Update
                    </Button>
                </div>
            </CardActions>
        </>
    );
}
