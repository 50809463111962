import { Controller } from "react-hook-form";
import ComboBox from "./autocomplete";
// import DateRangePicker from "./date-range-picker";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import {
	Card,
	CardContent,
	Grid,
	TextField,
	MenuItem
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DownloadIcon from '@mui/icons-material/Download';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { SelectCss, CardActionsCss, ButtonCss } from "../../../../css/Admin-css/listing-css";

function SearchField({
	searchField,
	onCreate,
	onSubmit,
	control,
	onReset,
	errors,
	setValue,
	getValues,
	exportData,
	headers,
	exportButton,
	onExportFileName,
	onExport,
}) {

	let gridElement = null;

	const theme = createTheme({
		palette: {
			primary: green,
		},
	});

	const input = searchField.map((propsField, row) => {
		switch (propsField.type) {
			case "text":
				gridElement = (
					<Controller
						name={propsField.name}
						control={control}
						defaultValue=""
						label={propsField.label}
						render={({ field }) => (
							<TextField
								{...field}
								helperText={propsField.helperText ? propsField.helperText : ""}
								placeholder={propsField.placeholder}
								margin={propsField.marginType}
								variant="outlined"
								fullWidth
								label={propsField.label}
							/>
						)}
					/>
				);
				break;
			case "select":
				const option = propsField.children.map((option, key) => {
					return (
						<MenuItem value={option.value} key={key}>
							{option.desc}
						</MenuItem>
					);
				});
				gridElement = (
					<Controller
						name={propsField.name}
						control={control}
						defaultValue=""
						label={propsField.label}
						render={({ field }) => (
							<SelectCss
								{...field}
								name={propsField.name}
								placeholder={propsField.placeholder}
								variant="outlined"
								margin={propsField.marginType}
								fullWidth
								displayEmpty
								label={propsField.label}
								labelId={propsField.name}
							>
								<MenuItem value="" selected>
									{propsField.label}
								</MenuItem>
								{option}
							</SelectCss>
						)}
					/>
				);
				break;
			// case "date_range_picker":
			// 	gridElement = (
			// 		<div>
			// 			<DateRangePicker
			// 				control={control}
			// 				setValue={setValue}
			// 				getValues={getValues}
			// 			/>
			// 		</div>
			// 	);
			// 	break;
			case "date_picker":
				gridElement = (
					<Controller
						rules={{}}
						control={control}
						defaultValue=""
						label={propsField.label}
						name={propsField.name}
						render={({ field }) => (
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									{...field}
									label={propsField.label}
									name={propsField.name}
									inputFormat="dd/MM/yyyy"
									renderInput={(startProps) => (
										<>
											<TextField
												{...startProps}
												variant="outlined"
												margin={propsField.marginType}
												error={errors}
												fullWidth
											/>
										</>
									)}
								/>
							</LocalizationProvider>
						)}
					/>
				);
				break;
			case "autocomplete":
				gridElement = (
					<div>
						<ComboBox
							control={control}
							errors={errors}
							rules={{
								required: "Franchise Username is required",
							}}
							name="franchise_nick_name"
							label="Franchise Nickname"
						/>
					</div>
				);
				break;
			default:
				break;
		}
		return (
			<Grid
				item
				md={3}
				sm={6}
				xs={12}
				key={row}
				style={{ paddingTop: 0 }}
			>
				{gridElement}
			</Grid>
		);
	});

	return (
		<Card>
			<form id="myform">
				<CardContent style={{ padding: "24px 24px 0 24px" }}>
					<Grid container spacing={2}>
						{input}
					</Grid>
				</CardContent>
			</form>
			<div className="separator separator-dashed my-7"></div>
			<CardActionsCss>
				{onCreate ? (
					<Link to={onCreate} style={{ textDecoration: 'none' }}>
						<ButtonCss
							variant="contained"
							size="large"
						>
							<AddIcon />
							&nbsp;&nbsp;Add
						</ButtonCss>
					</Link>
				) : (
					""
				)}

				<ButtonCss
					type="submit"
					variant="contained"
					color="secondary"
					size="large"
					onClick={onSubmit}
				>
					<SearchIcon />
					&nbsp;&nbsp;Search
				</ButtonCss>
				<ButtonCss
					variant="contained"
					color="grey"
					size="large"
					onClick={onReset}
				>
					<RestartAltIcon />
					&nbsp;&nbsp;Reset
				</ButtonCss>

				{exportButton ? (
					<CSVLink
						filename={onExportFileName ? onExportFileName : "listing.csv"}
						data={exportData}
						headers={headers}
						style={{ textDecoration: 'none' }}
					>
						<ButtonCss
							type="submit"
							variant="contained"
							color="primary"
							size="large"
							onClick={onExport}
						>
							<DownloadIcon />
							&nbsp;&nbsp;Export
						</ButtonCss>
					</CSVLink>
				) : (
					""
				)}
			</CardActionsCss>
		</Card>
	);
}

export default SearchField;
