import { styled } from '@mui/material/styles';
import { CardHeader, CardActions, Button, Select, Autocomplete, FormControl, InputLabel } from "@mui/material";

export const AppBarSpacer = styled('div')({
	paddingTop: "15px",
});

export const SelectCss = styled(Select)(({ theme }) => ({
	marginTop: theme.spacing(4),
}));

export const CardActionsCss = styled(CardActions)(({ theme }) => ({
	marginBottom: theme.spacing(2),
    flexWrap: 'wrap',
}));

export const ButtonCss = styled(Button)(({ theme }) => ({
	paddingTop: theme.spacing(1, "!important"),
    paddingBottom: theme.spacing(1, "!important"),
    marginLeft: theme.spacing(4, "!important"),
    width: "120px",
    marginBottom: theme.spacing(1, "!important"),
}));

export const CardHeaderCss = styled(CardHeader)(({ theme }) => ({
	borderBottom: "1px solid #ebedf2",
}));

export const ErrorMsgCss = styled('div')({
    marginTop: "5px",
    color: "#fd397a",
    fontSize: "10px",
});

export const AutocompleteCss = styled(Autocomplete)(({ theme }) => ({
	marginTop: theme.spacing(2),
}));

export const FormControlCss = styled(FormControl)(({ theme }) => ({
	minWidth: 120,
}));

export const InputLabelCss = styled(InputLabel)(({ theme }) => ({
	marginTop: theme.spacing(2),
}));

// export const SalesListStyles = makeStyles((theme) => ({
// 	root: {
// 		display: "flex",
// 	},
// 	appBarSpacer: theme.mixins.toolbar,

// 	container: {
// 		paddingTop: theme.spacing(4),
// 		paddingBottom: theme.spacing(4),
// 	},
// 	paper: {
// 		padding: theme.spacing(2),
// 		display: "flex",
// 		overflow: "auto",
// 		flexDirection: "column",
// 	},
// 	content: {
// 		flexGrow: 1,
// 		// height: "100vh",
// 		overflow: "auto",
// 	},
// 	inputContent: {
// 		marginBottom: "2rem",
// 	},
// 	buttonContent: {
// 		padding: "25px",
// 		borderTop: "1px solid #ebedf2",
// 		borderBottomLeftRadius: "4px",
// 		borderBottomRightRadius: "4px",
// 	},
// 	borderBottom: {
// 		borderBottom: "1px solid #ebedf2",
// 	},
// 	textField: {
// 		marginLeft: theme.spacing(1),
// 		paddingRight: theme.spacing(2),
// 		marginBottom: theme.spacing(2),
// 	},

// 	fontSmall: {
// 		fontSize: "12px",
// 	},

// 	dateRangePicker: {
// 		marginLeft: theme.spacing(1),
// 		marginRight: theme.spacing(1),
// 		marginBottom: theme.spacing(2),
// 	},

// 	btn_theme: {
// 		backgroundColor: "#AD8E64!important",
// 		borderColor: "#AD8E64!important",
// 	},
// 	list_item_gap: {
// 		minWidth: "30px",
// 	},
// 	theme_color: {
// 		color: "#AD8E64!important",
// 	},
// 	theme_bgcolor: {
// 		backgroundColor: "#AD8E64",
// 	},
// 	white: {
// 		color: "#FFFFFF!important",
// 	},
// }));