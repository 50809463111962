import React, { useContext, useEffect, useState, memo, useRef } from "react";
import TextField from "@mui/material/TextField";
import { AxiosContext } from "../../../../app/assets/axios/admin/AxiosComponent";
// import { fetchFranchiseUsernameList } from "../../../../actions/admin/actionCreators";
import { Controller } from "react-hook-form";
import { ErrorMsgCss, AutocompleteCss } from "../../../../css/Admin-css/listing-css";

const ComboBox = memo(({ control, rules, errors, name, label }) => {
  const api = useContext(AxiosContext);
  const timeoutRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (searchValue.length <= 0) {
      setLoading(false);
      setOpen(false);
    }
  }, [searchValue, setLoading, setOpen]);

  useEffect(() => {
    if (isOpen && searchValue.length > 0) {
      setOptions([]);
      setLoading(true);
      clearTimeout(timeoutRef.current);

      // timeoutRef.current = setTimeout(async () => {
      //   const response = await fetchFranchiseUsernameList({
      //     api: api,
      //     value: searchValue,
      //   });

      //   if (response) {
      //     if (response.data.status == 200) {
      //       setLoading(false);
      //       setOptions(response.data.data);
      //     }
      //   }
      // }, 600); // this is depend u need to hold user for how long only call api
    } else {
      setOptions([]);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [searchValue, isOpen, setLoading, setOptions]);

  return (
    <>
      <Controller
        rules={rules}
        name={name}
        control={control}
        defaultValue={null}
        render={({ field }) => (
          <AutocompleteCss
            {...field}
            control={control}
            errors={errors}
            // value={searchValue}
            inputValue={searchValue}
            options={options}
            loading={isLoading}
            open={isOpen}
            onOpen={() => setOpen(true)}
            onClose={() => {
              setLoading(false);
              setOpen(false);
            }}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.nick_name
            }
            // style={{ width: 200 }}
            onInputChange={async (event, value) => {
              if (searchValue !== value) {
                setSearchValue(value);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                error={errors[name] ? true : false}
              />
            )}
            onChange={(_, data) => {
              if (data) {
                field.onChange(
                  typeof data === "string" ? data : data.nick_name
                );
              }
            }}
          />
        )}
      />
      {errors[name] && (
        <ErrorMsgCss>
          <span>{errors[name].message}</span>
        </ErrorMsgCss>
      )}
    </>
  );
});

export default ComboBox;
