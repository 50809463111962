import * as React from 'react';
import { Link } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import * as Icons from '@mui/icons-material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { styled, useTheme } from '@mui/material/styles';
import logo from '../../assets/logo-meta-academy.png';
import '../../App.css';
import { getMenuData } from "../../actions/admin/actionCreators";
import { AxiosContext } from "../../app/assets/axios/admin/AxiosComponent";

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const LinkNoDeco = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: '#fff',
    width: '100%'
}));


export default function AsideComponent(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const api = React.useContext(AxiosContext);
    const [menuData, setMenuData] = React.useState([]);

    const handleClick = (menu) => {
        if (!open || open != menu) {
            setOpen(menu);
        } else {
            setOpen(false);
        }
    };

    const getMenu = async () => {
        const response = await getMenuData({
            api: api,
            data: [],
        });
        setMenuData(response.data ?? []);
    };

    React.useEffect(() => {
        getMenu();
    }, []);

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    backgroundColor: '#1a1a27'
                },
            }}
            variant="persistent"
            anchor="left"
            open={props.status}
        >
            <DrawerHeader>
                <img src={logo} className="App-logo" alt="logo" />
                <IconButton onClick={props.action} color="white">
                    {theme.direction === 'ltr' ? <Icons.ChevronLeft /> : <Icons.ChevronRight />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List sx={{ color: '#FFFFFF' }}>
                { Object.keys(menuData).length > 0 && 
                    (menuData).map((menu) => {
                        let DynamicIcon = menu.icn ? Icons[menu.icn] : Icons['Menu'];
                        return ((Object.keys(menu.children).length > 0) ? (
                            <div key={menu.name}>
                                <ListItem disablePadding xs={{ display: 'flex', flexDirection: 'column' }}>
                                    <ListItemButton onClick={() => handleClick(menu.name)}>
                                        <ListItemIcon sx={{ color: '#AD8E64' }}>
                                            <DynamicIcon />
                                        </ListItemIcon>
                                        <ListItemText disableTypography primary={<Typography variant="span">{menu.name}</Typography>} />
                                        {(open == menu.name) ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
                                    </ListItemButton>
                                </ListItem>
                                <ListItem sx={{ paddingLeft: '56px', paddingTop: '0px', paddingBottom: '0px' }}>
                                    <Collapse in={(open == menu.name)} timeout="auto" unmountOnExit>
                                        {(menu.children).map((child) => {
                                            return (
                                                <LinkNoDeco key={child.name} to={child.file_path ?? "#"}>
                                                    <List component="div" disablePadding>
                                                        <ListItemButton sx={{ pl: 4 }}>
                                                            <ListItemText disableTypography primary={<Typography variant="span">{child.name}</Typography>} />
                                                        </ListItemButton>
                                                    </List>
                                                </LinkNoDeco>
                                            )
                                        })}
                                    </Collapse>
                                </ListItem>
                            </div>
                        ) : (
                            <ListItem key={menu.name} disablePadding>
                                <LinkNoDeco to={menu.file_path ?? "#"}>
                                    <ListItemButton>
                                        <ListItemIcon sx={{ color: '#AD8E64' }}>
                                            <DynamicIcon />
                                        </ListItemIcon>
                                        <ListItemText disableTypography primary={<Typography variant="span">{menu.name}</Typography>} />
                                    </ListItemButton>
                                </LinkNoDeco>
                            </ListItem>
                        ))
                    })
                }
            </List>
        </Drawer>
    );
}
