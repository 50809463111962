import React, { memo } from "react";
import routes from "./routes";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

const MainRoutes = memo(() => {
    const temp = useSelector((state) => ({
        isLoggedIn: state.LoginReducer.isLoggedIn,
    }));
    return (
        <Routes>
            {routes.map((route, i) => {
                if ((route.auth && temp.isLoggedIn) || (!route.auth && !temp.isLoggedIn)) {
                    return <Route key={i} {...route} path={route.path} element={route.element} exact={route.exact} />
                }
            })}
            <Route
                path="*"
                element={<Navigate to="/admin/dashboard" replace />}
            />
        </Routes>
    );
});

export default MainRoutes;
