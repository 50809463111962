import React, { useContext, useState, useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { AppBarSpacer } from "../../../css/Admin-css/listing-css";
import CustomerListDatatable from "../DataTable/dt-customerList";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useForm } from "react-hook-form";
import SearchField from "../DataTable/SearchField/SearchField";

import {
	getCountry,
	fetchProductList,
	fetchCustomerList,
    fetchSalesPersonList,
	handleDataTablePerRowsChange,
} from "../../../actions/admin/actionCreators";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";

export default function CustomerList() {
	const api = useContext(AxiosContext);
	const [data, setData] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(50);
	const [startFrom, setStart] = useState(0);
	const [pending, setPending] = useState(true);
	const [country_id, setCountry] = useState([]);
	const [product_id, setProduct] = useState([]);
    const [sales_person, setSalesPerson] = useState([]);
	const [exportData, setExportData] = useState([]);
	const [breadcrumbArr, setBreadcrumbArr] = React.useState([
		{
			text: "Customer",
			link: "",
		}, 
	]);

	const searchField = [
		{
			name: "full_name",
			label: "Full Name",
			placeholder: "Full Name",
			marginType: "normal",
			type: "text",
		},
		{
			name: "email",
			label: "Email",
			placeholder: "example@email.com",
			marginType: "normal",
			type: "text",
		},
		{
			name: "mobile_no",
			label: "Mobile No",
			placeholder: "+60123456789",
			marginType: "normal",
			type: "text",
		},
		{
			name: "country_id",
			label: "Country",
			placeholder: "Country",
			marginType: "normal",
			type: "select",
			children: country_id,
		},
		{
			name: "product_id",
			label: "Campaign",
			placeholder: "Campaign",
			marginType: "normal",
			type: "select",
			children: product_id,
		},
        {
			name: "sales_person",
			label: "Sales person",
			placeholder: "Sales Person",
			marginType: "normal",
			type: "select",
			children: sales_person,
		},
	];

	const { handleSubmit, control, reset, getValues, setValue } = useForm({
		defaultValues: {
			full_name: "",
			email: "",
			mobile_no: "",
			country_id: "",
			product_id: "",
            sales_person: "",
		},
	});

	const getCountryData = async () => {
		const countryList = [];
		const response = await getCountry({
			api: api,
		});
		if (response) {
			if (response.status == 200) {
				response.data.map((val) => {
					countryList.push({
						value: val.id,
						desc: val.name,
					});
				});
			}
		}
		setCountry(countryList);
	};

	const getProductData = async () => {
		const productList = [];
		const response = await fetchProductList({
			api: api,
		});
		if (response) {
			if (response.status == 200) {
				response.data.data.map((val) => {
					productList.push({
						value: val.id,
						desc: val.name,
					});
				});
			}
		}
		setProduct(productList);
	}
    
    const getSalesPersonData = async () => {
        const salesPersonList = [];
		const response = await fetchSalesPersonList({
			api: api,
		});
		if (response) {
			if (response.status == 200) {
				response.data.data.map((val) => {
				    salesPersonList.push({
				        value: val.id,
				        desc: val.name,
				    });
				});
				setSalesPerson(salesPersonList);
			}
		}
	}

	const getCustomerListData = async () => {
		setPending(true);

		const param = {};
		param["page"]         = startFrom;
		param["per_page"]     = perPage;
		param["email"]        = getValues("email");
		param["full_name"]    = getValues("full_name");
		param["mobile_no"]    = getValues("mobile_no");
		param["country_id"]   = getValues("country_id");
		param["product_id"]   = getValues("product_id");
        param["sales_person"] = getValues("sales_person");

		const response = await fetchCustomerList({
			api: api,
			data: param,
		});

		if (response) {
			if (response.status == 200) {
				setPending(false);
				if (response.data.total_records > 0) {
					setData(response.data.data);
					setTotalRows(response.data.total_records);
				}
			}
		}
	};

	const getCustomerListExportData = async () => {
		const param = {};
		param["email"]        = getValues("email");
		param["full_name"]    = getValues("full_name");
		param["mobile_no"]    = getValues("mobile_no");
		param["country_id"]   = getValues("country_id");
		param["product_id"]   = getValues("product_id");
        param["sales_person"] = getValues("sales_person");
		
		const response = await fetchCustomerList({
			api: api,
			data: param,
		});

		if (response) {
			if (response.status == 200) {
				const result = response.data.data;
				setExportData((result && result.length > 0) 
					? result.map((x, index) => ({ 
						"No.": index+1,
						"Full Name": x.full_name, 
						"Email Address": x.email, 
						"Contact No": x.contact_no, 
						"Birthday": x.birth_date, 
						"Sales Person": x.sales_person_name, 
						"Country": x.country_name, 
						"Status": x.status_desc, 
						"Ecommerce Account": x.ecom_user_id ? 'Yes' : 'No',
						"Remark": x.remark,
						"Created Date": x.created_at })) 
					: []);
			}
		}
	};

	const onSubmit = handleSubmit(async (param) => {
		param["page"]     = startFrom;
		param["per_page"] = perPage;

		const response = await fetchCustomerList({
			api: api,
			data: param,
		});

		setData(response.data.data);
		setTotalRows(response.data.total_records);
		getCustomerListExportData();
	});

	const onReset = () => {
		reset();
		onSubmit();
	};

	// const onExport = async () => {
	// 	const response = await fetchCustomerList({
	// 		api: api,
	// 		data: param,
	// 	});

	// 	setData(response.data.data);
	// 	setTotalRows(response.data.total_records);
	// 	getCustomerListExportData();
	// };

	const handlePageChange = async (page) => {
		const startPage = (page - 1) * perPage;
		setStart(startPage);
	};

	const handlePerRowsChange = async (newPerPage) => {
		const param = {};

		param["page"]      = 0;
		param["per_page"]  = newPerPage;
		param["email"]     = getValues("email");
		param["mobile_no"] = getValues("mobile_no");
		param["full_name"] = getValues("full_name");

		const response = await handleDataTablePerRowsChange({
			api: api,
			url: "getCustomerList",
			data: param,
		});

		setData(response.data.data);
		setPerPage(newPerPage);
	};

	useEffect(() => {
		getCountryData();
		getProductData();
        getSalesPersonData();
		getCustomerListData();
		getCustomerListExportData();
	}, [startFrom]);

	return (
		<>
		<BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]} >
			<CollapsedBreadcrumbs />
		</BreadcrumbsContext.Provider>
		<div className="row">
			<div className="col-md-12">
				{/* <FormRow /> */}
				<SearchField
					control={control}
					searchField={searchField}
					onSubmit={onSubmit}
					onReset={onReset}
					getValues={getValues}
					onCreate="/admin/customer/create"
					setValue={setValue}
					exportData={exportData}
					exportButton={true}
					onExportFileName={"customer_listing.csv"}
				/>

                <AppBarSpacer/>
				<Card>
					<CardContent>
						<CustomerListDatatable
							totalRows={totalRows}
							onChangeRowsPerPage={handlePerRowsChange}
							onChangePage={handlePageChange}
							fileteredData={data}
							startFrom={startFrom}
							pending={pending}
						></CustomerListDatatable>
					</CardContent>
				</Card>
			</div>
		</div>
		</>
	);
}
