import React, { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardContent } from "@mui/material";
import { AppBarSpacer } from "../../../css/Admin-css/listing-css";
import UserListDatatable from "../DataTable/dt-userList";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useForm } from "react-hook-form";
import SearchField from "../DataTable/SearchField/SearchField";
import {
    fetchUserList,
    handleDataTablePerRowsChange,
} from "../../../actions/admin/actionCreators";
import { useSnackbar } from "notistack";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";

export default function UserList() {
    const api = useContext(AxiosContext);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [startFrom, setStart] = useState(0);
    const [pending, setPending] = useState(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [breadcrumbArr, setBreadcrumbArr] = React.useState([
        {
            text: "Admin",
            link: "",
        },
    ]);

    const searchField = [
        {
            name: "nick_name",
            label: "Username",
            placeholder: "Username",
            marginType: "normal",
            type: "text",
        },
        {
            name: "name",
            label: "Name",
            placeholder: "Name",
            marginType: "normal",
            type: "text",
        },
        {
            name: "email",
            label: "Email",
            placeholder: "Email",
            marginType: "normal",
            type: "text",
        },
    ];

    const { handleSubmit, control, reset, getValues } = useForm({
        defaultValues: {
            nick_name: "",
            name: "",
            email: "",
        },
    });

    const onSubmit = handleSubmit(async (param) => {
        param["page"] = startFrom;
        param["per_page"] = perPage;

        const response = await fetchUserList({
            api: api,
            data: param,
        });

        if (response) {
            if (response.status == 200) {
                if (response.data.total_records > 0) {
                    setData(response.data.data);
                    setTotalRows(response.data.total_records);
                }
            }
        }
    });

    const onReset = () => {
        reset();
        onSubmit();
    };

    const getUserListData = async () => {
        setPending(true);
        const param = {};
        param["page"] = startFrom;
        param["per_page"] = perPage;
        param["email"] = getValues("email");
        param["nick_name"] = getValues("nick_name");
        param["name"] = getValues("name");

        const response = await fetchUserList({
            api: api,
            data: param,
            dispatch,
            enqueueSnackbar,
        });

        if (response) {
            if (response.status == 200) {
                setPending(false);
                if (response.data.total_records > 0) {
                    setData(response.data.data);
                    setTotalRows(response.data.total_records);
                }
            }
        }
    };

    const handlePageChange = async (page) => {
        const startPage = (page - 1) * perPage;
        setStart(startPage);
    };

    const handlePerRowsChange = async (newPerPage) => {
        const param = {};
        param["page"] = 0;
        param["per_page"] = newPerPage;
        param["email"] = getValues("email");
        param["nick_name"] = getValues("nick_name");
        param["name"] = getValues("name");

        const response = await handleDataTablePerRowsChange({
            api: api,
            url: "getAdminList?status=A",
            data: param,
        });

        setData(response.data.data);
        setPerPage(newPerPage);
    };

    useEffect(() => {
        getUserListData();
    }, [startFrom]);

    return (
        <>
            <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]} >
                <CollapsedBreadcrumbs />
            </BreadcrumbsContext.Provider>
            <div className="row">
                <div className="col-md-12">
                    <SearchField
                        control={control}
                        searchField={searchField}
                        onSubmit={onSubmit}
                        onReset={onReset}
                        onCreate="/admin/user/create"
                    />
                    <AppBarSpacer />
                    <Card>
                        <CardContent>
                            <UserListDatatable
                                totalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                fileteredData={data}
                                startFrom={startFrom}
                                pending={pending}
                            ></UserListDatatable>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </>

    );
}
