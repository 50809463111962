import * as action_types from "./actionTypes";
import * as action from "./action";

const API_BASE_URL = process.env.REACT_APP_SERVER_URL;

/* meta crm start */
export const fetchSalesList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/get-all-sales-list`,
    method: "POST",
    data,
    params: {
      member_id: data.member_id,
      invoice_no: data.invoice_no,
      RefTxCode: data.RefTxCode,
      vmore_ref_no: data.vmore_ref_no,
      date_from: data.date_from ? data.date_from : "",
      date_to: data.date_to ? data.date_to : "",
      isVoid: data.isVoid,
    },
  });

  return response.data;
};

// Create Form - customer, sales person, assign sales person, product
export const postCreateForm = async ({
  api,
  data,
  url,
  method,
  enqueueSnackbar,
}) => {
  const response = await api({
    url: `${API_BASE_URL}admin/${url}`,
    method: method,
    data,
  }).catch((err) => {
    const message = err.response.data.message;
    document.getElementById("splash-screen").classList.add("hidden");
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  });

  return response;
};

// Edit Form - sales person, product
export const postEditForm = async ({
  api,
  data,
  url,
  method,
  enqueueSnackbar,
}) => {
  // console.log(data);
  const response = await api({
    url: `${API_BASE_URL}admin/${url}`,
    method: method,
    data,
  }).catch((err) => {
    console.log(err);
    const message = err.response.data.message;
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  });

  return response?.data;
};

// Get Edit Info - product
export const fetchEditInfoList = async ({ api, data, url }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/${url}`,
    method: "POST",
    data,
  });

  return response?.data;
};

// Login
export const LoginAction = ({ data, api, enqueueSnackbar }) => {
  return (dispatch) => {
    api({
      url: `${API_BASE_URL}login`,
      method: "POST",
      data,
    })
      .then((result) => {
        if (result.data.message) {
          const message = result.data.message;
          enqueueSnackbar(message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
        localStorage.setItem("token", result.data.data.token);
        localStorage.setItem("name", result.data.data.name);
        localStorage.setItem("nick_name", result.data.data.nick_name);

        dispatch(
          action.userSessionAuthenticate(action_types.userSessionAuthenticate)
        );
        return dispatch(
          action.AdminLoginResult(
            action_types.storeLoginToken,
            result.data.data.token,
            result.data.data.nick_name,
            result.data.data.name
          )
        );
      })
      .catch((err) => {
        if (err.response) {
          const message = err.response.data.message;
          enqueueSnackbar(message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      });
  };
};

// Logout
export const LogoutAction = ({ api }) => {
  return (dispatch) => {
    api({
      url: `${API_BASE_URL}admin/logout`,
      method: "POST",
    })
      .then((result) => {
        if (result.status === 200) {
          return dispatch(
            action.UpdateLoginResult(
              action_types.removeLoginToken,
              result.data.data.token
            )
          );
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
};

// User Lisitng
export const fetchUserList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getAdminList?status=A`,
    method: "POST",
    data,
  });

  return response?.data;
};

// User Group Listing
export const fetchUserGroupList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getUserGroupList?status=A`,
    method: "POST",
    data,
  });

  return response?.data;
};

// Handle Table Per Rows Change
export const handleDataTablePerRowsChange = async ({ api, url, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/${url}`,
    method: "POST",
    data,
  });
  return response?.data;
};

// Upload CSV
export const uploadCsv = async ({
  api,
  data,
  url,
  method,
  enqueueSnackbar,
  // setError,
  // dispatch
}) => {
  const response = await api({
    url: `${API_BASE_URL}admin/${url}`,
    method: method,
    data,
  }).catch((err) => {
    if (err.response.data.errors) {
      const error = Object.entries(err.response.data.errors);

      enqueueSnackbar(error[1][1], {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      // error.forEach(([key, element]) => {
      //    // setError(key, {
      //    //   type: "required",
      //    //   message: element[0],
      //    // });
      //    enqueueSnackbar(element[0], {
      //      variant: "error",
      //      anchorOrigin: {
      //        vertical: "top",
      //        horizontal: "center",
      //      },
      //    });
      //    // console.log(dispatch);
      //    // dispatch(action.isLoading(action_types.isLoadingAction, false));
      //  });
    }
  });

  return response?.data;
};

// Save Import Data
export const postImportData = async ({
  api,
  data,
  url,
  method,
  enqueueSnackbar,
  setError,
}) => {
  const response = await api({
    url: `${API_BASE_URL}admin/${url}`,
    method: method,
    data,
  }).catch((err) => {
    if (err.response.data.errors) {
      const error = Object.entries(err.response.data.errors);

      error.forEach(([key, element]) => {
        setError(key, {
          type: "required",
          message: element[0],
        });
        enqueueSnackbar(element[0], {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      });
    }
  });

  return response.data;
};

// Customer Listing
export const fetchCustomerList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getCustomerList`,
    method: "POST",
    data,
  });
  return response?.data;
};

// Sales Person Listing
export const fetchSalesPersonList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getSalesPersonList`,
    method: "POST",
    data,
  });

  return response?.data;
};

// Product Listing (Campaign)
export const fetchProductList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getProductList`,
    method: "POST",
    data,
  });

  return response?.data;
};

// Country Listing
export const fetchCountryList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getCountryList`,
    method: "POST",
    data,
  });

  return response?.data;
};

// Country Data
export const getCountry = async ({ api }) => {
  const response = await api({
    url: `${API_BASE_URL}getCountry`,
    method: `GET`,
  });

  return response?.data;
};

// Language Data
export const getLanguage = async ({ api }) => {
  const response = await api({
    url: `${API_BASE_URL}getLanguage`,
    method: `GET`,
  });

  return response?.data;
};

// General Data
export const getSysGeneralData = async ({ api, type }) => {
  const response = await api({
    url: `${API_BASE_URL}getSystemGeneralData`,
    method: "POST",
    params: {
      type: type,
          },
  });
  return response?.data;
};

export const getDashboardCustomerData = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getDashboardCustomer`,
    method: "POST",
    data,
  });
  return response?.data;
};

export const getDashboardSalesPersonData = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getDashboardSalesPerson`,
    method: "POST",
    data,
  });
  return response?.data;
};

export const getDashboardCampaignData = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getDashboardCampaign`,
    method: "POST",
    data,
  });
  return response?.data;
};

export const getDashboardCountryData = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getDashboardCountry`,
    method: "POST",
    data,
  });
  return response?.data;
};

export const getDashboardSalesData = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getDashboardSales`,
    method: "POST",
    data,
  });
  return response?.data;
};

export const getDashboardInstallmentData = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getDashboardInstallment`,
    method: "POST",
    data,
  });
  return response?.data;
};

export const getDashboardInstallmentReminder = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getInstallmentReminder`,
    method: "POST",
    data,
  });

  return response?.data;
};

// total sales list
export const getCampaignSalesData = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getCampaignSalesData`,
    method: "POST",
    data,
  });
  return response?.data;
};

export const getSalesPersonSalesData = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getSalesPersonSalesData`,
    method: "POST",
    data,
  });
  return response?.data;
};
// Translation Listing
export const fetchTranslationList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getTranslationList`,
    method: "POST",
    data,
  });
  return response?.data;
};

export const getTranslationSearchOptions = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getTranslationSearchOptions`,
    method: "POST",
    data,
  });
  return response?.data;
};

// Audit Listing
export const fetchAuditList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getAuditList`,
    method: "POST",
    data,
  });
  return response?.data;
};

export const getAuditSearchOptions = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getAuditSearchOptions`,
    method: "POST",
    data,
  });
  return response?.data;
};

// Payment Type Listing
export const fetchPaymentTypeList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getPaymentTypeList`,
    method: "POST",
    data,
  });

  return response?.data;
};

// Installment Listing
export const fetchInstallmentList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getInstallmentList`,
    method: "POST",
    data,
  });
  return response?.data;
};

// Installment Details
export const fetchInstallmentDetails = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getInstallmentDetails`,
    method: "POST",
    data,
  });
  return response?.data;
};

// user group
export const getMenuData = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getMenu`,
    method: "POST",
    data,
  });

  return response?.data;
};

export const getUserGroupTree = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getUserGroupTree`,
    method: "POST",
    data,
  });

  return response?.data;
};

export const getUserGroupMenuData = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/getUserGroupMenuTree`,
    method: "POST",
    data,
  });

  return response?.data;
};

export const updateUserGroupMenu = async ({ api, data, enqueueSnackbar }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/updateUserGroupMenu`,
    method: "POST",
    data,
  }).catch((err) => {
    const message = err.response.data.message;
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  });

  return response?.data;
};

export const postMenuSetup = async ({ api, data, enqueueSnackbar }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/menuSetup`,
    method: "POST",
    data,
  }).catch((err) => {
    const message = err.response.data.message;
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  });

  return response?.data;
};

export const postUserGroupSetup = async ({ api, data, enqueueSnackbar }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/userGroupSetup`,
    method: "POST",
    data,
  }).catch((err) => {
    const message = err.response.data.message;
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  });

  return response?.data;
};

export const fetchEcommerceOrderList = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/get-ecommerce-sales-list`,
    method: "POST",
    data,
  });

  return response.data;
};

export const fetchEcommerceOrderList2 = async ({ api, data }) => {
  const response = await api({
    url: `${API_BASE_URL}admin/get-ecommerce-sales-list2`,
    method: "POST",
    data,
  });

  return response.data;
};
/* meta crm end */
