import React, { useContext, useState, useEffect } from "react";

import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";
import { Card, CardHeader, CardContent, Typography, Button, Grid, useTheme } from "@mui/material";
import { AppBarSpacer, CardHeaderCss } from "../../../css/Admin-css/listing-css";
import "../../../css/Admin-css/tree-style.css";
import Tree, { TreeNode } from 'rc-tree';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TextFieldComponent from "../../FormAddInputComponent/TextField";
import SelectOptionComponent from "../../FormAddInputComponent/SelectOption";
import { useForm } from "react-hook-form";
import { getUserGroupTree, postUserGroupSetup } from "../../../actions/admin/actionCreators";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function UserGroupSetup() {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedNode, setSelectedNode] = useState();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedNodeName, setSelectedNodeName] = useState();
  const treeRef = React.createRef();
  const MySwal = withReactContent(Swal);
  const theme = useTheme();
  const {
    watch,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "all",
  });

  const [userGroupData, setUserGroupData] = useState([]);
  const [treeUserGroupData, setTreeUserGroupData] = useState([]);
  const [expandedUserGroupKeys, setExpandedUserGroupKeys] = useState([]);
  const [newNodes, setNewNodes] = useState([]);
  const [parentId, setParentId] = useState('');
  const api = useContext(AxiosContext);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [breadcrumbArr, setBreadcrumbArr] = React.useState([
    {
      text: "Admin",
      link: "/admin/user-list",
    },
    {
      text: "User Group Setup",
      link: "/admin/user-group-setup",
    },
  ]);

  const statusList = [
    { id: "A", name: "Active" },
    { id: "I", name: "Inactive" },
  ];

  function buildTree(data, parentId) {
    const tree = [];

    data.map((item, index) => {
      if (item.parent_id === parentId || (parentId == 0 && index == 0)) {
        item.key = item.id;
        item.title = <div style={{ display: 'flex', alignItems: 'center' }}><Typography>{item.name}</Typography> <Typography style={{ fontSize: 12 }}>({item.seq_no})</Typography></div>;

        const children = buildTree(data, item.id);
        if (children.length) {
          item.children = children;
        }
        tree.push(item);
      }
    });

    return tree;
  }

  const getUserGroupData = async () => {
    const response = await getUserGroupTree({
      api: api,
      data: {}
    });

    if (response) {
      if (response.status == 200) {
        setUserGroupData(response.data);
      }
    }
  }

  const onExpandUserGroup = expandedKeys => {
    setExpandedUserGroupKeys(expandedKeys);
  };

  const onSelectChangeHandler = (name) => (event) => {
    const value = event.target.value;
    if (name == "status") {
      setStatus(value);
    }
    setValue(name, value);
  };

  function proceedOnSelect(selectedKey) {
    setSelectedNode(selectedKey);
    setSelectedKeys([selectedKey]);

    let newData = [...userGroupData];

    newData.map((dt, key) => {
      if (dt.key === selectedKey) {
        setValue('parent_name', dt.parent_name ?? '-');
        setValue('code', dt.code);
        setValue('name', dt.name);
        setValue('seq_no', dt.seq_no);
        setValue('status', dt.status);
        setStatus(dt.status);
        setParentId(dt.parent_id ?? 0);
        setSelectedNodeName(dt.name);
      }
    });
  }

  const onSelect = info => {
    proceedOnSelect(info[0]);
  };

  const onDrop = event => {
    MySwal.fire({
      title: <strong>Are you sure?</strong>,
      html: <label>You won't be able to revert this!</label>,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.danger.main,
      confirmButtonText: 'Yes, move now !',
      customClass: {
        confirmButton: 'confirm-button-class',
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        let postData = {};
        postData.action = 'update';
        postData.sys_group_id = event.dragNode.id;
        postData.parent_id = event.node.id;
        await submitPostUserGroupSetup(postData);
        handleExpandNode(event.node.id);
      }
    });
  }

  function handleExpandNode(selectedNode) {
    let newExpandedUserGroupKeys = expandedUserGroupKeys;
    if (!newExpandedUserGroupKeys.includes(selectedNode)) {
      newExpandedUserGroupKeys.push(selectedNode);
    }
    setExpandedUserGroupKeys(newExpandedUserGroupKeys);
  }

  const handleAddUserGroup = () => {
    if (selectedNode) {
      let dataArray = userGroupData;
      let item = [];

      item.id = Date.now();
      item.key = item.id;
      item.parent_name = selectedNodeName;
      item.parent_id = selectedNode;
      item.code = '';
      item.name = 'New Node';
      item.seq_no = '';
      item.status = 'A';

      dataArray.push({ ...item });

      // insert new node array
      let newNodeArray = newNodes;
      if (!newNodeArray.includes(item.id)) {
        newNodeArray.push(item.id);
      }
      setNewNodes(newNodeArray);

      // expand parent if not expanded
      handleExpandNode(selectedNode);

      setTreeUserGroupData(buildTree(dataArray, 0));
      proceedOnSelect(item.key);
    } else {
      enqueueSnackbar('Please select node first', {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  }

  const handleDeleteUserGroup = () => {
    if (selectedNode) {
      MySwal.fire({
        title: <strong>Are you sure?</strong>,
        html: <label>You won't be able to revert this!</label>,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: theme.palette.primary.main,
        cancelButtonColor: theme.palette.danger.main,
        confirmButtonText: 'Yes, delete now !',
        customClass: {
          confirmButton: 'confirm-button-class',
        }
      }).then((result) => {
        if (result.isConfirmed) {
          if (newNodes.includes(selectedNode)) {
            // delete new node
            getUserGroupData();
          } else {
            // delete existing node
            let postData = {};
            postData.action = 'delete';
            postData.sys_group_id = selectedNode;
            submitPostUserGroupSetup(postData);
          }
        }
      });
    } else {
      enqueueSnackbar('Please select node first', {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  }

  async function submitPostUserGroupSetup(data) {
    const response = await postUserGroupSetup({
      api: api,
      data: data,
      enqueueSnackbar,
    });

    if (response) {
      if (response.status === 200) {
        enqueueSnackbar(response.msg, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        await getUserGroupData();
        if(response['data']['id']){
          proceedOnSelect(response['data']['id']);
        }
      } else {
        console.log(response);
        enqueueSnackbar(response.msg, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      }
    }
  }

  const onSubmit = handleSubmit(async (data) => {
    if (selectedNode) {
      if (newNodes.includes(selectedNode)) {
        // add
        data.action = 'add';
      } else {
        // update
        data.action = 'update';
        data.sys_group_id = selectedNode;
      }

      data.parent_id = parentId;
      submitPostUserGroupSetup(data);
    } else {
      enqueueSnackbar('Please select node first', {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  });

  useEffect(() => {
    getUserGroupData();
  }, []);

  useEffect(() => {
    proceedSetTreeUserGroupData(userGroupData);
    proceedOnSelect(selectedNode);
  }, [userGroupData]);

  function proceedSetTreeUserGroupData(data) {
    let newTreeData = buildTree(data, 0);
    setTreeUserGroupData([...newTreeData]);

    if (!expandedUserGroupKeys.length) {
      setExpandedUserGroupKeys([1]);
    }
  }

  return (
    <>
      <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]}>
        <CollapsedBreadcrumbs />
      </BreadcrumbsContext.Provider>
      <Card>
        <CardHeaderCss title="User Group Setup" />
        <CardContent>
          <Grid container>
            <Grid item md={6} xs={12} >
              <Typography variant="h6">User Group List</Typography>
              <Button variant="contained" color="primary" onClick={handleAddUserGroup} style={{ marginRight: '1rem' }}>Add New User Group</Button>
              <Button variant="outlined" color="primary" onClick={handleDeleteUserGroup}>Delete User Group</Button>
              <Tree
                ref={treeRef}
                className="myCls"
                onExpand={onExpandUserGroup}
                defaultExpandAll
                expandedKeys={expandedUserGroupKeys}
                treeData={treeUserGroupData}
                selectedKeys={selectedKeys}
                onSelect={onSelect}
                showLine
                showIcon={false}
                draggable
                onDrop={onDrop}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h6">User Group Form</Typography>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                    <TextFieldComponent
                      control={control}
                      name="parent_name"
                      marginType="normal"
                      label="Parent Group"
                      type="text"
                      readOnly
                      errors={errors}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                    <TextFieldComponent
                      control={control}
                      name="code"
                      marginType="normal"
                      label="User Group Code"
                      type="text"
                      rules={{
                        required: {
                          value: true,
                          message: "this field is required",
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                    <TextFieldComponent
                      control={control}
                      name="name"
                      marginType="normal"
                      label="User Group Name"
                      type="text"
                      rules={{
                        required: {
                          value: true,
                          message: "this field is required",
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                    <TextFieldComponent
                      control={control}
                      name="seq_no"
                      marginType="normal"
                      label="Sequence No."
                      type="number"
                      rules={{
                        required: {
                          value: true,
                          message: "this field is required",
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                    <SelectOptionComponent
                      control={control}
                      name="status"
                      placeholder="Status"
                      label="Status"
                      option={statusList}
                      onChangeHandler={onSelectChangeHandler("status")}
                      value={status}
                      rules={{
                        required: {
                          value: true,
                          message: "this field is required",
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                </Grid>
                <AppBarSpacer />
              </form>
              <div style={{ marginTop: '1rem' }}>
                <Button variant="contained" color="primary" onClick={onSubmit}>Update</Button>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
