import React, { useState, useRef, useEffect } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.accessToken = 'pk.eyJ1Ijoic3VjaGl5YXAiLCJhIjoiY2w0cXNxa3J6MDlmdzNrbW41dTdqNDBvOSJ9.a3gSOq8z8IygNU-c8qu_hg';

export default function Map(props){

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(102.15);
    const [lat, setLat] = useState(3.78);
    const [zoom, setZoom] = useState(2);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/light-v10',
            center: [lng, lat],
            zoom: zoom
        });
        
        if(props.data){
            props.data.map((country, index) => {
                var popup = new mapboxgl.Popup({ offset: 25 }).setHTML(`
                    <p>${country.country}: ${country.value}</p>
                `);
    
                new mapboxgl.Marker({color: '#FF5A5A'})
                .setLngLat([country.longitude, country.latitude])
                .setPopup(popup)
                .addTo(map.current);
            });
        }
    });

    return (
        <div ref={mapContainer} className="map-container" style={{width: '100%', height: '100%', minHeight: '300px'}}/>
    );
}