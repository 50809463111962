import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import customStyles from "./dt-main-style";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip, Chip } from "@mui/material";

export default function DataTable2(props) {
	const status = {
		C: { class: "warning" },
		W: { class: "primary" },
		H: { class: "success" },
		B: { class: "error" },
		D: { class: "error" },
	};

	const columns = [
		{
			name: "Actions",
			sortable: false,
			selector: row => row.nick_name,
			center: true,
			maxWidth: "80px",
			cell: (row) => (
				<>
					<Link
						to={{
							pathname: "/admin/customer/create",
							
						}}
                        state={{ 
                            ent_member_id: row.id 
                        }}
					>
						<Tooltip title="Update Customer">
							<IconButton color="primary">
								<EditIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</Link>
				</>
			),
		},
		{
			name: "No.",
			sortable: true,
			grow: 0,
			minWidth: "70px",
			cell: function (data, index) {
				return index + 1 + parseFloat(props.startFrom);
			},
		},
		{
			name: "Full Name",
			selector: row => row.full_name,
			sortable: true,
			minWidth: "180px",
		},
		{
			name: "Email Address",
			selector: row => row.email,
			sortable: true,
			minWidth: "220px",
		},
		{
			name: "Contact No",
			selector: row => row.contact_no,
			sortable: true,
			minWidth: "150px",
		},
		{
			name: "Birthday",
			selector: row => row.birth_date,
			sortable: true,
			minWidth: "120px",
		},
		{
			name: "Sales Person",
			selector: row => row.sales_person_name,
			sortable: true,
			minWidth: "180px",
		},
		{
			name: "Country",
			selector: row => row.country_name,
			sortable: true,
			minWidth: "140px",
		},
		{
			name: "Status",
			selector: row => row.status,
			sortable: true,
			center: true,
			maxWidth: "120px",
			cell: (row) => (
				<Chip color={status[row["status"]].class} label={row["status_desc"]}></Chip>
			),
		},
		{
			name: "Ecommerce Account",
			selector: row => row.ecom_user_id,
			sortable: true,
			minWidth: "180px",
			cell: (row) => (
                <div>{row["ecom_user_id"] ? 'Yes' : 'No'}</div>
            ),
		},
        {
			name: "Remark",
			selector: row => row.remark,
			sortable: true,
			minWidth: "180px",
		},
		{
			name: "Created Date",
			selector: row => row.created_at,
			sortable: true,
			minWidth: "190px",
		},
	];

	return (
		<DataTable
			columns={columns}
			data={props.fileteredData}
			customStyles={customStyles}
			noHeader
			pagination
			paginationServer
			paginationPerPage={50}
			paginationRowsPerPageOptions={[50, 100, 150]}
			paginationTotalRows={props.totalRows}
			onChangeRowsPerPage={props.onChangeRowsPerPage}
			onChangePage={props.onChangePage}
			progressPending={props.pending}
		/>
	);
}
