import React from "react";
import DataTable from "react-data-table-component";
import customStyles from "./dt-main-style";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip, TextField, Typography } from "@mui/material";

export default function DataTable2(props) {
    const columns = [
        {
            name: "#",
            sortable: true,
            grow: 0,
            cell: function (data, index) {
                return index + 1 + parseFloat(props.startFrom);
            },
        },
        {
            name: "Group",
            sortable: true,
            width: "150px",
            selector: row => row.group,
            cell: (row) => {
                return (
                    <Typography>{row.group}</Typography>
                );
            },
        },
        {
            name: "Type",
            selector: row => row.type,
            sortable: true,
            width: "150px",
            cell: (row) => {
                return (
                    <Typography>{row.type}</Typography>
                );
            },
        },
        {
            name: "Name",
            selector: row => row.short_name,
            sortable: true,
            width: "200px",
            cell: (row) => {
                return (
                    <Typography>{row.short_name}</Typography>
                );
            },
        },
        {
            name: "Value",
            minWidth: "300px",
            selector: row => row.value,
            cell: (row) => {
                let str = [];
                let arr = row;

                let json_key = row.group + "." + row.name;

                //generate value array ,key array and id array
                let value = [];
                let key = [];
                let id = [];

                Object.keys(arr).map(function (ele_key) {
                    if (!["group", "type", "name", "short_name"].includes(ele_key)) {
                        if (ele_key.includes("_id")) {
                            id.push(arr[ele_key]);
                        } else {
                            key.push(ele_key);
                            value.push(arr[ele_key]);
                        }
                    }
                });

                //generate multiple language output
                if (value.length == key.length && key.length == id.length) {
                    Object.keys(value).map(function (ele_key) {
                        str.push(
                            <div key={id[ele_key]} style={{ paddingBottom: "15px" }}>
                                <TextField
                                    fullWidth
                                    label={props.languageNames[key[ele_key].replace("_", "-")]}
                                    defaultValue={value[ele_key]}
                                    multiline
                                    rows={2}
                                    onChange={(event) =>
                                        props.handleTransChange(
                                            event.target.value,
                                            key[ele_key].toUpperCase().replace("_", "-"),
                                            json_key,
                                            id[ele_key]
                                        )
                                    }
                                />
                            </div>
                        );
                    });
                }

                return (
                    <div key={json_key} className="row" style={{ paddingTop: "15px", width: "100%" }}>
                        {str}
                    </div>
                );
            },
        },
        {
            name: "Actions",
            sortable: false,
            selector: row => row.name,
            center: true,
            maxWidth: "100px",
            cell: (row) => {
                let json_key = row.group + "." + row.name;
                return (
                    <>
                        <Tooltip title="Update Translation" onClick={() => props.handleTransSave(json_key)}>
                            <IconButton color="primary">
                                <SaveIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Translation" onClick={() => props.handleTransDelete(row.group, row.name)}>
                            <IconButton color="primary">
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={props.fileteredData}
            customStyles={customStyles}
            noHeader
            pagination
            paginationServer
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50, 100, 150]}
            paginationTotalRows={props.totalRows}
            onChangeRowsPerPage={props.onChangeRowsPerPage}
            onChangePage={props.onChangePage}
            progressPending={props.pending}
        />
    );
}
