import * as ActionTypes from "./actionTypes";

export function authCheck() {
    return {
        type: ActionTypes.storeLoginToken,
    };
}

export function SalesPersonLoginResult(type, payload, username) {
    return {
        type: type,
        payload,
        username,
    };
}

export function UpdateLoginResult(type, payload) {
    return {
        type: type,
        payload,
    };
}

export function SessionExpired(type, payload) {
    return {
        type: type,
        payload,
    };
}

export function userSession(type, payload, enqueueSnackbar) {
    return {
        type: type,
        payload,
        enqueueSnackbar,
    };
}

export function userSessionAuthenticate(type) {
    return {
        type: type,
    };
}

export function isLoading(type, payload) {
    return {
        type: type,
        payload,
    };
}
