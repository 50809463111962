import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
// import UserInput from "../../FormAddInputComponent/edit-user-input";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import { postEditForm, fetchEditInfoList, fetchUserGroupList } from "../../../actions/admin/actionCreators";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";
import { Card, CardHeader, CardContent, Button, Grid } from "@mui/material";
import TextFieldComponent from "../../FormAddInputComponent/TextField";
import SelectOptionComponent from "../../FormAddInputComponent/SelectOption";
import { AppBarSpacer, CardHeaderCss } from "../../../css/Admin-css/listing-css";

export default function UserUpdate(props) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const api = useContext(AxiosContext);
    const navigate = useNavigate();
    const [status, setStatus] = useState("");
    const password = useRef({});
    const [userGroup, setUserGroup] = useState("");
    const [userGroupData, setUserGroupData] = useState([]);

    const {
        watch,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "all",
    });

    const location = useLocation();
	const { id } = location?.state ? location.state : 0;

    const onSubmit = handleSubmit(async (data) => {
        document.getElementById("splash-screen").classList.remove("hidden");
        data["id"] = id;
        const response = await postEditForm({
            api: api,
            data: data,
            method: "POST",
            url: "updateAdmin",
            enqueueSnackbar,
        });

        if (response) {
            document.getElementById("splash-screen").classList.add("hidden");
            if (response.status === 200) {
                enqueueSnackbar(response.msg_title, {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
                navigate("/admin/user-list");
            }
        }
    });

    const statusList = [
        { id: "A", name: "Active" },
        { id: "I", name: "Inactive" },
    ];

    const getUserInfo = async (id) => {
        const response = await fetchEditInfoList({
              api: api,
              url: "getAdminList",
              data: {
                id: id,
              },
              enqueueSnackbar,
              dispatch,
        });

        if (response) {
            if (response.data.data) {
                setValue("name", response.data.data[0].name);
                setValue("nick_name", response.data.data[0].nick_name);
                setValue("email", response.data.data[0].email);
                setValue("status", response.data.data[0].status);
                setValue("user_group_id", response.data.data[0].user_group_id);
                setStatus(response.data.data[0].status);
                setUserGroup(response.data.data[0].user_group_id);
            }
        }
    };

    const getUserGroupList = async () => {
        const response = await fetchUserGroupList({
              api: api,
              url: "getUserGroupList",
              enqueueSnackbar,
              dispatch,
        });

        if (response) {
            if (response.data.data) {
                setUserGroupData(response.data.data);
            }
        }
    };

    const [breadcrumbArr, setBreadcrumbArr] = React.useState([
		{
			text: "Admin",
			link: "/admin/user-list",
		}, 
		{
			text: "Admin Form",
			link: "/admin/user/create",
		}, 
	]);

    const onSelectChangeHandler = (name) => (event) => {
        const value = event.target.value;
        if (name == "status") {
            setStatus(value);
        } else if (name == "user_group_id") {
            setUserGroup(value);
        }
        setValue(name, value);
    };

    password.current = watch("password");

    useEffect(() => {
        getUserGroupList();
        // const id = props.location.state ? props.location.state.id : 0;
        if (id) {
            getUserInfo(id);
        }
    }, []);

    return (
        <>
            <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]} >
				<CollapsedBreadcrumbs />
			</BreadcrumbsContext.Provider>
            {/* <UserInput
                classes={classes}
                errors={errors}
                statusList={statusList}
                control={control}
                onSubmit={onSubmit}
                watch={watch}
                setValue={setValue}
                dbStatus={status}
            /> */}
            <Card>
                <CardHeaderCss title="Update User" />
                <CardContent>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} style={{paddingTop: 0}}>
                                <TextFieldComponent
                                    control={control}
                                    name="name"
                                    marginType="normal"
                                    label="Full Name"
                                    type="text"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "this field is required",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{paddingTop: 0}}>
                                <TextFieldComponent
                                    control={control}
                                    name="nick_name"
                                    marginType="normal"
                                    label="Username"
                                    type="text"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "this field is required",
                                        },
                                    }}
                                    errors={errors}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{paddingTop: 0}}>
                                <TextFieldComponent
                                    control={control}
                                    name="email"
                                    marginType="normal"
                                    label="Email Address"
                                    helperText="We'll never share your email with anyone else"
                                    style={{ margin: 8 }}
                                    type="email"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "this field is required",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{paddingTop: 0}}>
                                <SelectOptionComponent
                                    control={control}
                                    name="status"
                                    placeholder="Status"
                                    label="Status"
                                    option={statusList}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "this field is required",
                                        },
                                    }}
                                    errors={errors}
                                    onChangeHandler={onSelectChangeHandler("status")}
                                    value={status}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="password"
                                    placeholder="Password"
                                    marginType="normal"
                                    label="Password"
                                    type="password"
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "This field is required",
                                        },
                                        minLength: {
                                            value: 8,
                                            message: "Password must have at least 8 characters",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="confirm_password"
                                    placeholder="Confirm Password"
                                    marginType="normal"
                                    label="Confirm Password"
                                    type="password"
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "This field is required",
                                        },
                                        minLength: {
                                            value: 8,
                                            message: "Password must have at least 8 characters",
                                        },
                                        validate: (value) =>
                                            value === password.current ||
                                            "The passwords do not match",
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <SelectOptionComponent
                                    control={control}
                                    name="user_group"
                                    placeholder="User Group"
                                    label="User Group"
                                    option={userGroupData}
                                    onChangeHandler={onSelectChangeHandler("user_group_id")}
                                    value={userGroup}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                        <AppBarSpacer />
                        <div align="center">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={onSubmit}
                                size="large"
                            >
                                update
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </>
    );
}
