import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default function BarChart(props){
    useLayoutEffect(() => {
        let root = am5.Root.new("barchartdiv");
        
        root.setThemes([
          am5themes_Animated.new(root)
        ]);

        let barchart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            // layout: root.verticalLayout
        }));

        // let data = [ 
        //     {
        //         country: "UK",
        //         value: 4420
        //     },{
        //         country: "Germany",
        //         value: 5041
        //     },{
        //         country: "Vietnam",
        //         value: 6083
        //     },{
        //         country: "Singapore",
        //         value: 10173
        //     },{
        //         country: "Malaysia",
        //         value: 12478
        //     },
        // ];

        // // Create axes
        let yRenderer = am5xy.AxisRendererY.new(root, { minGridDistance: 30 });
        // // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        let yAxisBar = barchart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                categoryField: "country",
                renderer: yRenderer
            })
        );
        
        yAxisBar.data.setAll(props.data);

        let xAxisBar = barchart.xAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0,
            min: 0,
            renderer: am5xy.AxisRendererX.new(root, {})
        }));

        let series = barchart.series.push(am5xy.ColumnSeries.new(root, {
            name: "Series 1",
            xAxis: xAxisBar,
            yAxis: yAxisBar,
            valueXField: "value",
            categoryYField: "country"
        }));
        
        let columnTemplate = series.columns.template;

        columnTemplate.setAll({
            cursorOverStyle: "pointer",
            cornerRadiusBR: 10,
            cornerRadiusTR: 10,
            tooltipText: "{categoryY}: {valueX}",
        });
        columnTemplate.adapters.add("fill", (fill, target) => {
            return barchart.get("colors").getIndex(series.columns.indexOf(target));
        });

        columnTemplate.adapters.add("stroke", (stroke, target) => {
            return barchart.get("colors").getIndex(series.columns.indexOf(target));
        });

        series.data.setAll(props.data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear(1000);
        barchart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, []);

    return (
        <div id="barchartdiv" style={{width: '100%', height: '300px'}}></div>
    );
}