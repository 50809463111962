import React, { useEffect, useContext, useState } from "react";
import { Grid, Paper, useTheme, Typography, Chip, Button, Avatar, Tabs, Tab, ListItem, ListItemText, Box } from "@mui/material";
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";
import { styled } from '@mui/material/styles';
import RadarChart  from './radarChart';
import BarChart  from './barChart';
import LineChart  from './lineChart';
import Map from "./map";
import { getDashboardCustomerData, getDashboardSalesPersonData, getDashboardCampaignData, getDashboardCountryData, getDashboardSalesData, getDashboardInstallmentData } from "../../../actions/admin/actionCreators";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { Link as RouterLink } from "react-router-dom";
import {TabPanel, TabContext} from '@mui/lab';
import { useDispatch, useSelector } from "react-redux";
import '../../../css/Admin-css/dashboard.css';

export default function Dashboard() {
  const api = useContext(AxiosContext);
    const [breadcrumbArr, setBreadcrumbArr] = useState([{
        text: "Dashboard",
        link: "/dashboard",
    }]);
    const [tabValue, setTabValue] = useState('all');
    const [spTabValue, setSPTabValue] = useState('year');
    const [sTabValue, setSTabValue] = useState('sales_quarter');
    const [iTabValue, setITabValue] = useState('all');
    const [state, setState] = useState({
      customer: [],
      salesPerson: [],
      campaign: [],
      country: [],
      sales: [],
      installment: [],
    });
    const handleChange = (event, newValue) => {
      setTabValue(newValue);
    };

    const sphandleChange = (event, newValue) => {
      setSPTabValue(newValue);
    };

    const shandleChange = (event, newValue) => {
      setSTabValue(newValue);
    };

    const ihandleChange = (event, newValue) => {
      setITabValue(newValue);
    };
    const avatarColor = [ '#FFC107', '#20C997', '#FF5A5A'];
    const listColor = ['#5AB9FF', '#FFC107', '#00D9D9', '#FF5A5A', '#7239EA'];

    const theme = useTheme();

    const Item = styled(Paper)(({ theme }) => ({
      padding: theme.spacing(5),
    }));

    const StyledTabs = styled(Tabs)(() => ({
      root: {
        "& div.MuiTabs-scroller": {
          "& .MuiTabs-flexContainer": {
            justifyContent: 'end'
          }
        }
      }
    }));

    const getCustomerData = async () => {
      const response = await getDashboardCustomerData({
        api: api,
        data: [],
      });
  
      if (response) {
        if (response.status == 200) {
          setState((prevState) => {
            return {
              ...prevState,
              customer: response.data,
            };
          });
        }
      }
    }

    const getSalesPersonData = async () => {
      const response = await getDashboardSalesPersonData({
        api: api,
        data: [],
      });
  
      if (response) {
        if (response.status == 200) {
          setState((prevState) => {
            return {
              ...prevState,
              salesPerson: response.data,
            };
          });
        }
      }
    }

    const getCampaignData = async () => {
      const response = await getDashboardCampaignData({
        api: api,
        data: [],
      });
  
      if (response) {
        if (response.status == 200) {
          setState((prevState) => {
            return {
              ...prevState,
              campaign: response.data,
            };
          });
        }
      }
    }

    const getCountryData = async () => {
      const response = await getDashboardCountryData({
        api: api,
        data: [],
      });
  
      if (response) {
        if (response.status == 200) {
          setState((prevState) => {
            return {
              ...prevState,
              country: response.data,
            };
          });
        }
      }
    }

    const getSalesData = async () => {
      const response = await getDashboardSalesData({
        api: api,
        data: [],
      });

      if (response) {
        if (response.status == 200) {
          setState((prevState) => {
            return {
              ...prevState,
              sales: response.data,
            };
          });
        }
      }
    }

    const getInstallmentData = async () => {
      const response = await getDashboardInstallmentData({
        api: api,
        data: [],
      });
  
      if (response) {
        if (response.status == 200) {
          setState((prevState) => {
            return {
              ...prevState,
              installment: response.data,
            };
          });
        }
      }
    }

    useEffect(() => {
      getCustomerData();
      getSalesPersonData();
      getCampaignData();
      getCountryData();
      getSalesData();
      getInstallmentData();
    }, []);

    return (
        <>
            <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]} >
                <CollapsedBreadcrumbs />
            </BreadcrumbsContext.Provider>
            <Grid container spacing={{ xs: 1, sm: 3, md: 5 }}>
              <Grid item md={6} xs={12}>
                <Item>
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography variant="h6" style={{marginBottom: '10px'}}>Total Customer</Typography>
                      <Typography variant="h4" style={{marginBottom: '20px'}}> { Object.keys(state.customer).length > 0 ? state.customer.total.count : 0 } { (Object.keys(state.customer).length > 0) ? 
                                      Math.sign(state.customer.total.percentage) == 1 ?
                                        (<Chip icon={ <ArrowUpward /> } label={state.customer.total.percentage +' %'} color="green"/>) : (<Chip icon={ <ArrowDownward /> } label={state.customer.total.percentage +' %'} color="error"/>) 
                                      : ('')}
                      </Typography>
                      <Typography variant="p" style={{color: '#A1A5B7'}}>New Customer</Typography>
                      <Typography variant="h6" style={{marginBottom: '20px', color: '#20C997'}}>{ Object.keys(state.customer).length > 0 ? state.customer.C.count : 0 }</Typography>
                      <Typography variant="p" style={{color: '#A1A5B7'}}>Assigned Potetial</Typography>
                      <Typography variant="h6" style={{marginBottom: '20px', color: '#5AB9FF'}}>{ Object.keys(state.customer).length > 0 ? state.customer.W.count : 0 }</Typography>
                      <Typography variant="p" style={{color: '#A1A5B7'}}>Attractive Super Fans</Typography>
                      <Typography variant="h6" style={{marginBottom: '20px', color: '#FF5A5A'}}>{ Object.keys(state.customer).length > 0 ? state.customer.H.count : 0 }</Typography>
                      <Typography variant="p" style={{color: '#A1A5B7'}}>Blacklisted</Typography>
                      <Typography variant="h6" style={{marginBottom: '20px', color: '#1A1A27'}}>{ Object.keys(state.customer).length > 0 ? state.customer.B.count : 0 }</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <RadarChart chartData = { state.customer }/>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
              <Grid item md={6} xs={12}>
                <Item style={{height: '100%'}}>
                <TabContext value={spTabValue}>
                  <Grid container style={{marginBottom: '20px'}}>
                    <Grid item md={6}>
                      <Typography variant="h6">Popular Sales Person</Typography>
                      <Typography variant="p" style={{color: '#A1A5B7'}}>Total { Object.keys(state.salesPerson).length > 0 ? state.salesPerson.total_count : 0} sales person</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Tabs value={spTabValue} onChange={sphandleChange} TabIndicatorProps={{ style: { background: "#E4E6EF" } }}>
                        <Tab label="Year" value="year" style={{fontWeight: '600'}} />
                        <Tab label="Month" value="month" style={{fontWeight: '600'}} />
                        <Tab label="Week" value="week" style={{fontWeight: '600'}} />
                      </Tabs>
                    </Grid>
                  </Grid>
                  { Object.keys(state.salesPerson).length > 0 ? 
                    ( <><TabPanel value="year">
                      { (state.salesPerson.yearly_top_sales_person).map((sp, index) => {
                        return (
                          <Grid container alignItems="center" key={index} style={{paddingBottom: '15px', paddingTop: '15px', borderBottom: '1px dashed #d9dce9' }}>
                              {
                                Object.keys(state.salesPerson).length > 0 ? (
                                  <>
                                  <Grid item md={1} xs={2}>
                                    <Avatar sx={{ bgcolor: avatarColor[index] }}>{ (sp.name).charAt(0) }</Avatar>
                                  </Grid>
                                  <Grid item md={4} xs={4}>
                                    <Typography variant="h6" style={{fontWeight: '600', paddingLeft: '10px'}}>{ (sp.name) }</Typography>
                                    <Typography variant="p" style={{color: '#A1A5B7', paddingLeft: '10px'}}>{ (sp.code) }</Typography>
                                  </Grid>
                                  <Grid item md={4} xs={3} style={{textAlign: 'end', alignSelf: 'center'}}>
                                    <Typography variant="h6" style={{fontWeight: '600'}} >{ (sp.customer_count) }</Typography>
                                    <Typography variant="p" style={{color: '#A1A5B7'}}>Assigned Customers</Typography>
                                  </Grid>
                                  <Grid item md={3} xs={3} style={{textAlign: 'end', alignSelf: 'center'}}>
                                    <Typography variant="h6" style={{fontWeight: '600'}} >${ (sp.total_sales) }</Typography>
                                    <Typography variant="p" style={{color: '#A1A5B7'}}>Earning</Typography>
                                  </Grid>
                                  </>
                                ) : ('')
                              }
                          </Grid>
                        )
                      }) }
                   </TabPanel>
                   <TabPanel value="month">
                      { (state.salesPerson.monthly_top_sales_person).map((sp, index) => {
                        return (
                          <Grid container alignItems="center" key={index} style={{paddingBottom: '15px', paddingTop: '15px', borderBottom: '1px dashed #d9dce9' }}>
                              {
                                Object.keys(state.salesPerson).length > 0 ? (
                                  <>
                                  <Grid item md={1} xs={2}>
                                    <Avatar sx={{ bgcolor: avatarColor[index] }}>{ (sp.name).charAt(0) }</Avatar>
                                  </Grid>
                                  <Grid item md={4} xs={4}>
                                    <Typography variant="h6" style={{fontWeight: '600', paddingLeft: '10px'}}>{ (sp.name) }</Typography>
                                    <Typography variant="p" style={{color: '#A1A5B7', paddingLeft: '10px'}}>{ (sp.code) }</Typography>
                                  </Grid>
                                  <Grid item md={4} xs={3} style={{textAlign: 'end', alignSelf: 'center'}}>
                                    <Typography variant="h6" style={{fontWeight: '600'}} >{ (sp.customer_count) }</Typography>
                                    <Typography variant="p" style={{color: '#A1A5B7'}}>Assigned Customers</Typography>
                                  </Grid>
                                  <Grid item md={3} xs={3} style={{textAlign: 'end', alignSelf: 'center'}}>
                                    <Typography variant="h6" style={{fontWeight: '600'}} >${ (sp.total_sales) }</Typography>
                                    <Typography variant="p" style={{color: '#A1A5B7'}}>Earning</Typography>
                                  </Grid>
                                  </>
                                ) : ('')
                              }
                          </Grid>
                        )
                      }) }
                   </TabPanel>
                   <TabPanel value="week">
                      { (state.salesPerson.weekly_top_sales_person).map((sp, index) => {
                        return (
                          <Grid container alignItems="center" key={index} style={{paddingBottom: '15px', paddingTop: '15px', borderBottom: '1px dashed #d9dce9' }}>
                              {
                                Object.keys(state.salesPerson).length > 0 ? (
                                  <>
                                  <Grid item md={1} xs={2}>
                                    <Avatar sx={{ bgcolor: avatarColor[index] }}>{ (sp.name).charAt(0) }</Avatar>
                                  </Grid>
                                  <Grid item md={4} xs={4}>
                                    <Typography variant="h6" style={{fontWeight: '600', paddingLeft: '10px'}}>{ (sp.name) }</Typography>
                                    <Typography variant="p" style={{color: '#A1A5B7', paddingLeft: '10px'}}>{ (sp.code) }</Typography>
                                  </Grid>
                                  <Grid item md={4} xs={3} style={{textAlign: 'end', alignSelf: 'center'}}>
                                    <Typography variant="h6" style={{fontWeight: '600'}} >{ (sp.customer_count) }</Typography>
                                    <Typography variant="p" style={{color: '#A1A5B7'}}>Assigned Customers</Typography>
                                  </Grid>
                                  <Grid item md={3} xs={3} style={{textAlign: 'end', alignSelf: 'center'}}>
                                    <Typography variant="h6" style={{fontWeight: '600'}} >${ (sp.total_sales) }</Typography>
                                    <Typography variant="p" style={{color: '#A1A5B7'}}>Earning</Typography>
                                  </Grid>
                                  </>
                                ) : ('')
                              }
                          </Grid>
                        )
                      }) }
                   </TabPanel>
                   </>)
                   : ('')}
                   </TabContext>
                   <Grid item style={{textAlign: 'end', alignSelf: 'center'}}>
                    <RouterLink to='/admin/total-sales-list' style={{textDecoration: 'none'}}>
                        <Button variant="contained" color="primary">View all</Button>
                    </RouterLink>
                  </Grid>
                </Item>
              </Grid>
            </Grid> 
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 5 }} style={{marginTop: '10px'}}>
              <Grid item xs={12}>
                <Item style={{height: '100%'}}>
                  <Grid container style={{marginBottom: '20px'}}>
                    <Grid item md={6}>
                      <Typography variant="h6">Total Sales</Typography>
                      <Typography variant="p" style={{color: '#A1A5B7'}}>Sales from all Campaigns</Typography>
                    </Grid>
                    <Grid item md={6} style={{textAlign: 'end', alignSelf: 'center'}}>
                      <RouterLink to='/admin/total-sales-list' style={{textDecoration: 'none'}}>
                        <Button variant="contained" color="primary" >View all</Button>
                      </RouterLink>
                    </Grid>
                  </Grid>
                  <Grid container style={{marginBottom: '20px'}}>
                    <Grid item xs={12} md={8}>
                      <TabContext value={sTabValue}>
                      <Grid container>
                        <Grid item md={6}>
                        <div style={{display: 'flex'}}>
                          <Typography variant="h5" style={{ color: '#A1A5B7', paddingRight: '1rem'}}>$ </Typography>
                          <Typography variant="h4" style={{marginBottom: '20px'}}>{ Object.keys(state.sales).length > 0 ? Intl.NumberFormat('en-US').format(state.sales.total_sales_amount) : 0}
                          </Typography>
                          </div>
                        </Grid>
                        <Grid item md={6} style={{textAlign: 'end'}}>
                          <StyledTabs value={sTabValue} onChange={shandleChange} TabIndicatorProps={{ style: { display: 'none' } }}>
                            <Tab label="Quarter" value="sales_quarter" style={{fontWeight: '600'}} />
                            <Tab label="Month" value="sales_month" style={{fontWeight: '600'}} />
                            <Tab label="Week" value="sales_week" style={{fontWeight: '600'}} />
                          </StyledTabs>
                        </Grid>
                      </Grid>
                      <Grid container>
                          <Grid item xs={12}>
                            <TabPanel value="sales_quarter">
                              {
                                Object.keys(state.sales).length > 0 ? (
                                  // <LineChart type="year" data={state.sales.yearly_sales_list}/>
                                  <LineChart type="month" data={state.sales.quarterly_sales_list}/>
                                ) : ('')
                              }
                              </TabPanel>
                              <TabPanel value="sales_month">
                              {
                                Object.keys(state.sales).length > 0 ? (
                                  <LineChart type="month" data={state.sales.monthly_sales_list}/>
                                ) : ('')
                              }
                              </TabPanel>
                              <TabPanel value="sales_week">
                              {
                                Object.keys(state.sales).length > 0 ? (
                                  <LineChart type="week" data={state.sales.weekly_sales_list}/>
                                ) : ('')
                              }
                              </TabPanel>
                          </Grid>
                      </Grid>
                      </TabContext>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid container direction="column" justifyContent="space-between" spacing={2} style={{background: '#FAFAFA', padding: '2rem', minHeight: '100%'}}>
                        <Grid item xs={4}>
                          <div style={{display: 'flex'}}>
                          <Typography variant="h5" style={{ color: '#A1A5B7', paddingRight: '1rem'}}>$ </Typography>
                          <Typography variant="h4">{ Object.keys(state.sales).length > 0 ? Intl.NumberFormat('en-US').format(state.sales.daily_sales_amount) : 0} { 
                                (Object.keys(state.sales).length > 0) ? 
                                  Math.sign(state.sales.daily_sales_percentage) == 1 ?
                                    (<Chip icon={ <ArrowUpward /> } label={state.sales.daily_sales_percentage +' %'} color="success"/>) : (<Chip icon={ <ArrowDownward /> } label={state.sales.daily_sales_percentage +' %'} color="error"/>) 
                                  : ('')}
                            </Typography>
                          </div>
                          <Typography variant="p" style={{marginBottom: '20px', color: '#A1A5B7'}}>Today Sales</Typography>
                        </Grid>
                        <Grid item xs={4}>
                        <div style={{display: 'flex'}}>
                          <Typography variant="h5" style={{ color: '#A1A5B7', paddingRight: '1rem'}}>$ </Typography>
                          <Typography variant="h4">{ Object.keys(state.sales).length > 0 ? Intl.NumberFormat('en-US').format(state.sales.monthly_sales_amount) : 0} { 
                                (Object.keys(state.sales).length > 0) ? 
                                  Math.sign(state.sales.monthly_sales_percentage) == 1 ?
                                    (<Chip icon={ <ArrowUpward /> } label={state.sales.monthly_sales_percentage +' %'} color="success"/>) : (<Chip icon={ <ArrowDownward /> } label={state.sales.monthly_sales_percentage +' %'} color="error"/>) 
                                  : ('')}
                            </Typography>
                          </div>
                          <Typography variant="p" style={{marginBottom: '20px', color: '#A1A5B7'}}>Monthly Sales</Typography>
                        </Grid>
                        <Grid item xs={4}>
                        <div style={{display: 'flex'}}>
                          <Typography variant="h5" style={{ color: '#A1A5B7', paddingRight: '1rem'}}>$ </Typography>
                          {/* <Typography variant="h4">{ Object.keys(state.sales).length > 0 ? Intl.NumberFormat('en-US').format(state.sales.yearly_sales_amount) : 0} { 
                                (Object.keys(state.sales).length > 0) ? 
                                  Math.sign(state.sales.yearly_sales_percentage) == 1 ?
                                    (<Chip icon={ <ArrowUpward /> } label={state.sales.yearly_sales_percentage +' %'} color="success"/>) : (<Chip icon={ <ArrowDownward /> } label={state.sales.yearly_sales_percentage +' %'} color="error"/>) 
                                  : ('')}
                            </Typography>
                          </div>
                          <Typography variant="p" style={{marginBottom: '20px', color: '#A1A5B7'}}>Yearly Sales</Typography> */}
                          <Typography variant="h4">{ Object.keys(state.sales).length > 0 ? Intl.NumberFormat('en-US').format(state.sales.quarterly_sales_amount) : 0} { 
                                (Object.keys(state.sales).length > 0) ? 
                                  Math.sign(state.sales.quarterly_sales_percentage) == 1 ?
                                    (<Chip icon={ <ArrowUpward /> } label={state.sales.quarterly_sales_percentage +' %'} color="success"/>) : (<Chip icon={ <ArrowDownward /> } label={state.sales.quarterly_sales_percentage +' %'} color="error"/>) 
                                  : ('')}
                            </Typography>
                          </div>
                          <Typography variant="p" style={{marginBottom: '20px', color: '#A1A5B7'}}>Quarterly Sales</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
            </Grid>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 5 }} style={{marginTop: '10px'}}>
              <Grid item xs={12}>
                <TabContext value={tabValue}>
                  <Item>
                    <Grid container>
                      <Grid item xs={10}>
                      {
                        Object.keys(state.campaign).length > 0 ? (
                            <Tabs
                              onChange={handleChange}
                              value={tabValue}
                              TabIndicatorProps={{ style: { display: "none" } }}
                            >
                              <Tab label={'All Campaigns ('+state.campaign.count.all+ ')' } value="all" style={{fontWeight: '600', fontSize: '16px'}} />
                              <Tab label={"Active (" + state.campaign.count.active + ')'} value="active" style={{fontWeight: '600', fontSize: '16px'}} />
                              <Tab label={"Inactive (" + state.campaign.count.inactive + ')'} value="inactive"  style={{fontWeight: '600', fontSize: '16px'}} />
                        </Tabs>
                        ) : ('')
                      }
                      </Grid>
                      <Grid item xs={2} textAlign="right">
                        <RouterLink to='/admin/product/create' style={{textDecoration: 'none'}}>
                          <Button variant="contained" color="primary">Create campaign</Button>
                        </RouterLink>
                      </Grid>
                    </Grid>
                    <TabPanel value="all">
                    { Object.keys(state.campaign).length > 0 ? (
                        (state.campaign.all).map((prd, ind) => {
                          return(
                            <Grid key={ind} container rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 5 }} style={{marginTop: '10px', marginLeft: '0px', width: '100%', borderLeft: '5px solid '+listColor[ind]}}>
                                <Grid item xs={6}>
                                <ListItemText
                                    primary={prd.name}
                                    secondary='Campaign'
                                  />
                                </Grid>
                                <Grid item xs={3} md={2}>
                                  <ListItemText
                                    primary={prd.unit_price}
                                    secondary='Price'
                                    style={{textAlign: 'center'}}
                                  />
                                </Grid>
                                <Grid item xs={3} md={2}>
                                  <ListItemText
                                    primary={
                                      prd.status == 'A' ? 
                                        (<Chip label="Active" color="green"/>) :
                                        (<Chip label="Inactive" color="grey"/>)
                                    }
                                    style={{textAlign: 'center'}}
                                  />
                                </Grid>
                                <Grid item xs={3} md={2}>
                                <ListItemText
                                  primary={prd.code}
                                  secondary='Code'
                                  style={{textAlign: 'end'}}
                                />
                                </Grid>
                            </Grid>
                          )
                        })
                    ) : ('')}
                    </TabPanel>
                    <TabPanel value="active">
                    { Object.keys(state.campaign).length > 0 ? (
                        (state.campaign.active).map((prd, ind) => {
                          return(
                            <Grid key={ind} container rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 5 }} style={{marginTop: '10px', marginLeft: '0px', width: '100%', borderLeft: '5px solid '+listColor[ind]}}>
                                <Grid item md={6}>
                                <ListItemText
                                    primary={prd.name}
                                    secondary='Campaign'
                                  />
                                </Grid>
                                <Grid item md={2}>
                                  <ListItemText
                                    primary={prd.unit_price}
                                    secondary='Price'
                                    style={{textAlign: 'center'}}
                                  />
                                </Grid>
                                <Grid item md={2}>
                                  <ListItemText
                                    primary={
                                      prd.status == 'A' ? 
                                        (<Chip label="Active" color="green"/>) :
                                        (<Chip label="Inactive" color="grey"/>)
                                    }
                                    style={{textAlign: 'center'}}
                                  />
                                </Grid>
                                <Grid item md={2}>
                                <ListItemText
                                  primary={prd.code}
                                  secondary='Code'
                                  style={{textAlign: 'end'}}
                                />
                                </Grid>
                            </Grid>
                          )
                        })
                      ) : ('')}
                      </TabPanel>
                      <TabPanel value="inactive">
                      { Object.keys(state.campaign).length > 0 ? (
                        (state.campaign.inactive).map((prd, ind) => {
                          return(
                            <Grid key={ind} container rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 5 }} style={{marginTop: '10px', marginLeft: '0px', width: '100%', borderLeft: '5px solid '+listColor[ind]}}>
                                <Grid item md={6}>
                                <ListItemText
                                    primary={prd.name}
                                    secondary='Campaign'
                                  />
                                </Grid>
                                <Grid item md={2}>
                                  <ListItemText
                                    primary={prd.unit_price}
                                    secondary='Price'
                                    style={{textAlign: 'center'}}
                                  />
                                </Grid>
                                <Grid item md={2}>
                                  <ListItemText
                                    primary={
                                      prd.status == 'A' ? 
                                        (<Chip label="Active" color="green"/>) :
                                        (<Chip label="Inactive" color="grey"/>)
                                    }
                                    style={{textAlign: 'center'}}
                                  />
                                </Grid>
                                <Grid item md={2}>
                                <ListItemText
                                  primary={prd.code}
                                  secondary='Code'
                                  style={{textAlign: 'end'}}
                                />
                                </Grid>
                            </Grid>
                          )
                        })
                    ) : ('')}
                    </TabPanel>
                  </Item>
                </TabContext>
              </Grid>
            </Grid>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 5 }} style={{marginTop: '10px'}}>
              <Grid item xs={12}>
                <TabContext value={iTabValue}>
                  <Item>
                    <Grid container>
                      <Grid item xs={9}>
                      {
                        Object.keys(state.installment).length > 0 ? (
                            <Tabs
                              onChange={ihandleChange}
                              value={iTabValue}
                              TabIndicatorProps={{ style: { display: "none" } }}
                            >
                              <Tab label={'All Installments ('+state.installment.count.all+ ')' } value="all" style={{fontWeight: '600', fontSize: '16px'}} />
                              <Tab label={"Completed (" + state.installment.count.completed + ')'} value="completed" style={{fontWeight: '600', fontSize: '16px'}} />
                              <Tab label={"Pending (" + state.installment.count.pending + ')'} value="pending"  style={{fontWeight: '600', fontSize: '16px'}} />
                        </Tabs>
                        ) : ('')
                      }
                      </Grid>
                      <Grid item xs={3} textAlign="right">
                        <RouterLink to='/admin/installment-list' style={{textDecoration: 'none'}}>
                          <Button variant="contained" color="primary">View All</Button>
                        </RouterLink>
                      </Grid>
                    </Grid>
                    <TabPanel value="all">
                    { Object.keys(state.installment).length > 0 ? (
                        (state.installment.all).map((inst, ind) => {
                          return(
                            <Grid key={ind} container rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 5 }} style={{marginTop: '10px', marginLeft: '0px', width: '100%', borderLeft: '5px solid '+listColor[ind]}}>
                                <Grid item md={2}>
                                  <ListItemText
                                    primary={inst.full_name}
                                    secondary='Name'
                                  />
                                </Grid>
                                <Grid item xs={3} md={2}>
                                  <ListItemText
                                    primary={inst.doc_date}
                                    secondary='Installment Date'
                                    style={{textAlign: 'center'}}
                                  />
                                </Grid>
                                <Grid item md={4}>
                                  <ListItemText
                                    primary={inst.product_name}
                                    secondary='Product Name'
                                  />
                                </Grid>
                                <Grid item md={1}>
                                  <ListItemText
                                    primary={inst.progress_period}
                                    secondary='Progress'
                                  />
                                </Grid>
                                <Grid item md={2}>
                                  <ListItemText
                                    primary={
                                      inst.status == 'COM' ? 
                                        (<Chip label="Completed" color="green"/>) :
                                        (<Chip label="Pending" color="grey"/>)
                                    }
                                    style={{textAlign: 'center'}}
                                  />
                                </Grid>
                                <Grid item xs={3} md={1}>
                                  <ListItemText
                                    primary={inst.total_amount}
                                    secondary='Price'
                                    style={{textAlign: 'end'}}
                                  />
                                </Grid>
                            </Grid>
                          )
                        })
                    ) : ('')}
                    </TabPanel>
                    <TabPanel value="completed">
                    { Object.keys(state.installment).length > 0 ? (
                        (state.installment.completed).map((inst, ind) => {
                          return(
                            <Grid key={ind} container rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 5 }} style={{marginTop: '10px', marginLeft: '0px', width: '100%', borderLeft: '5px solid '+listColor[ind]}}>
                                <Grid item md={2}>
                                  <ListItemText
                                    primary={inst.full_name}
                                    secondary='Name'
                                  />
                                </Grid>
                                <Grid item xs={3} md={2}>
                                  <ListItemText
                                    primary={inst.doc_date}
                                    secondary='Installment Date'
                                    style={{textAlign: 'center'}}
                                  />
                                </Grid>
                                <Grid item md={4}>
                                  <ListItemText
                                    primary={inst.product_name}
                                    secondary='Product Name'
                                  />
                                </Grid>
                                <Grid item md={1}>
                                  <ListItemText
                                    primary={inst.progress_period}
                                    secondary='Progress'
                                  />
                                </Grid>
                                <Grid item md={2}>
                                  <ListItemText
                                    primary={
                                      inst.status == 'COM' ? 
                                        (<Chip label="Completed" color="green"/>) :
                                        (<Chip label="Pending" color="grey"/>)
                                    }
                                    style={{textAlign: 'center'}}
                                  />
                                </Grid>
                                <Grid item md={1}>
                                  <ListItemText
                                    primary={inst.total_amount}
                                    secondary='Price'
                                    style={{textAlign: 'end'}}
                                  />
                                </Grid>
                            </Grid>
                          )
                        })
                      ) : ('')}
                      </TabPanel>
                      <TabPanel value="pending">
                      { Object.keys(state.installment).length > 0 ? (
                        (state.installment.pending).map((inst, ind) => {
                          return(
                            <Grid key={ind} container rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 5 }} style={{marginTop: '10px', marginLeft: '0px', width: '100%', borderLeft: '5px solid '+listColor[ind]}}>
                                <Grid item md={2}>
                                  <ListItemText
                                    primary={inst.full_name}
                                    secondary='Name'
                                  />
                                </Grid>
                                <Grid item xs={3} md={2}>
                                  <ListItemText
                                    primary={inst.doc_date}
                                    secondary='Installment Date'
                                    style={{textAlign: 'center'}}
                                  />
                                </Grid>
                                <Grid item md={4}>
                                  <ListItemText
                                    primary={inst.product_name}
                                    secondary='Product Name'
                                  />
                                </Grid>
                                <Grid item md={1}>
                                  <ListItemText
                                    primary={inst.progress_period}
                                    secondary='Progress'
                                  />
                                </Grid>
                                <Grid item md={2}>
                                  <ListItemText
                                    primary={
                                      inst.status == 'COM' ? 
                                        (<Chip label="Completed" color="green"/>) :
                                        (<Chip label="Pending" color="grey"/>)
                                    }
                                    style={{textAlign: 'center'}}
                                  />
                                </Grid>
                                <Grid item md={1}>
                                  <ListItemText
                                    primary={inst.total_amount}
                                    secondary='Price'
                                    style={{textAlign: 'end'}}
                                  />
                                </Grid>
                            </Grid>
                          )
                        })
                    ) : ('')}
                    </TabPanel>
                  </Item>
                </TabContext>
              </Grid>
            </Grid>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 5 }} style={{marginTop: '10px'}}>
              <Grid item xs={12}>
                <Item>
                  <Grid container>
                    <Grid item md={5} xs={12} style={{marginRight: 'auto'}}>
                      <Typography variant="h6">Top 5 Country</Typography>
                      <Typography variant="p" style={{color: '#A1A5B7'}}>20 Countries Organic Sessions</Typography>
                      <Typography variant="h4" style={{marginBottom: '20px'}}>{ Object.keys(state.customer).length > 0 ? state.customer.total.count : 0 }</Typography>
                      {
                        Object.keys(state.country).length > 0 ? (
                          <BarChart data={state.country.topCountry}/>
                        ) : ('')
                      }
                      
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {
                        Object.keys(state.country).length > 0 ? (
                          <Map data={state.country.topCountry}/>
                        ) : ('')
                      }
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
            </Grid>
        </>
    );
}
