import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";

export const Main = styled("div")(({ theme }) => ({
	backgroundColor: "#f0ebe2",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	height: "100vh",
}));

export const ContainerEle = styled(Container)({
	height: "500px",
	backgroundColor: "white",
	borderRadius: "2px",
	zIndex: "1",
	maxWidth: "90%!important",
	width: "444px!important",
	boxShadow: "0px 6px 10px #00000014",
});

export const BgLogo = styled("img")({
	position: "absolute",
	width: "100%",
	bottom: "0",
	zIndex: "0",
});

export const Paper = styled("div")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
});

export const Logo = styled("img")({
	marginBottom: "2rem",
	marginTop: "1rem",
});

export const FormCss = styled("form")(({ theme }) => ({
	width: "100%", // Fix IE 11 issue.
	padding: theme.spacing(4),
	paddingBottom: theme.spacing(8),
}));

export const LoginSubmitBtn = styled(Button)(({ theme }) => ({
	marginTop: theme.spacing(6),
}));

export const ListItemGap = styled(ListItem)(({ theme }) => ({
	minWidth: "30px",
}));

// export const SalesListStyles = makeStyles((theme) => ({
// 	root: {
// 		display: "flex",
// 	},
// 	appBarSpacer: theme.mixins.toolbar,

// 	container: {
// 		paddingTop: theme.spacing(4),
// 		paddingBottom: theme.spacing(4),
// 	},
// 	paper: {
// 		padding: theme.spacing(2),
// 		display: "flex",
// 		overflow: "auto",
// 		flexDirection: "column",
// 	},
// 	content: {
// 		flexGrow: 1,
// 		// height: "100vh",
// 		overflow: "auto",
// 	},
// 	inputContent: {
// 		marginBottom: "2rem",
// 	},
// 	buttonContent: {
// 		padding: "25px",
// 		borderTop: "1px solid #ebedf2",
// 		borderBottomLeftRadius: "4px",
// 		borderBottomRightRadius: "4px",
// 	},
// 	borderBottom: {
// 		borderBottom: "1px solid #ebedf2",
// 	},
// 	appBarSpacerV2: {
// 		paddingTop: "15px",
// 	},
// 	textField: {
// 		marginLeft: theme.spacing(1),
// 		paddingRight: theme.spacing(2),
// 		marginBottom: theme.spacing(2),
// 	},
// 	button: {
// 		paddingTop: theme.spacing(1, "!important"),
// 		paddingBottom: theme.spacing(1, "!important"),
// 		marginLeft: theme.spacing(2, "!important"),
// 		width: "120px",
// 		marginBottom: theme.spacing(1, "!important"),
// 		// [theme.breakpoints.down('sm')]: {
// 		//   marginBottom: '8px',
// 		// },
// 	},
// 	mb2: {
// 		marginBottom: theme.spacing(2),
// 	},
// 	fontSmall: {
// 		fontSize: "12px",
// 	},
// 	selectField: {
// 		marginTop: theme.spacing(2),
// 	},
// 	dateRangePicker: {
// 		marginLeft: theme.spacing(1),
// 		marginRight: theme.spacing(1),
// 		marginBottom: theme.spacing(2),
// 	},
// 	error_msg: {
// 		marginTop: "5px",
// 		// marginLeft: "10px",
// 		color: "#fd397a",
// 		fontSize: "10px",
// 	},
// 	formControl: {
// 		// margin: theme.spacing(1),
// 		minWidth: 120,
// 	},
// 	btn_theme: {
// 		backgroundColor: "#AD8E64!important",
// 		borderColor: "#AD8E64!important",
// 	},
// 	list_item_gap: {
// 		minWidth: "30px",
// 	},
// 	theme_color: {
// 		color: "#AD8E64!important",
// 	},
// 	theme_bgcolor: {
// 		backgroundColor: "#AD8E64",
// 	},
// 	white: {
// 		color: "#FFFFFF!important",
// 	},
// }));
