import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './app/assets/store/store';
import * as action from './actions/admin/action';
import * as actionSalesPerson from './actions/salesPerson/action';
import AxiosComponent from './app/assets/axios/admin/AxiosComponent';
import SalesPersonAxiosComponent from './app/assets/axios/salesPerson/AxiosComponent';
import MasterLayout from './layouts/admin/master';
import SalesPersonMasterLayout from "./layouts/salesPerson/master";
import { SnackbarProvider } from 'notistack';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import HttpApi from 'i18next-http-backend';


i18next.use(HttpApi).init({
    interpolation: { escapeValue: false },  // React already does escaping
    react: {
        useSuspense: false
    },
    saveMissing: true,
    lng: localStorage.getItem("language") ?? 'en',   // language to use
    backend: {
        crossDomain: true,
        loadPath: `${process.env.REACT_APP_SERVER_URL}getTranslation?code={{lng}}`,
        addPath: `${process.env.REACT_APP_SERVER_URL}addTranslation`,
    },
});

const pathname = window.location.pathname;
let master = <MasterLayout />;
let FinalAxiosComponent = AxiosComponent;

if (pathname.startsWith("/salesperson")) {
    master = <SalesPersonMasterLayout />;
    FinalAxiosComponent = SalesPersonAxiosComponent;
}

store.dispatch(action.authCheck());
store.dispatch(actionSalesPerson.authCheck());

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>
        <React.Fragment>
            <SnackbarProvider maxSnack={3}>
                <Provider store={store}>
                    <FinalAxiosComponent>
                        <BrowserRouter>
                            <I18nextProvider i18n={i18next}>
                                {master}
                            </I18nextProvider>
                        </BrowserRouter>
                    </FinalAxiosComponent>
                </Provider>
            </SnackbarProvider>
        </React.Fragment>

    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
