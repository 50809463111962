import React, { useContext, useEffect, useState } from "react";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useForm } from "react-hook-form";
import { postCreateForm, getSysGeneralData } from "../../../actions/admin/actionCreators";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";
import { AppBarSpacer, CardHeaderCss } from "../../../css/Admin-css/listing-css";
import { Card, CardHeader, CardContent, Grid, Button, FormControlLabel, Checkbox } from "@mui/material";
import TextFieldComponent from "../../FormAddInputComponent/TextField";
import SelectOptionComponent from "../../FormAddInputComponent/SelectOption";

export default function ProductCreate() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "all",
    });

    const api = useContext(AxiosContext);
    const navigate = useNavigate();
    const [breadcrumbArr, setBreadcrumbArr] = React.useState([
		{
			text: "Campaign / Product",
			link: "/admin/product-list",
		}, 
		{
			text: "Campaign / Product Form",
			link: "/admin/product/create",
		}, 
	]);
    const [status, setStatus] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [paymentTypeData, setPaymentTypeData] = useState([]);
    const [landing, setLanding] = useState(0);

    const onSelectChangeHandler = (name) => (event) => {
        const value = event.target.value;
        if (name == "status") {
            setStatus(value);
        } else if (name == "payment_type") {
            setPaymentType(value);
        } else if (name == "landing") {
            setLanding(event.target.checked == true ? 1 : 0);
        }
        setValue(name, value);
    };

    const getPaymentTypeData = async () => {
        const response = await getSysGeneralData({
            api: api,
            type: "payment-type",
        });

        setPaymentTypeData(response.data.data);
    };
    
    useEffect (() => {
        getPaymentTypeData();
    }, []);
    
    const onSubmit = handleSubmit(async (data) => {
        document.getElementById("splash-screen").classList.remove("hidden");
        data['landing']   = landing;
        const response = await postCreateForm({
            api: api,
            data: data,
            method: "POST",
            url: "createProduct",
            enqueueSnackbar,
        });

        if (response) {
            document.getElementById("splash-screen").classList.add("hidden");
            if (response.data.status === 200) {
                enqueueSnackbar(response.data.data.msg, {
                    variant: response.data.data.msgType,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
                if (response.data.data.msgType == 'success') navigate("/admin/product-list");
            }
        }
    });

    const statusList = [
        { id: "A", name: "Active" },
        { id: "I", name: "Inactive" },
    ];

    return (
        <>
            <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]} >
                <CollapsedBreadcrumbs />
            </BreadcrumbsContext.Provider>
            <Card>
                <CardHeaderCss title="Create Campaign / Product"/>
                <CardContent>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="code"
                                    marginType="normal"
                                    label="Code"
                                    type="text"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "this field is required",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="name"
                                    marginType="normal"
                                    label="Name"
                                    type="text"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "this field is required",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="ori_price"
                                    marginType="normal"
                                    label="Original Price"
                                    type="number"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "this field is required",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="unit_price"
                                    marginType="normal"
                                    label="Selling Price"
                                    type="number"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "this field is required",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <SelectOptionComponent
                                    control={control}
                                    name="payment_type"
                                    placeholder="Payment Type"
                                    label="Payment Type"
                                    option={paymentTypeData}
                                    onChangeHandler={onSelectChangeHandler("payment_type")}
                                    value={paymentType}
//                                    rules={{
//                                        required: {
//                                            value: true,
//                                            message: "this field is required",
//                                        },
//                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <SelectOptionComponent
                                    control={control}
                                    name="status"
                                    placeholder="Status"
                                    label="Status"
                                    option={statusList}
                                    onChangeHandler={onSelectChangeHandler("status")}
                                    value={status}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "this field is required",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="seq_no"
                                    marginType="normal"
                                    label="Sequence Number"
                                    type="number"
                                    // rules={{
                                    //     required: {
                                    //         value: true,
                                    //         message: "this field is required",
                                    //     },
                                    // }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 20 }}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        control={control}
                                        name="landing"
                                        // checked={landing ? true : false}
                                        errors={errors}
                                        onChange={onSelectChangeHandler("landing")}
                                    />
                                    }
                                    label="Landing"
                                />
                            </Grid>
                        </Grid>
                        <AppBarSpacer/>
                        <div align="center">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={onSubmit}
                                size="large"
                            >
                                Submit
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </>
    );
}
