import * as action_types from "../../../../../actions/salesPerson/actionTypes";

const initialLoginState = {
  isLoggedIn: false,
  username: "",
  name: "",
};

export default function LoginReducer(state = initialLoginState, action) {
  switch (action.type) {
    case action_types.storeLoginToken:
      return LoginDetail(action.username, action.name, action.token, state);
    case action_types.removeLoginToken:
      return removeLoginDetail(state);
    case action_types.sessionExpired:
      return sessionExpired(state);
    case action_types.userSession:
      return userSession(state, action.payload);
    default:
      return state;
  }
}

function LoginDetail(username, name, token, state) {
  let isLoggedIn = false;
  const loggedInUser = localStorage.getItem("sales_person_token");

  if (token || loggedInUser) {
    isLoggedIn = true;
    name = localStorage.getItem("sales_person_name");
    username = localStorage.getItem("sales_person_nick_name");
  }

  state = {
    isLoggedIn: isLoggedIn,
    username: username,
    name: name,
  };

  return state;
}

const removeLoginDetail = (state) => {
  let isLoggedIn = false;

  state = {
    isLoggedIn: isLoggedIn,
    username: "",
    name: "",
  };

  clearLocalStorage();

  return state;
};

const sessionExpired = (state) => {
  return {
    isLoggedIn: false,
    username: "",
    name: "",
  };
};

const userSession = (state, payload) => {
  if (payload) {
    if (payload.data.status === 401) {
      clearLocalStorage();
      return {
        isLoggedIn: false,
        username: "",
        name: "",
      };
    } else {
      return state;
    }
  }
};

function clearLocalStorage(){
  localStorage.removeItem("sales_person_token");
  localStorage.removeItem("sales_person_name");
  localStorage.removeItem("sales_person_nick_name");
}