import React from "react";
import DataTable from "react-data-table-component";
import customStyles from "./dt-main-style";
import { Chip } from "@mui/material";

export default function DataTable2(props) {
	const status = {
		A: { class: "success" },
		F: { class: "error" },
		RF: { class: "error" },
		CNL: { class: "error" },
		P: { class: "warning" },
	};

	const delivery_status = {
		COM: { class: "success" },
		CFM: { class: "primary" },
		SHP: { class: "primary" },
		P: { class: "warning" },
	};

	const columns = [
		{
			name: "No.",
			selector: row => row.id,
			sortable: true,
			grow: 0,
			minWidth: "70px",
			cell: function (data, index) {
				return index + 1 + parseFloat(props.startFrom);
			},
		},
		{
			name: "Doc Date",
			selector: row => row.doc_date,
			sortable: true,
			sortField: 'doc_date',
			width: "120px",
			cell: function (data, index) {
				return data.doc_date;
			},
		},
		{
			name: "Doc No.",
			selector: row => row.doc_no,
			sortable: true,
			width: "150px",
		},
		{
			name: "Member Name",
			selector: row => row.name,
			sortable: true,
			width: "200px",
		},
		{
			name: "Member Email",
			selector: row => row.email,
			sortable: true,
			width: "200px",
		},
		{
			name: "Product",
			selector: row => row.items,
			sortable: true,
			width: "300px",
			cell: (data) => {
				var str = [];
				var items = [];
				if (data?.items && data?.items.length > 0) {
					items = data?.items.split("||");
				}

				items.map((option, key) => {
					str.push(
						<div key={'items-' + key} style={{ paddingBottom: "15px" }}>{option}</div>
					);
				});

				return (
					<div key={'product_' + data.id} className="row" style={{ paddingTop: "15px", width: "100%" }}>
						{str}
					</div>
				);
			},
		},
		{
			name: "Status",
			selector: row => row.status,
			sortable: true,
			width: "130px",
			cell: (row) => (
				<Chip color={status[row["status"]].class} label={row["status_desc"]}></Chip>
			),
		},
		{
			name: "Delivery Status",
			selector: row => row.delivery_status,
			sortable: true,
			width: "140px",
			cell: (row) => (
				<Chip color={delivery_status[row["delivery_status"]].class} label={row["delivery_status_desc"]}></Chip>
			),
		},
		{
			name: "Total Amount",
			selector: row => parseFloat(row.total_amount),
			sortable: true,
			width: "130px",
			cell: function (data, index) {
				return data.total_amount;
			},
		},
		{
			name: "Payment Type",
			selector: row => row.payment_methods,
			sortable: true,
		},
		{
			name: "Paid Amount",
			selector: row => parseFloat(row.payment_amounts),
			sortable: true,
			width: "130px",
			cell: function (data, index) {
				return data.payment_amounts;
			},
		},
		{
			name: "Ref No.",
			selector: row => row.payment_ref_nos,
			sortable: true,
			width: "250px",
		},
		{
			name: "Sales Person",
			selector: row => row.sales_person_name,
			sortable: true,
			width: "160px",
		},
		{
			name: "Created Date",
			selector: row => row.created_at,
			sortable: true,
			width: "160px",
		},
	];

	return (
		<DataTable
			columns={columns}
			data={props.fileteredData}
			customStyles={customStyles}
			noHeader
			pagination
			paginationServer
			paginationPerPage={50}
			paginationRowsPerPageOptions={[50, 100, 150]}
			paginationTotalRows={props.totalRows}
			onChangeRowsPerPage={props.onChangeRowsPerPage}
			onChangePage={props.onChangePage}
			progressPending={props.pending}
		/>
	);
}
