import SignIn from "../../components/SalesPersonModule/Login/Login";
import Dashboard from "../../components/SalesPersonModule/dashboard";
import CustomerList from "../../components/SalesPersonModule/Customer/Customer-list";
import CreateCustomer from "../../components/SalesPersonModule/Customer/Create-customer";
import Profile from "../../components/SalesPersonModule/Profile/Edit-profile";
import InstallmentList from "../../components/SalesPersonModule/Installment/Installment-List";
import InstallmentView from "../../components/SalesPersonModule/Installment/Installment-View";

const routes = [
  {
    path: "/salesperson/login",
    exact: true,
    auth: false,
    element: <SignIn />,
  },
  {
    path: "/salesperson/dashboard",
    exact: true,
    auth: true,
    element: <Dashboard />,
  },
  {
    path: "/salesperson/customer-list",
    exact: true,
    auth: true,
    element: <CustomerList />,
  },
  {
    path: "/salesperson/customer/create",
    exact: true,
    auth: true,
    element: <CreateCustomer />,
  },
  {
    path: "/salesperson/profile",
    exact: true,
    auth: true,
    element: <Profile />,
  },
  {
    path: "/salesperson/installment-list",
    exact: true,
    auth: true,
    element: <InstallmentList />,
  },
  {
    path: "/salesperson/installment-sales-form/view",
    exact: true,
    auth: true,
    element: <InstallmentView />,
  },
];

export default routes;
