import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import customStyles from "./dt-main-style";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Tooltip, Chip } from "@mui/material";

export default function DataTable2(props) {
  const status = {
    P: { class: "primary" },
    COM: { class: "success" },
  };

  const columns = [
    {
      name: "Actions",
      sortable: false,
      selector: (row) => row.row_number,
      center: true,
      maxWidth: "80px",
      cell: (row) => (
        <>
          <Link
            to={{
              pathname: "/salesperson/installment-sales-form/view",
            }}
            state={{
              installment_id: row.id,
            }}
          >
            <Tooltip title="view Installment">
              <IconButton color="primary">
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Link>
        </>
      ),
    },
    {
      name: "No.",
      selector: (row) => row.id,
      sortable: true,
      grow: 0,
      minWidth: "70px",
      cell: function (data, index) {
        return index + 1 + parseFloat(props.startFrom);
      },
    },
    {
      name: "Installment Date",
      selector: (row) => row.raw_doc_date,
      sortable: true,
      sortField: "raw_doc_date",
      width: "130px",
      cell: function (data, index) {
        return data.doc_date;
      },
    },
    {
      name: "Installment No",
      selector: (row) => row.doc_no,
      sortable: true,
      width: "130px",
    },
    {
      name: "Product/Campaign",
      selector: (row) => row.product_name,
      sortable: true,
      width: "250px",
    },
    {
      name: "Price",
      selector: (row) => parseFloat(row.total_amount),
      sortable: true,
      width: "100px",
      cell: function (data, index) {
        return data.total_amount;
      },
    },
    {
      name: "Full Name",
      selector: (row) => row.full_name,
      sortable: true,
      width: "180px",
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
      sortable: true,
      width: "220px",
    },
    {
      name: "Mobile No",
      selector: (row) => row.contact_no,
      sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "130px",
      cell: (row) => (
        <Chip
          color={status[row["status"]].class}
          label={row["status_desc"]}
        ></Chip>
      ),
    },
    {
      name: "Created Date",
      selector: (row) => row.created_at,
      sortable: true,
      width: "190px",
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={props.fileteredData}
      customStyles={customStyles}
      noHeader
      pagination
      paginationServer
      paginationPerPage={50}
      paginationRowsPerPageOptions={[50, 100, 150]}
      paginationTotalRows={props.totalRows}
      onChangeRowsPerPage={props.onChangeRowsPerPage}
      onChangePage={props.onChangePage}
      progressPending={props.pending}
    />
  );
}
