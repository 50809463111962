import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Main, ContainerEle, BgLogo, Paper, Logo, FormCss, LoginSubmitBtn } from "../../../css/Admin-css/admin-css";
import { useNavigate } from 'react-router-dom';
import { LoginAction } from "../../../actions/salesPerson/actionCreators";
import { AxiosContext } from "../../../app/assets/axios/salesPerson/AxiosComponent";
import { useSnackbar } from "notistack";
import { toAbsoluteUrl } from "../../../helpers";
import bg from '../../../assets/bg.png';
import meta_logo from '../../../assets/meta-logo.webp';

export default function SignIn(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const api = useContext(AxiosContext);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    nick_name: "",
    password: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();

    if (state.password !== "" && state.nick_name !== "") {
      dispatch(LoginAction({ api, data: state, enqueueSnackbar }));
    }
  };

  const temp = useSelector((state) => ({
    isLoggedIn: state.SalesPersonLoginReducer.isLoggedIn,
    userSession: state.SalesPersonErrorReducer.token_expired,
  }));

  
  useEffect(() => {
    if (temp.isLoggedIn) {
      navigate("salesperson/dashboard");
    }

    if (temp.userSession) {
      enqueueSnackbar("session expired", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  }, [temp.isLoggedIn, temp.userSession]);

  return (
    <>
      <Main>
        <BgLogo src={bg} alt="Background Logo"/>
        <ContainerEle component="main" maxWidth="xs" >
        <CssBaseline />
      <Paper>
        <header>
          <Logo src={meta_logo} alt="logo" />
        </header>
        <Typography variant="h6">
          Sales Person
        </Typography>
        <FormCss noValidate onSubmit={handleSubmitClick}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="nick_name"
            label="Username"
            name="username"
            autoComplete="email"
            autoFocus
            value={state.nick_name}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={state.password}
            onChange={handleChange}
          />
          <LoginSubmitBtn
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Continue
          </LoginSubmitBtn>
        </FormCss>
      </Paper>
    </ContainerEle>
    </Main>
    </>
  );
}
