import { combineReducers } from "redux";
import LoginReducer from "./Admin/loginReducer";
import ErrorReducer from "./Admin/errorReducer";
import SalesPersonLoginReducer from "./SalesPerson/loginReducer";
import SalesPersonErrorReducer from "./SalesPerson/errorReducer";
import GeneralReducer from "./Global/generalReducer";

const reducer = combineReducers({
  LoginReducer,
  ErrorReducer,
  SalesPersonLoginReducer,
  SalesPersonErrorReducer,
  GeneralReducer,
});

export default reducer;
