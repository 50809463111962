import React from "react";
import DataTable from "react-data-table-component";
import customStyles from "./dt-main-style";
import { Chip } from "@mui/material";

export default function DataTable2(props) {
	const status = {
		C: { class: "warning", desc: "Cold" },
		W: { class: "primary", desc: "Warm" },
		H: { class: "success", desc: "Hot" },
		B: { class: "error", desc: "Blacklisted" },
		D: { class: "error", desc: "Deleted" },
	};

	const disableEllipsis = {
		overflow: "visible!important",
		whiteSpace: "normal!important",
		maxWidth: "300px",
		padding: "5px 0px",
	};

	const columns = [
		{
			name: "#",
			sortable: true,
			grow: 0,
			cell: function (data, index) {
				return index + 1 + parseFloat(props.startFrom);
			},
			minWidth: "80px",
		},
		{
			name: "First Name",
			selector: (row) => row.first_name,
			sortable: true,
		},
		{
			name: "Last Name",
			selector: (row) => row.last_name,
			sortable: true,
		},
		{
			name: "Full Name",
			selector: (row) => row.full_name,
			sortable: true,
			minWidth: "200px",
		},
		{
			name: "Email",
			selector: (row) => row.email,
			sortable: true,
			minWidth: "180px",
		},
		{
			name: "Mobile No",
			selector: (row) => row.mobile_no,
			sortable: true,
			maxWidth: "150px",
		},
		{
			name: "Birthday",
			selector: (row) => row.birthday,
			sortable: true,
			minWidth: "50px",
		},
		{
			name: "Country",
			selector: (row) => row.country,
			sortable: true,
			minWidth: "150px",
		},
		{
			name: "Time Zone",
			selector: (row) => row.time_zone,
			sortable: true,
			minWidth: "150px",
		},
		{
			name: "Status",
			selector: (row) => row.status,
			sortable: true,
			maxWidth: "120px",
			cell: (row) => (
				<Chip
					color={status[row["status"]].class}
					label={status[row["status"]].desc}
				></Chip>
			),
		},
		{
			name: "Created Date",
			sortable: true,
			selector: (row) => row.created_at,
			minWidth: "180px",
		},
	];

	return (
		<DataTable
			columns={columns}
			data={props.fileteredData}
			customStyles={customStyles}
			noHeader
			pagination
			paginationServer
			paginationPerPage={50}
			paginationRowsPerPageOptions={[50, 100, 150]}
			paginationTotalRows={props.totalRows}
			onChangeRowsPerPage={props.onChangeRowsPerPage}
			onChangePage={props.onChangePage}
			progressPending={props.pending}
		/>
	);
}
