import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AxiosContext } from "../../../app/assets/axios/salesPerson/AxiosComponent";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import {
  fetchInstallmentDetails,
  getSysGeneralData,
} from "../../../actions/salesPerson/actionCreators";
import CollapsedBreadcrumbs, {
  BreadcrumbsContext,
} from "../../../layouts/salesPerson/breadcrumb";
import { CardHeaderCss } from "../../../css/Admin-css/listing-css";
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  CardActions,
} from "@mui/material";

import Typography from "@mui/material/Typography";

export default function InstallmentView() {
  const dispatch = useDispatch();
  const api = useContext(AxiosContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [installmentMaster, setInstallmentMaster] = useState([]);
  const [installmentDetails, setInstallmentDetails] = useState([]);
  const location = useLocation();
  const [breadcrumbArr, setBreadcrumbArr] = React.useState([
    {
      text: "Installment",
      link: "/salesperson/installment-list",
    },
    {
      text: "Installment Info",
      link: "",
    },
  ]);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      member: "",
      product_name: "",
      period: "",
    },
  });

  const getInstallmentInfo = async (id) => {
    const response = await fetchInstallmentDetails({
      api: api,
      data: {
        id: id,
      },
      enqueueSnackbar,
      dispatch,
    });

    if (response) {
      if (response.data.data) {
        setInstallmentMaster(response.data.data.master);
        setInstallmentDetails(response.data.data.details);
      }
    }
  };

  useEffect(() => {
    const id = location?.state?.installment_id
      ? location.state.installment_id
      : 0;
    if (id) {
      getInstallmentInfo(id);
    }
  }, []);

  return (
    <>
      <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]}>
        <CollapsedBreadcrumbs />
      </BreadcrumbsContext.Provider>
      <Card>
        <CardHeaderCss title="Installment Info" />
        <CardContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={4} md={2}>
                <p>Member:</p>
              </Grid>
              <Grid item xs={8} md={10}>
                {installmentMaster ? (
                  <p>
                    {installmentMaster.full_name} ({installmentMaster.email} |{" "}
                    {installmentMaster.contact_no})
                  </p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={4} md={2}>
                <p>Product:</p>
              </Grid>
              <Grid item xs={8} md={10}>
                {installmentMaster ? (
                  <p>{installmentMaster.product_name}</p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={4} md={2}>
                <p>Price:</p>
              </Grid>
              <Grid item xs={8} md={10}>
                {installmentMaster ? (
                  installmentMaster.total_disc != "0.00" ? (
                    <p>
                      USD {installmentMaster.total_amount} (Discount : USD{" "}
                      {installmentMaster.total_disc})
                    </p>
                  ) : (
                    <p>USD {installmentMaster.total_amount}</p>
                  )
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4} md={2}>
                <p>Installment Period:</p>
              </Grid>
              <Grid item xs={8} md={10}>
                {installmentMaster ? (
                  <p>
                    {installmentMaster.period} {installmentMaster.duration_type}
                  </p>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <hr />
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h6">Installment Details</Typography>
              </Grid>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Amount (USD)</TableCell>
                    <TableCell align="center">Payment Method</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {installmentDetails.map((detail, index) => {
                    return (
                      <TableRow
                        key={detail.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}.
                        </TableCell>
                        <TableCell align="center">
                          {detail.installment_date}
                        </TableCell>
                        <TableCell align="center">
                          {detail.total_amount}
                        </TableCell>
                        <TableCell align="center">
                          {detail.payment_type_name}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  );
}
