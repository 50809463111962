import React from "react";
import { Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import InputLabel from "@mui/material/InputLabel";
import {
	ErrorMsgCss,
	FormControlCss,
	InputLabelCss,
	SelectCss,
} from "../../css/Admin-css/listing-css";
// import FormControl from "@mui/material/FormControl";
export default function SelectOptionComponent(props) {
	const option = props.option.map((option, key) => {
		let returnOption = (
			<MenuItem value={option.id} key={key}>
				{option.name}
			</MenuItem>
		);
		if (props.type == "sysGeneral") {
			returnOption = (
				<MenuItem value={option.code} key={key}>
					{option.name}
				</MenuItem>
			);
		}

		return returnOption;
	});

	return (
		<>
			<FormControlCss variant="outlined" fullWidth>
				<InputLabelCss id={props.name}>{props.label}</InputLabelCss>
				<Controller
					rules={props.rules}
					name={props.name}
					control={props.control}
					defaultValue=""
					render={({ field }) => (
						<SelectCss
							{...field}
							name={props.name}
							displayEmpty
							variant="outlined"
							fullWidth
							placeholder={props.placeholder}
							labelId={props.name}
							label={props.label}
							error={props.errors[props.name] ? true : false}
							onChange={props.onChangeHandler}
							value={props.value}
							disabled={props.disabled ? true : false}
						>
							{option}
						</SelectCss>
					)}
				/>
				{props.errors[props.name] && (
					<ErrorMsgCss>
						<span>{props.errors[props.name].message}</span>
					</ErrorMsgCss>
				)}
			</FormControlCss>
		</>
	);
}
