import * as React from 'react';
import { Link } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Typography from '@mui/material/Typography';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SettingsIcon from '@mui/icons-material/Settings';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SellIcon from '@mui/icons-material/Sell';
import { styled, useTheme } from '@mui/material/styles';
import logo from '../../assets/logo-meta-academy.png';
import '../../App.css';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const LinkNoDeco = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: '#fff',
    width: '100%'
}));


export default function AsideComponent(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <Drawer
            sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: '#1a1a27'
            },
            }}
            variant="persistent"
            anchor="left"
            open={props.status}
        >
            <DrawerHeader>
                <img src={logo} className="App-logo" alt="logo" />
                <IconButton onClick={props.action} color="white">
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List sx={{color: '#FFFFFF'}}>
                <ListItem key="Dashboard" disablePadding>
                    <LinkNoDeco to="/salesperson/dashboard">
                        <ListItemButton> 
                            <ListItemIcon sx={{color: '#AD8E64'}}>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText disableTypography primary={<Typography variant="span">Dashboard</Typography>}/>
                        </ListItemButton>
                    </LinkNoDeco>
                </ListItem>
                <ListItem key="Profile" disablePadding>
                    <LinkNoDeco to="/salesperson/profile">
                        <ListItemButton>
                            <ListItemIcon sx={{color: '#AD8E64'}}>
                                <AdminPanelSettingsIcon />
                            </ListItemIcon>
                            <ListItemText disableTypography primary={<Typography variant="span">Profile</Typography>}/>
                        </ListItemButton>
                    </LinkNoDeco>
                </ListItem>
                <ListItem key="Customer" disablePadding>
                    <LinkNoDeco to="/salesperson/customer-list">
                        <ListItemButton>
                            <ListItemIcon sx={{color: '#AD8E64'}}>
                                <SupportAgentIcon/>
                            </ListItemIcon>
                            <ListItemText disableTypography primary={<Typography variant="span">Customer List</Typography>} />
                        </ListItemButton>
                    </LinkNoDeco>
                </ListItem>
                <ListItem key="Installment" disablePadding>
                    <LinkNoDeco to="/salesperson/installment-list">
                        <ListItemButton>
                            <ListItemIcon sx={{ color: '#AD8E64' }}>
                                <CreditCardIcon />
                            </ListItemIcon>
                            <ListItemText disableTypography primary={<Typography variant="span">Installment</Typography>} />
                        </ListItemButton>
                    </LinkNoDeco>
                </ListItem>
            </List>
        </Drawer>
    );
}
