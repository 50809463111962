import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import { postEditForm, fetchEditInfoList, getSysGeneralData } from "../../../actions/admin/actionCreators";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";
import { AppBarSpacer, CardHeaderCss } from "../../../css/Admin-css/listing-css";
import { Card, CardHeader, CardContent, Grid, Button, FormControlLabel, Checkbox } from "@mui/material";
import TextFieldComponent from "../../FormAddInputComponent/TextField";
import SelectOptionComponent from "../../FormAddInputComponent/SelectOption";

export default function ProductUpdate() {
    const dispatch = useDispatch();
    const api = useContext(AxiosContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [status, setStatus] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [paymentTypeData, setPaymentTypeData] = useState([]);
    const [landing, setLanding] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const [breadcrumbArr, setBreadcrumbArr] = React.useState([
        {
            text: "Campaign / Product",
            link: "/admin/product-list",
        }, 
        {
            text: "Campaign / Product Form",
            link: "/admin/product/update",
        },
    ]);
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "all",
    });

    const onSubmit = handleSubmit(async (data) => {
        document.getElementById("splash-screen").classList.remove("hidden");
        // data["prd_master_id"] = props.location.state.prd_master_id;
        // data["id"] = props.location.state.prd_master_id;
        data["prd_master_id"] = location?.state?.prd_master_id ? location.state.prd_master_id : 0;
        data["id"] = location?.state?.prd_master_id ? location.state.prd_master_id : 0;
        data['landing'] = landing;

        const response = await postEditForm({
            api: api,
            data: data,
            method: "POST",
            url: "updateProduct",
            enqueueSnackbar,
        });

        if (response) {
            document.getElementById("splash-screen").classList.add("hidden");
            if (response.status === 200) {
                enqueueSnackbar(response.data.msg, {
                    variant: response.data.msgType,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
                if (response.data.msgType == 'success') navigate("/admin/product-list");
            }
        }
    });

    const statusList = [
        { id: "A", name: "Active" },
        { id: "I", name: "Inactive" },
        { id: "D", name: "Delete" },
    ];

    const getProductInfo = async (id) => {
        const response = await fetchEditInfoList({
            api: api,
            url: "getProductList",
            data: {
                prd_master_id: id,
            },
            enqueueSnackbar,
            dispatch,
        });

        if (response) {
            if (response.data.data) {
                setValue("code", response.data.data[0].code);
                setValue("name", response.data.data[0].name);
                setValue("ori_price", response.data.data[0].original_price);
                setValue("unit_price", response.data.data[0].unit_price);
//                setValue("payment_type", response.data.data[0].payment_type);
                setValue("status", response.data.data[0].status);
                setValue("seq_no", response.data.data[0].seq_no);
                setStatus(response.data.data[0].status);
                setPaymentType(response.data.data[0].payment_type_id);
                setLanding(response.data.data[0].landing);
            }
        }
    };

    const onSelectChangeHandler = (name) => (event) => {
        const value = event.target.value;
        if (name == "status") {
            setStatus(value);
        } else if (name == "payment_type") {
            setPaymentType(value);
        } else if (name == "landing") {
            setLanding(event.target.checked == true ? 1 : 0);
        }
        setValue(name, value);
    };

    const getPaymentTypeData = async () => {
        const response = await getSysGeneralData({
            api: api,
            type: "payment-type",
        });

        setPaymentTypeData(response.data.data);
    };
    
    useEffect(() => {
        getPaymentTypeData();
        // const id = props.location.state ? props.location.state.prd_master_id : 0;
        const id = location?.state?.prd_master_id ? location.state.prd_master_id : 0;
        if (id) {
            getProductInfo(id);
        }
    }, []);

    return (
        <>
            <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]} >
                <CollapsedBreadcrumbs />
            </BreadcrumbsContext.Provider>
            <Card>
                <CardHeaderCss title="Update Campaign / Product"/>
                <CardContent>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="code"
                                    marginType="normal"
                                    label="Code"
                                    type="text"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "this field is required",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="name"
                                    marginType="normal"
                                    label="Name"
                                    type="text"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "this field is required",
                                        },
                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="ori_price"
                                    marginType="normal"
                                    label="Original Price"
                                    type="number"
                                    rules={{ required: true }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="unit_price"
                                    placeholder="Selling Price"
                                    marginType="normal"
                                    label="Selling Price"
                                    type="number"
                                    rules={{ required: true }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <SelectOptionComponent
                                    control={control}
                                    name="payment_type"
                                    placeholder="Payment Type"
                                    label="Payment Type"
                                    option={paymentTypeData}
                                    onChangeHandler={onSelectChangeHandler("payment_type")}
                                    value={paymentType}
//                                    rules={{
//                                        required: {
//                                            value: true,
//                                            message: "this field is required",
//                                        },
//                                    }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <SelectOptionComponent
                                    control={control}
                                    name="status"
                                    placeholder="Status"
                                    label="Status"
                                    option={statusList}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "this field is required",
                                        },
                                    }}
                                    errors={errors}
                                    onChangeHandler={onSelectChangeHandler("status")}
                                    value={status}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                <TextFieldComponent
                                    control={control}
                                    name="seq_no"
                                    marginType="normal"
                                    label="Sequence Number"
                                    type="number"
                                    // rules={{ required: true }}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingTop: 20 }}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        control={control}
                                        name="landing"
                                        checked={landing ? true : false}
                                        errors={errors}
                                        onChange={onSelectChangeHandler("landing")}
                                    />
                                    }
                                    label="Landing"
                                />
                            </Grid>
                        </Grid>
                        <AppBarSpacer/>
                        <div align="center">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={onSubmit}
                                size="large"
                            >
                                Update
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </>
    );
}
