import React, { useContext, useState, useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { AppBarSpacer } from "../../../css/Admin-css/listing-css";
import SalesListDatatable from "../DataTable/dt-salesList";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useForm } from "react-hook-form";
import SearchField from "../DataTable/SearchField/SearchField";

import {
  getCountry,
  fetchProductList,
  fetchSalesList,
  handleDataTablePerRowsChange,
  fetchSalesPersonList,
  getSysGeneralData,
  fetchPaymentTypeList,
} from "../../../actions/admin/actionCreators";
import CollapsedBreadcrumbs, {
  BreadcrumbsContext,
} from "../../../layouts/admin/breadcrumb";

export default function SalesList() {
  const api = useContext(AxiosContext);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [startFrom, setStart] = useState(0);
  const [pending, setPending] = useState(true);
  const [country_id, setCountry] = useState([]);
  const [product_id, setProduct] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [status, setStatus] = useState([]);
  const [salesPerson, setSalesPerson] = useState([]);
  const [payment_id, setPayment] = useState([]);
  const [adsSales] = useState([
    { value: 1, desc: "Ads Sales" },
    { value: 0, desc: "Exclude Ads Sales" },
  ]);
  const [breadcrumbArr, setBreadcrumbArr] = React.useState([
    {
      text: "Sales",
      link: "",
    },
  ]);

  const searchField = [
    {
      name: "date_from",
      label: "Invoice Date From",
      placeholder: "Invoice Date From",
      marginType: "normal",
      type: "date_picker",
    },
    {
      name: "date_to",
      label: "Invoice Date To",
      placeholder: "Invoice Date To",
      marginType: "normal",
      type: "date_picker",
    },
    {
      name: "invoice_no",
      label: "Invoice No",
      placeholder: "Invoice No",
      marginType: "normal",
      type: "text",
    },
    {
      name: "installment_no",
      label: "Installment No",
      placeholder: "Installment No",
      marginType: "normal",
      type: "text",
    },
    {
      name: "ref_no",
      label: "Payment Ref No",
      placeholder: "Payment Ref No",
      marginType: "normal",
      type: "text",
    },
    {
      name: "product_id",
      label: "Campaign",
      placeholder: "Campaign",
      marginType: "normal",
      type: "select",
      children: product_id,
    },
    {
      name: "full_name",
      label: "Full Name",
      placeholder: "Full Name",
      marginType: "normal",
      type: "text",
    },
    {
      name: "email",
      label: "Email",
      placeholder: "example@email.com",
      marginType: "normal",
      type: "text",
    },
    {
      name: "mobile_no",
      label: "Mobile No",
      placeholder: "+60123456789",
      marginType: "normal",
      type: "text",
    },
    {
      name: "company_id",
      label: "Sales Person",
      placeholder: "Sales Person",
      marginType: "normal",
      type: "select",
      children: salesPerson,
    },
    {
      name: "country_id",
      label: "Country",
      placeholder: "Country",
      marginType: "normal",
      type: "select",
      children: country_id,
    },
    {
      name: "status",
      label: "Status",
      placeholder: "Status",
      marginType: "normal",
      type: "select",
      children: status,
    },
    {
      name: "ads_sales",
      label: "All Sales",
      placeholder: "All Sales",
      marginType: "normal",
      type: "select",
      children: adsSales,
    },
    {
      name: "payment_id",
      label: "Payment Type",
      placeholder: "Payment Type",
      marginType: "normal",
      type: "select",
      children: payment_id,
    },
  ];

  const { handleSubmit, control, reset, getValues, setValue } = useForm({
    defaultValues: {
      date_from: null,
      date_to: null,
      invoice_no: "",
      product_id: "",
      full_name: "",
      email: "",
      mobile_no: "",
      company_id: "",
      country_id: "",
      status: "",
      ads_sales: "",
      installment_no: "",
      ref_no: "",
      payment_id: "",
    },
  });

  const getCountryData = async () => {
    const countryList = [];
    const response = await getCountry({
      api: api,
    });
    if (response) {
      if (response.status == 200) {
        response.data.map((val) => {
          countryList.push({
            value: val.id,
            desc: val.name,
          });
        });
      }
    }
    setCountry(countryList);
  };

  const getProductData = async () => {
    const productList = [];
    const response = await fetchProductList({
      api: api,
    });
    if (response) {
      if (response.status == 200) {
        response.data.data.map((val) => {
          productList.push({
            value: val.id,
            desc: val.name,
          });
        });
      }
    }
    setProduct(productList);
  };

  const getPaymentData = async () => {
    const paymentList = [];
    const response = await fetchPaymentTypeList({
      api: api,
    });
    if (response) {
      if (response.status == 200) {
        response.data.data.map((val) => {
          paymentList.push({
            value: val.id,
            desc: val.name,
          });
        });
      }
    }
    setPayment(paymentList);
  };

  const getSalesListData = async () => {
    setPending(true);

    const param = {};
    param["page"] = startFrom;
    param["per_page"] = perPage;
	param["date_from"] = getValues("date_from")
      ? convertUTCDateToLocalDate(getValues("date_from"))
      : "";
    param["date_to"] = getValues("date_to")
      ? convertUTCDateToLocalDate(getValues("date_to"))
      : "";
    param["invoice_no"] = getValues("invoice_no");
    param["product_id"] = getValues("product_id");
    param["full_name"] = getValues("full_name");
    param["email"] = getValues("email");
    param["mobile_no"] = getValues("mobile_no");
    param["company_id"] = getValues("company_id");
    param["country_id"] = getValues("country_id");
    param["status"] = getValues("status");
    param["ads_sales"] = getValues("ads_sales");
    param["installment_no"] = getValues("installment_no");
    param["ref_no"] = getValues("ref_no");
    param["payment_id"] = getValues("payment_id");

    const response = await fetchSalesList({
      api: api,
      data: param,
    });

    if (response) {
      if (response.status == 200) {
        setPending(false);
        if (response.data.total_records > 0) {
          setData(response.data.data);
          setTotalRows(response.data.total_records);
        }
      }
    }
  };

  const getSalesListExportData = async () => {
    const param = {};
    param["date_from"] = getValues("date_from")
      ? convertUTCDateToLocalDate(getValues("date_from"))
      : "";
    param["date_to"] = getValues("date_to")
      ? convertUTCDateToLocalDate(getValues("date_to"))
      : "";
    param["invoice_no"] = getValues("invoice_no");
    param["product_id"] = getValues("product_id");
    param["full_name"] = getValues("full_name");
    param["email"] = getValues("email");
    param["mobile_no"] = getValues("mobile_no");
    param["company_id"] = getValues("company_id");
    param["country_id"] = getValues("country_id");
    param["status"] = getValues("status");
    param["ads_sales"] = getValues("ads_sales");
    param["installment_no"] = getValues("installment_no");
    param["ref_no"] = getValues("ref_no");
    param["payment_id"] = getValues("payment_id");

    const response = await fetchSalesList({
      api: api,
      data: param,
    });

    if (response) {
      if (response.status == 200) {
        const result = response.data.data;
        setExportData(
          result && result.length > 0
            ? result.map((x, index) => ({
                "No.": index + 1,
                "Invoice Date": x.doc_date,
                "Invoice No": x.doc_no,
                "Installment No": x.installment_no,
                "Product/Campaign": x.ads_sales
                  ? "(Ads Sales) " + x.item_desc
                  : x.item_desc,
                Price: x.total_amount,
                "Payment Type": x.payment_type,
                "Payment Ref No": x.ref_no,
                "Full Name": x.full_name,
                "Email Address": x.email,
                "Mobile No": x.contact_no,
                "Sales Person": x.sales_person_name,
                Country: x.country_name,
                Status: x.status_desc,
                "Created Date": x.created_at,
              }))
            : []
        );
      }
    }
  };

  const getStatusData = async () => {
    const statusList = [];
    const response = await getSysGeneralData({
      api: api,
      type: "invoice-status",
    });
    if (response) {
      if (response.status == 200) {
        response.data.data.map((val) => {
          statusList.push({
            value: val.code,
            desc: val.name,
          });
        });
      }
    }
    setStatus(statusList);
  };

  const getSalesPersonData = async () => {
    const salesPersonList = [];
    const param = {};
    param["status"] = "A";
    const response = await fetchSalesPersonList({
      api: api,
      data: param,
    });
    if (response) {
      if (response.status == 200) {
        response.data.data.map((val) => {
          salesPersonList.push({
            value: val.id,
            desc: val.name,
          });
        });
      }
    }
    setSalesPerson(salesPersonList);
  };

  function convertUTCDateToLocalDate(date) {
    // var newDate = new Date(
    //   date.getTime() - date.getTimezoneOffset() * 60 * 1000
    // );
	var formatDate = date.getFullYear() + "-" + parseInt(date.getMonth() + 1) + "-" + date.getDate();
	return formatDate;
  }

  const onSubmit = handleSubmit(async (param) => {
    param["date_from"] = param["date_from"]
      ? convertUTCDateToLocalDate(param["date_from"])
      : "";
    param["date_to"] = param["date_to"]
      ? convertUTCDateToLocalDate(param["date_to"])
      : "";
    param["page"]     = startFrom;
    param["per_page"] = perPage;

    const response = await fetchSalesList({
      api: api,
      data: param,
    });

    setData(response.data.data);
    setTotalRows(response.data.total_records);
    getSalesListExportData();
  });

  const onReset = () => {
    reset();
    onSubmit();
  };

  const handlePageChange = async (page) => {
    const startPage = (page - 1) * perPage;
    setStart(startPage);
  };

  const handlePerRowsChange = async (newPerPage) => {
    const param = {};

    param["page"]      = 0;
    param["per_page"]  = newPerPage;
    param["email"]     = getValues("email");
    param["mobile_no"] = getValues("mobile_no");
    param["full_name"] = getValues("full_name");

    const response = await handleDataTablePerRowsChange({
      api: api,
      url: "get-all-sales-list",
      data: param,
    });

    setData(response.data.data);
    setPerPage(newPerPage);
  };

  useEffect(() => {
    getCountryData();
    getProductData();
    getSalesListData();
    getSalesListExportData();
    getStatusData();
    getSalesPersonData();
    getPaymentData();
  }, [startFrom]);

  return (
    <>
      <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]}>
        <CollapsedBreadcrumbs />
      </BreadcrumbsContext.Provider>
      <div className="row">
        <div className="col-md-12">
          {/* <FormRow /> */}
          <SearchField
            control={control}
            searchField={searchField}
            onSubmit={onSubmit}
            onReset={onReset}
            getValues={getValues}
            setValue={setValue}
            exportData={exportData}
            exportButton={true}
            onExportFileName={"sales_listing.csv"}
          />

          <AppBarSpacer />
          <Card>
            <CardContent>
              <SalesListDatatable
                totalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fileteredData={data}
                startFrom={startFrom}
                pending={pending}
              ></SalesListDatatable>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}
