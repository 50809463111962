import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import {
  postEditForm,
  fetchInstallmentDetails,
  getSysGeneralData,
} from "../../../actions/admin/actionCreators";
import CollapsedBreadcrumbs, {
  BreadcrumbsContext,
} from "../../../layouts/admin/breadcrumb";
import { CardHeaderCss } from "../../../css/Admin-css/listing-css";
import { Card, CardContent, Grid, Button, CardActions } from "@mui/material";
import TextFieldComponent from "../../FormAddInputComponent/TextField";
import SelectOptionComponent from "../../FormAddInputComponent/SelectOption";
import Typography from "@mui/material/Typography";
import DatePickerComponent from "../../FormAddInputComponent/DatePickerField";

export default function InstallmentUpdate() {
  const dispatch = useDispatch();
  const api = useContext(AxiosContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [installmentMaster, setInstallmentMaster] = useState([]);
  const [installmentDetails, setInstallmentDetails] = useState([]);
  const [period, setPeriod] = React.useState([]);
  const [productPrice, setProductPrice] = React.useState("");
  const [averagePrice, setAveragePrice] = React.useState(0);
  const [paymentOptions, setPaymentOptions] = React.useState([]);
  const [paymentType, setPaymentType] = React.useState([]);
  const [docDate, setDocDate] = React.useState([]);
  const [showUpdate, setShowUpdate] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [breadcrumbArr, setBreadcrumbArr] = React.useState([
    {
      text: "Installment",
      link: "/admin/installment-list",
    },
    {
      text: "Installment Form",
      link: "",
    },
  ]);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      member: "",
      product_name: "",
      period: "",
    },
  });

  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date);
    var formatDate =
      newDate.getFullYear() +
      "-" +
      parseInt(newDate.getMonth() + 1) +
      "-" +
      newDate.getDate();
    return formatDate;
  }

  const onSubmitHandler = handleSubmit(async (data) => {
    if (getValues("period") < 1 + installmentDetails.length) {
      enqueueSnackbar(
        `Min Installment Period is ${1 + installmentDetails.length} month`,
        {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }
      );
      return false;
    }
    document.getElementById("splash-screen").classList.remove("hidden");
    data["id"] = location?.state?.installment_id
      ? location.state.installment_id
      : 0;

    data["details"].map((detail, key) => {
      data["details"][key]["date"] = detail.date
        ? convertUTCDateToLocalDate(detail.date)
        : "";
    });
    const response = await postEditForm({
      api: api,
      data: data,
      method: "POST",
      url: "updateInstallment",
      enqueueSnackbar,
    });

    if (response) {
      document.getElementById("splash-screen").classList.add("hidden");
      enqueueSnackbar(response.msg, {
        variant: response.msgType,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      if (response.msgType == "success") {
        navigate("/admin/installment-list");
      }
    }
  });

  const getInstallmentInfo = async (id) => {
    const response = await fetchInstallmentDetails({
      api: api,
      data: {
        id: id,
      },
      enqueueSnackbar,
      dispatch,
    });

    if (response) {
      if (response.data.data) {
        setInstallmentMaster(response.data.data.master);
        setProductPrice(response.data.data.master.total_amount);
        setValue("period", response.data.data.master.period);
        setInstallmentDetails(response.data.data.details);
        response.data.data.details.map((detail, index) => {
          setValue(`[details][${index}][total_amount]`, detail.total_amount);
          setValue(`[details][${index}][date]`, detail.installment_date);
          setValue(
            `[details][${index}][payment_type_name]`,
            detail.payment_type_name
          );
        });

        const remaining_period =
          response.data.data.master.period - response.data.data.details.length;
        if (remaining_period > 0) {
          let periodArray = [];
          for (let index = 0; index < remaining_period; index++) {
            periodArray.push(index);
          }
          setPeriod(periodArray);
        } else {
          setShowUpdate(false);
        }
      }
    }
  };

  const periodOnChange = (event) => {
    setValue("period", event.target.value);
    let periodValue = event.target.value;
    let periodArray = [];
    for (
      let index = 0;
      index < periodValue - installmentDetails.length;
      index++
    ) {
      periodArray.push(index);
    }
    setPeriod(periodArray);
    countInstallmentPrice();
  };

  const countInstallmentPrice = (type = "") => {
    if (productPrice && getValues("period") - installmentDetails.length) {
      let paid_amount = 0;
      installmentDetails.map((detail, key) => {
        paid_amount = paid_amount + parseFloat(detail.total_amount);
      });

      const periodVal = getValues("period") - installmentDetails.length;
      const average = (parseFloat(productPrice) - paid_amount) / periodVal;
      let total_amount = average;

      if (type == "change_value") {
        if (
          getValues(`[details][${installmentDetails.length}][total_amount]`) !=
          average
        ) {
          total_amount =
            (parseFloat(productPrice) -
              paid_amount -
              getValues(
                `[details][${installmentDetails.length}][total_amount]`
              )) /
            (periodVal - 1);
          period.map((val, index) => {
            if (index != 0) {
              setValue(
                `[details][${index + installmentDetails.length}][total_amount]`,
                total_amount.toFixed(2)
              );
            }
          });
        }
      } else {
        setAveragePrice(average);
      }
    }
  };

  const totalAmountOnChange = (event) => {
    setValue(event.target.getAttribute("name"), event.target.value);
    if (
      event.target.getAttribute("name") ==
      `[details][${installmentDetails.length}][total_amount]`
    ) {
      countInstallmentPrice("change_value");
    }
  };

  const onSelectChangeHandler = (name, index) => (event) => {
    const value = event.target.value;
    if (name == "payment_type_id") {
      let payment_array = [...paymentType];
      payment_array[index] = value;
      setPaymentType(payment_array);
    }

    setValue(`[details][${index}][payment_type_id]`, value);
  };

  const onDateChangeHandler = (value, name, index) => {
    if (name == "date") {
      let doc_array = [...docDate];
      doc_array[index] = value;
      setDocDate(doc_array);
    }
    setValue(`[details][${index}][date]`, value);
  };

  const getPaymentType = async () => {
    const response = await getSysGeneralData({
      api: api,
      type: "payment-type",
    });
    if (response) {
      setPaymentOptions(response.data.data);
    }
  };

  React.useEffect(() => {
    countInstallmentPrice();
  }, [productPrice]);

  React.useEffect(() => {
    period.map((val, index) => {
      setValue(
        `[details][${index + installmentDetails.length}][total_amount]`,
        averagePrice.toFixed(2)
      );
    });
  }, [averagePrice]);

  useEffect(() => {
    getPaymentType();
    const id = location?.state?.installment_id
      ? location.state.installment_id
      : 0;
    if (id) {
      getInstallmentInfo(id);
    }
  }, []);

  return (
    <>
      <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]}>
        <CollapsedBreadcrumbs />
      </BreadcrumbsContext.Provider>
      <Card>
        <CardHeaderCss title="Installment Update Form" />
        <CardContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={4} md={2}>
                <p>Member:</p>
              </Grid>
              <Grid item xs={8} md={10}>
                {installmentMaster ? (
                  <p>
                    {installmentMaster.full_name} ({installmentMaster.email} |{" "}
                    {installmentMaster.contact_no})
                  </p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={4} md={2}>
                <p>Product:</p>
              </Grid>
              <Grid item xs={8} md={10}>
                {installmentMaster ? (
                  <p>{installmentMaster.product_name}</p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={4} md={2}>
                <p>Price:</p>
              </Grid>
              <Grid item xs={8} md={10}>
                {installmentMaster ? (
                  installmentMaster.total_disc != "0.00" ? (
                    <p>
                      USD {installmentMaster.total_amount} (Discount : USD{" "}
                      {installmentMaster.total_disc})
                    </p>
                  ) : (
                    <p>USD {installmentMaster.total_amount}</p>
                  )
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4} md={2}>
                <p>Installment Period (months):</p>
              </Grid>
              <Grid item xs={8} md={6}>
                <TextFieldComponent
                  control={control}
                  name="period"
                  placeholder="Installment Period (month)"
                  marginType="normal"
                  type="tel"
                  errors={errors}
                  setValue={setValue}
                  onChangeHandler={periodOnChange}
                  disabled={showUpdate ? false : true}
                />
              </Grid>
            </Grid>
            <hr />
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h6">Installment Details</Typography>
              </Grid>
              {installmentDetails.map((detail, index) => {
                return (
                  <Grid container spacing={2} key={index}>
                    <Grid
                      item
                      xs={2}
                      md={1}
                      style={{ alignSelf: "center", textAlign: "center" }}
                    >
                      <span>{index + 1}.</span>
                    </Grid>
                    <Grid item xs={10} md={3}>
                      <TextFieldComponent
                        control={control}
                        name={`[details][${index}][total_amount]`}
                        placeholder="Amount"
                        label="Amount"
                        marginType="normal"
                        type="number"
                        errors={errors}
                        setValue={setValue}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={10} md={3}>
                      <TextFieldComponent
                        control={control}
                        name={`[details][${index}][date]`}
                        label="Invoice Date"
                        errors={errors}
                        marginType="normal"
                        setValue={setValue}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={10} md={3}>
                      <TextFieldComponent
                        control={control}
                        name={`[details][${index}][payment_type_name]`}
                        placeholder="Payment Type"
                        label="Payment Type"
                        marginType="normal"
                        type="text"
                        errors={errors}
                        setValue={setValue}
                        disabled
                      />
                    </Grid>
                  </Grid>
                );
              })}
              {period.map((value, index) => {
                return (
                  <Grid container spacing={2} key={index}>
                    <Grid
                      item
                      xs={2}
                      md={1}
                      style={{ alignSelf: "center", textAlign: "center" }}
                    >
                      <span>{index + 1 + installmentDetails.length}.</span>
                    </Grid>
                    <Grid item xs={10} md={3}>
                      <TextFieldComponent
                        control={control}
                        name={`[details][${
                          index + installmentDetails.length
                        }][total_amount]`}
                        placeholder="Amount"
                        label="Amount"
                        marginType="normal"
                        type="number"
                        errors={errors}
                        setValue={setValue}
                        onChangeHandler={totalAmountOnChange}
                        data-key={index + installmentDetails.length}
                      />
                    </Grid>
                    <Grid item xs={10} md={3}>
                      <DatePickerComponent
                        control={control}
                        name={`[details][${
                          index + installmentDetails.length
                        }][date]`}
                        label="Invoice Date"
                        marginType="normal"
                        errors={errors}
                        value={docDate[index + installmentDetails.length] ?? ""}
                        onChange={(value) => {
                          onDateChangeHandler(
                            value,
                            "date",
                            index + installmentDetails.length
                          );
                        }}
                        setValue={setValue}
                      />
                    </Grid>
                    <Grid item xs={10} md={3}>
                      <SelectOptionComponent
                        control={control}
                        name={`[details][${
                          index + installmentDetails.length
                        }][payment_type_id]`}
                        placeholder="Payment Type"
                        label="Payment Type"
                        option={paymentOptions}
                        errors={errors}
                        setValue={setValue}
                        onChangeHandler={onSelectChangeHandler(
                          "payment_type_id",
                          index + installmentDetails.length
                        )}
                        value={
                          paymentType[index + installmentDetails.length] ?? ""
                        }
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            {showUpdate ? (
              <CardActions
                style={{ justifyContent: "center", marginBottom: "16px" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={onSubmitHandler}
                >
                  Update
                </Button>
              </CardActions>
            ) : (
              ""
            )}
          </form>
        </CardContent>
      </Card>
    </>
  );
}
