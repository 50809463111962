import React, { useState, useEffect, useContext } from "react";
import CollapsedBreadcrumbs, {
  BreadcrumbsContext,
} from "../../../layouts/admin/breadcrumb";
import { AppBarSpacer } from "../../../css/Admin-css/listing-css";
import {
  Grid,
  Paper,
  Typography,
  Chip,
  Button,
  Avatar,
  Tabs,
  Tab,
  ListItemText,
} from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import {
  getCampaignSalesData,
  getSalesPersonSalesData,
} from "../../../actions/admin/actionCreators";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";

export default function TotalSalesList() {
  const avatarColor = [
    "#FFC107",
    "#20C997",
    "#FF5A5A",
    "#FFC107",
    "#20C997",
    "#FF5A5A",
  ];
  const listColor = [
    "#5AB9FF",
    "#FFC107",
    "#00D9D9",
    "#FF5A5A",
    "#7239EA",
    "#5AB9FF",
    "#FFC107",
    "#00D9D9",
    "#FF5A5A",
    "#7239EA",
  ];
  const api = useContext(AxiosContext);
  const [tabValue, setTabValue] = useState("campaign");
  const [state, setState] = useState({
    salesPerson: [],
    campaign: [],
  });
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [breadcrumbArr, setBreadcrumbArr] = React.useState([
    {
      text: "Sales",
      link: "",
    },
  ]);

  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(5),
  }));

  const showCampaignSalesData = async () => {
    const response = await getCampaignSalesData({
      api: api,
      data: [],
    });

    if (response) {
      if (response.status == 200) {
        setState((prevState) => {
          return {
            ...prevState,
            campaign: response.data,
          };
        });
      }
    }
  };

  const showSalesPersonSalesData = async () => {
    const response = await getSalesPersonSalesData({
      api: api,
      data: [],
    });

    if (response) {
      if (response.status == 200) {
        setState((prevState) => {
          return {
            ...prevState,
            salesPerson: response.data,
          };
        });
      }
    }
  };

  useEffect(() => {
    showSalesPersonSalesData();
    showCampaignSalesData();
  }, []);

  return (
    <>
      <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]}>
        <CollapsedBreadcrumbs />
      </BreadcrumbsContext.Provider>
      <div className="row">
        <div className="col-md-12">
          <AppBarSpacer />
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 3, md: 5 }}
            style={{ marginTop: "10px" }}
          >
            <Grid item xs={12}>
              <TabContext value={tabValue}>
                <Item>
                  <Grid container>
                    <Grid item xs={10}>
                      <Tabs
                        onChange={handleChange}
                        value={tabValue}
                        TabIndicatorProps={{ style: { display: "none" } }}
                      >
                        <Tab
                          label="Per Campaign"
                          value="campaign"
                          style={{ fontWeight: "600", fontSize: "16px" }}
                        />
                        <Tab
                          label="Per Sales Person"
                          value="sales-person"
                          style={{ fontWeight: "600", fontSize: "16px" }}
                        />
                      </Tabs>
                    </Grid>
                    <Grid item xs={2} textAlign="right">
                      {tabValue == "campaign" ? (
                        <RouterLink
                          to="/admin/product/create"
                          style={{ textDecoration: "none" }}
                        >
                          <Button variant="contained" color="primary">
                            Create campaign
                          </Button>
                        </RouterLink>
                      ) : (
                        <RouterLink
                          to="/admin/sales-person/create"
                          style={{ textDecoration: "none" }}
                        >
                          <Button variant="contained" color="primary">
                            Create Sales Person
                          </Button>
                        </RouterLink>
                      )}
                    </Grid>
                  </Grid>
                  <TabPanel value="campaign">
                    {Object.keys(state.campaign).length > 0
                      ? state.campaign.map((prd, ind) => {
                          return (
                            <Grid
                              key={ind}
                              container
                              rowSpacing={3}
                              columnSpacing={{ xs: 1, sm: 3, md: 5 }}
                              style={{
                                marginTop: "10px",
                                marginLeft: "0px",
                                width: "100%",
                                borderLeft:
                                  "5px solid " +
                                  listColor[ind > 9 ? ind - 9 : ind],
                              }}
                            >
                              <Grid item xs={6}>
                                <ListItemText
                                  primary={prd.name}
                                  secondary="Campaign"
                                />
                              </Grid>
                              <Grid item xs={3} md={2}>
                                <ListItemText
                                  primary={prd.unit_price}
                                  secondary="Price"
                                  style={{ textAlign: "center" }}
                                />
                              </Grid>
                              <Grid item xs={3} md={2}>
                                <ListItemText
                                  primary={prd.code}
                                  secondary="Code"
                                  style={{ textAlign: "center" }}
                                />
                              </Grid>
                              <Grid item xs={3} md={2}>
                                <ListItemText
                                  primary={"$ " + prd.total_sales}
                                  // secondary={
                                  //   prd.status == "A" ? (
                                  //     <Chip label="Active" color="green" />
                                  //   ) : (
                                  //     <Chip label="Inactive" color="grey" />
                                  //   )
                                  // }
                                  secondary="Sales"
                                  style={{ textAlign: "end" }}
                                />
                              </Grid>
                            </Grid>
                          );
                        })
                      : ""}
                  </TabPanel>
                  <TabPanel value="sales-person">
                    {Object.keys(state.salesPerson).length > 0
                      ? state.salesPerson.map((sp, ind) => {
                          return (
                            <Grid
                              key={ind}
                              container
                              alignItems="center"
                              style={{
                                paddingBottom: "15px",
                                paddingTop: "15px",
                                borderBottom: "1px dashed #d9dce9",
                              }}
                            >
                              <Grid item md={1} xs={2}>
                                <Avatar
                                  sx={{
                                    bgcolor:
                                      avatarColor[ind > 10 ? ind - 10 : ind],
                                  }}
                                >
                                  {sp.name.charAt(0)}
                                </Avatar>
                              </Grid>
                              <Grid item md={4} xs={4}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: "600",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {sp.name}
                                </Typography>
                                <Typography
                                  variant="p"
                                  style={{
                                    color: "#A1A5B7",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {sp.code}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                md={4}
                                xs={3}
                                style={{
                                  textAlign: "end",
                                  alignSelf: "center",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  style={{ fontWeight: "600" }}
                                >
                                  {sp.customer_count}
                                </Typography>
                                <Typography
                                  variant="p"
                                  style={{ color: "#A1A5B7" }}
                                >
                                  Assigned Customers
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                md={3}
                                xs={3}
                                style={{
                                  textAlign: "end",
                                  alignSelf: "center",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  style={{ fontWeight: "600" }}
                                >
                                  ${sp.total_sales ?? "0.00"}
                                </Typography>
                                <Typography
                                  variant="p"
                                  style={{ color: "#A1A5B7" }}
                                >
                                  Earning
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })
                      : ""}
                  </TabPanel>
                </Item>
              </TabContext>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
