import axios from "axios";
import React, { useContext, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as action_types from "../../../../actions/admin/actionTypes";
import * as action from "../../../../actions/admin/action";
import { useSnackbar } from "notistack";

export const AxiosContext = React.createContext(null);

export const useCustomAxios = () => {
  return useContext(AxiosContext);
};

let requestCount = 0;

function createAxiosInstance(auth, dispatch, enqueueSnackbar) {
  let AxiosApi = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN,
    timeout: 0,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;multipart/form-data; charset=UTF-8",
      ...(auth && {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }),
    },
  });

  AxiosApi.interceptors.request.use(
    function(config) {
      requestCount++;

      if (requestCount > 0) {
        dispatch(action.isLoading(action_types.isLoadingAction, false));
      }

      return config;
    },
    function(error) {
      // Do something with request error
      requestCount--;

      if (requestCount > 0) {
        dispatch(action.isLoading(action_types.isLoadingAction, false));
      }

      return Promise.reject(error);
    }
  );

  AxiosApi.interceptors.response.use(
    function(response) {
      requestCount--;

      if (requestCount === 0) {
        dispatch(action.isLoading(action_types.isLoadingAction, false));
      }

      return response;
    },
    function(error) {
      requestCount--;

      if (requestCount === 0) {
        dispatch(action.isLoading(action_types.isLoadingAction, false));
      }

      if (401 === error.response.status) {
        dispatch(
          action.userSession(
            action_types.userSession,
            error.response,
            enqueueSnackbar
          )
        );
      } else if (500 === error.response.status) {
        dispatch(
          action.userSession(
            action_types.userSession,
            error.response,
            enqueueSnackbar
          )
        );
      } else {
        return Promise.reject(error);
      }
    }
  );
  return AxiosApi;
}

function AxiosComponent(props) {
  
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const auth = useSelector((state) => state.LoginReducer.isLoggedIn);
  const AxiosApi = useMemo(() => {
    return createAxiosInstance(auth, dispatch, enqueueSnackbar);
  }, [auth, enqueueSnackbar, dispatch]);

  return (
    <AxiosContext.Provider value={AxiosApi}>
      {props.children}
    </AxiosContext.Provider>
  );
}

export default React.memo(AxiosComponent);
