import React from "react";
import DataTable from "react-data-table-component";
import customStyles from "./dt-main-style";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip, Chip } from "@mui/material";

export default function DataTable2(props) {

    const status = {
        A: { class: "success" },
        I: { class: "error" },
    };

    const columns = [
        {
            name: "Actions",
            sortable: false,
            selector: row => row.name,
            center: true,
            maxWidth: "100px",
            cell: (row) => (
                <>
                    <Link
                        to={{
                            pathname: "/admin/product/update",
                        }}
                        state={{ 
                            prd_master_id: row.id 
                        }}
                    >
                        <Tooltip title="Update Campaign / Product">
                            <IconButton color="primary">
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Link>
                </>
            ),
        },
        {
            name: "#",
            sortable: true,
            grow: 0,
            cell: function (data, index) {
                return index + 1 + parseFloat(props.startFrom);
            },
        },
        {
            name: "Code",
            selector: row => row.code,
            sortable: true,
            maxWidth: "200px",
        },
        {
            name: "Name",
            selector: row => row.name,
            sortable: true,
            minWidth: "200px",
        },
        {
            name: "Price",
            selector: row => row.unit_price,
            sortable: true,
            right: true,
            maxWidth: "150px",
        },
        {
            name: "Payment Type",
            selector: row => row.payment_type,
            sortable: true,
            maxWidth: "150px",
        },
        {
            name: "Status",
            selector: row => row.status,
            sortable: true,
            center: true,
            maxWidth: "120px",
            cell: (row) => (
                <Chip color={status[row["status"]].class} label={row["status_desc"]}></Chip>
            ),
        },
        {
            name: "Created On",
            selector: row => row.created_at,
            sortable: true,
            maxWidth: "180px",
        },
        {
            name: "Updated On",
            selector: row => row.updated_at,
            sortable: true,
            maxWidth: "180px",
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={props.fileteredData}
            customStyles={customStyles}
            noHeader
            pagination
            paginationServer
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50, 100, 150]}
            paginationTotalRows={props.totalRows}
            onChangeRowsPerPage={props.onChangeRowsPerPage}
            onChangePage={props.onChangePage}
            progressPending={props.pending}
        />
    );
}
