import React from "react";
import DataTable from "react-data-table-component";
import customStyles from "./dt-main-style";
import { IconButton, Tooltip, Chip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export default function DataTable2(props) {
	const status = {
		A: { class: "success" },
		I: { class: "error" },
		R: { class: "primary" },
	};

	const columns = [
		{
			name: "Actions",
			sortable: false,
			selector: row => row.name,
			center: true,
			maxWidth: "100px",
			cell: (row) => (
				<>
					{row.status == 'A' ?
						(<Tooltip title="Update Status to Refund">
							<IconButton color="primary" onClick={() => props.handleRefund(row.id)}>
								<AttachMoneyIcon fontSize="small" />
							</IconButton>
						</Tooltip>)
						: ''}
					<Tooltip title="Update Details">
						<IconButton color="primary" onClick={() => props.handleShowUpdatePaymentType(true, row.id, '', row.payment_type_id, row.ads_sales)}>
							<EditIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</>
			),
		},
		{
			name: "No.",
			selector: row => row.id,
			sortable: true,
			grow: 0,
			minWidth: "70px",
			cell: function (data, index) {
				return index + 1 + parseFloat(props.startFrom);
			},
		},
		{
			name: "Invoice Date",
			selector: row => row.raw_doc_date,
			sortable: true,
			sortField: 'raw_doc_date',
			width: "120px",
			cell: function (data, index) {
				return data.doc_date;
			},
		},
		{
			name: "Invoice No",
			selector: row => row.doc_no,
			sortable: true,
			width: "120px",
		},
        {
			name: "Installment No",
			selector: row => row.installment_no,
			sortable: true,
			width: "140px",
		},
		{
			name: "Product/Campaign",
			selector: row => row.ads_sales ? '(Ads Sales) ' + row.item_desc : row.item_desc,
			sortable: true,
			width: "250px",
            cell: function (data, index) {
				let prd = [];
				let num = 0;
				
				const usingArrayFrom = data.item_price_desc.split('<br>');
				usingArrayFrom.map(function(object, i) {
					num++;
					{prd.push(
						<div style={i > 0 ? {paddingTop: "5px"} : {paddingTop: "0px"}}>{data.ads_sales ? '(Ads Sales) ' + object : object}</div>
					)}
				});
				return (
					<span>{prd}</span>
				);
			},
		},
		{
			name: "Nett Amount",
			selector: row => parseFloat(row.total_amount),
			sortable: true,
			width: "120px",
			cell: function (data, index) {
				return data.total_amount;
			},
		},
        {
			name: "Discount Amount",
			selector: row => parseFloat(row.total_disc),
			sortable: true,
			width: "150px",
			cell: function (data, index) {
				return data.total_disc;
			},
		},
		{
			name: "Payment Type",
			selector: row => row.payment_type,
			sortable: true,
			width: "130px",
		},
		{
			name: "Payment Ref No",
			selector: row => row.ref_no,
			sortable: true,
			width: "260px",
		},
		{
			name: "Status",
			selector: row => row.status,
			sortable: true,
			width: "130px",
			cell: (row) => (
				<Chip color={status[row["status"]].class} label={row["status_desc"]}></Chip>
			),
		},
		{
			name: "Created Date",
			selector: row => row.created_at,
			sortable: true,
			width: "160px",
		},
	];

	return (
		<DataTable
			columns={columns}
			data={props.fileteredData}
			customStyles={customStyles}
			noHeader
			pagination
			paginationServer
			paginationPerPage={50}
			paginationRowsPerPageOptions={[50, 100, 150]}
			paginationTotalRows={props.totalRows}
			onChangeRowsPerPage={props.onChangeRowsPerPage}
			onChangePage={props.onChangePage}
			progressPending={props.pending}
		/>
	);
}
