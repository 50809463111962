import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { ErrorMsgCss } from "../../css/Admin-css/listing-css";
//import AdapterDateFns from "@mui/lab/AdapterDateFns";
//import LocalizationProvider from "@mui/lab/LocalizationProvider";
//import DatePicker from "@mui/lab/DatePicker";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from "@mui/material/TextField";

export default function DatePickerComponent(props) {

	return (
		<>
		<Controller
			rules={props.rules}
			control={props.control}
			defaultValue=""
			label={props.label}
			name={props.name}
			render={({ field }) => (
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DatePicker
						label={props.label}
						name={props.name}
						value={props.value}
						inputFormat="dd/MM/yyyy"
						onChange={props.onChange}
						renderInput={(startProps) => (
							<>
								<TextField
									{...startProps}
									variant="outlined"
									margin={props.marginType}
									error={props.errors[props.name] ? true : false}
									disabled={props.disabled ? true : false}
									fullWidth
								/>
							</>
						)}
					/>
				</LocalizationProvider>
			)}
		/>
		{props.errors[props.name] && (
			<ErrorMsgCss>
			  <span>{props.errors[props.name].message}</span>
			</ErrorMsgCss>
		)}
		</>
	);
}
