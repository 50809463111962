const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "rgba(0,0,0,.12)",
        background: '#f3f6f9'
      },
    },
    headCells: {
      style: {
        "&:first-of-type":{
          borderLeft: "1px solid rgba(0,0,0,.12)",
        },
        borderRight: "1px solid rgba(0,0,0,.12)"
      },
    },
    cells: {
      style: {
        "&:first-of-type":{
          borderLeft: "1px solid rgba(0,0,0,.12)",
        },
        borderRight: "1px solid rgba(0,0,0,.12)"
      },
    },
};

export default customStyles;