import React, { useContext, useState, useEffect } from "react";
import { Card, CardContent, useTheme } from "@mui/material";
import { AppBarSpacer } from "../../../css/Admin-css/listing-css";
import TranslationListDatatable from "../DataTable/dt-translationList";
import { postEditForm } from "../../../actions/admin/actionCreators";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useForm } from "react-hook-form";
import SearchField from "../DataTable/SearchField/SearchField";
import { useSnackbar } from "notistack";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
    getLanguage,
    getTranslationSearchOptions,
    fetchTranslationList,
    handleDataTablePerRowsChange,
} from "../../../actions/admin/actionCreators";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";

export default function TranslationList() {
    const api = useContext(AxiosContext);
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const MySwal = withReactContent(Swal);
    const [data, setData] = useState([]);
    const [groupData, setGroup] = useState([]);
    const [typeData, setType] = useState([]);
    const [languageData, setLanguage] = useState([]);
    const [languageNames, setLanguageNames] = useState([]);
    const [transData, setTransData] = useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [startFrom, setStart] = useState(0);
    const [pending, setPending] = useState(true);
    const [breadcrumbArr, setBreadcrumbArr] = React.useState([
        {
            text: "Translation",
            link: "",
        },
    ]);

    const searchField = [
        {
            name: "group",
            label: "Group",
            placeholder: "Group",
            marginType: "normal",
            type: "select",
            children: groupData,
        },
        {
            name: "type",
            label: "Type",
            placeholder: "Type",
            marginType: "normal",
            type: "select",
            children: typeData,
        },
        {
            name: "locale",
            label: "Language",
            placeholder: "Language",
            marginType: "normal",
            type: "select",
            children: languageData,
        },
        {
            name: "global_search",
            label: "Name / Value",
            placeholder: "Name / Value",
            marginType: "normal",
            type: "text",
            helperText: "Column 'Name' will be searched as default. \n When language is selected, column 'Value' will be searched."
        },
    ];

    const { handleSubmit, control, reset, getValues } = useForm({
        defaultValues: {
            group: "",
            type: "",
            locale: "",
            global_search: "",
        },
    });

    const onSubmit = handleSubmit(async (param) => {
        param["page"] = startFrom;
        param["per_page"] = perPage;

        const response = await fetchTranslationList({
            api: api,
            data: param,
        });

        if (response) {
            if (response.status === 200) {
                setData(response.data.data);
                setTotalRows(response.data.total_records);
            }
        }
    });

    const onReset = () => {
        reset();
        onSubmit();
    };

    const getLanguageData = async () => {
        const languageList = [];
        const languageNameList = [];
        const response = await getLanguage({
            api: api,
        });
        if (response) {
            if (response.status == 200) {
                response.data.map((val) => {
                    languageList.push({
                        value: val.locale,
                        desc: val.name,
                    });
                    languageNameList[val.locale] = val.name;
                });
            }
        }
        setLanguage(languageList);
        setLanguageNames(languageNameList);
    };

    const getTranslationSearchData = async () => {
        const groupList = [];
        const typeList = [];
        const response = await getTranslationSearchOptions({
            api: api,
        });
        if (response) {
            if (response.status == 200) {
                response.data.group.map((val) => {
                    groupList.push({
                        value: val.group,
                        desc: val.group,
                    });
                });
                response.data.type.map((val) => {
                    typeList.push({
                        value: val.type,
                        desc: val.type,
                    });
                });
            }
        }
        setGroup(groupList);
        setType(typeList);
    };

    const getTranslationListData = async () => {
        setPending(true);

        const param = {};
        param["page"] = startFrom;
        param["per_page"] = perPage;
        param["group"] = getValues("group");
        param["type"] = getValues("type");
        param["locale"] = getValues("locale");
        param["global_search"] = getValues("global_search");

        const response = await fetchTranslationList({
            api: api,
            data: param,
        });

        if (response) {
            if (response.status === 200) {
                setPending(false);
                setData(response.data.data);
                setTotalRows(response.data.total_records);
            }
        }
    };

    const handlePageChange = async (page) => {
        const startPage = (page - 1) * perPage;
        setStart(startPage);
    };

    const handlePerRowsChange = async (newPerPage) => {
        const param = {};
        param["page"] = 0;
        param["per_page"] = newPerPage;
        param["group"] = getValues("group");
        param["type"] = getValues("type");
        param["locale"] = getValues("locale");
        param["global_search"] = getValues("global_search");

        const response = await handleDataTablePerRowsChange({
            api: api,
            url: "getTranslationList?",
            data: param,
        });

        setData(response.data.data);
        setPerPage(newPerPage);
    };

    const handleTransChange = (value, locale, key, id) => {
        const transDataList = transData;
        transDataList[key] = transDataList[key] ?? {};
        transDataList[key][locale] = transDataList[key][locale] ?? {};
        transDataList[key][locale]["id"] = id;
        transDataList[key][locale]["data"] = value;
        transDataList[key][locale]["key"] = key;
        setTransData(transDataList);
    };

    const handleTransSave = async (key) => {
        if (transData[key]) {
            document.getElementById("splash-screen").classList.remove("hidden");

            const response = await postEditForm({
                api: api,
                data: {
                    dataset: transData[key],
                },
                method: "POST",
                url: "updateTranslation",
                enqueueSnackbar,
            });

            if (response) {
                if (response.status === 200) {
                    enqueueSnackbar(response.data.msg, {
                        variant: response.data.msgType,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                    });
                }
            }
            document.getElementById("splash-screen").classList.add("hidden");
        } else {
            enqueueSnackbar("Successfully update translation", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            });
        }
    };

    const handleTransDelete = async (group, name) => {
        await MySwal.fire({
            title: <strong>Are you sure?</strong>,
            html: <label>You won't be able to revert this!</label>,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: theme.palette.primary.main,
            cancelButtonColor: theme.palette.danger.main,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'confirm-button-class',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                document.getElementById("splash-screen").classList.remove("hidden");

                const response = await postEditForm({
                    api: api,
                    data: {
                        group: group,
                        name: name,
                    },
                    method: "POST",
                    url: "deleteTranslation",
                    enqueueSnackbar,
                });

                if (response) {
                    if (response.status === 200) {
                        enqueueSnackbar(response.data.msg, {
                            variant: response.data.msgType,
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                            },
                        });
                        getTranslationListData();
                    }
                }
                document.getElementById("splash-screen").classList.add("hidden");
            }
        });
    };

    useEffect(() => {
        getLanguageData();
        getTranslationSearchData();
        getTranslationListData();
    }, [startFrom]);

    return (
        <>
            <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]} >
                <CollapsedBreadcrumbs />
            </BreadcrumbsContext.Provider>
            <div className="row">
                <div className="col-md-12">
                    <SearchField
                        control={control}
                        searchField={searchField}
                        onSubmit={onSubmit}
                        onReset={onReset}
                    />

                    <AppBarSpacer />

                    <Card>
                        <CardContent>
                            <TranslationListDatatable
                                totalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                fileteredData={data}
                                startFrom={startFrom}
                                pending={pending}
                                languageNames={languageNames}
                                handleTransChange={handleTransChange}
                                handleTransSave={handleTransSave}
                                handleTransDelete={handleTransDelete}
                            ></TranslationListDatatable>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </>

    );
}
