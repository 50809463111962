import React, { useContext, useState, useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { AppBarSpacer } from "../../../css/Admin-css/listing-css";
import EcommerceOrderListDatatable from "../DataTable/dt-ecommerceOrderList";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useForm } from "react-hook-form";
import SearchField from "../DataTable/SearchField/SearchField";

import {
    fetchEcommerceOrderList2,
    handleDataTablePerRowsChange,
    getSysGeneralData,
    fetchSalesPersonList,
} from "../../../actions/admin/actionCreators";
import CollapsedBreadcrumbs, {
    BreadcrumbsContext,
} from "../../../layouts/admin/breadcrumb";

export default function EcommerceOrderList() {
    const api = useContext(AxiosContext);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [startFrom, setStart] = useState(0);
    const [pending, setPending] = useState(true);
    const [exportData, setExportData] = useState([]);
    const [status, setStatus] = useState([]);
    const [deliveryStatus, setDeliveryStatus] = useState([]);
    const [salesPerson, setSalesPerson] = useState([]);
    const [breadcrumbArr, setBreadcrumbArr] = React.useState([
        {
            text: "Ecommerce Orders",
            link: "",
        },
    ]);

    const searchField = [
        {
            name: "doc_date_start",
            label: "Doc Date From",
            placeholder: "Select Date",
            marginType: "normal",
            type: "date_picker",
        },
        {
            name: "doc_date_end",
            label: "Doc Date To",
            placeholder: "Select Date",
            marginType: "normal",
            type: "date_picker",
        },
        {
            name: "doc_no",
            label: "Doc No.",
            placeholder: "Search Doc No.",
            marginType: "normal",
            type: "text",
        },
        {
            name: "member_info",
            label: "Member Info",
            placeholder: "Search Member Name",
            marginType: "normal",
            type: "text",
        },
        {
            name: "status",
            label: "Status",
            placeholder: "Select Status",
            marginType: "normal",
            type: "select",
            children: status,
        },
        {
            name: "delivery_status",
            label: "Delivery Status",
            placeholder: "Select Delivery Status",
            marginType: "normal",
            type: "select",
            children: deliveryStatus,
        },
        {
            name: "payment_ref_no",
            label: "Ref No.",
            placeholder: "Search Ref No.",
            marginType: "normal",
            type: "text",
        },
        {
            name: "product_info",
            label: "Product Info",
            placeholder: "Search Product Code/Name",
            marginType: "normal",
            type: "text",
        },
        {
            name: "company_id",
            label: "Sales Person",
            placeholder: "Sales Person",
            marginType: "normal",
            type: "select",
            children: salesPerson,
        },
    ];

    const { handleSubmit, control, reset, getValues, setValue } = useForm({
        defaultValues: {
            doc_date_start: null,
            doc_date_end: null,
            doc_no: "",
            member_info: "",
            status: "",
            delivery_status: "",
            payment_ref_no: "",
            product_info: "",
            company_id: "",
        },
    });

    const getOrderListData = async () => {
        setPending(true);

        const param = {};
        param["page"] = startFrom;
        param["per_page"] = perPage;
        param["doc_date_start"] = getValues("doc_date_start")
            ? convertUTCDateToLocalDate(getValues("doc_date_start"))
            : null;
        param["doc_date_end"] = getValues("doc_date_end")
            ? convertUTCDateToLocalDate(getValues("doc_date_end"))
            : null;
        param["doc_no"] = getValues("doc_no");
        param["member_info"] = getValues("member_info");
        param["status"] = getValues("status");
        param["delivery_status"] = getValues("delivery_status");
        param["payment_ref_no"] = getValues("payment_ref_no");
        param["product_info"] = getValues("product_info");
        param["company_id"] = getValues("company_id");

        const response = await fetchEcommerceOrderList2({
            api: api,
            data: param,
        });

        if (response) {
            if (response.status == 200) {
                setPending(false);
                if (response.data.total_records > 0) {
                    setData(response.data.data);
                    setTotalRows(response.data.total_records);
                }
            }
        }
    };

    const getOrderListExportData = async () => {
        const param = {};
        param["doc_date_start"] = getValues("doc_date_start")
            ? convertUTCDateToLocalDate(getValues("doc_date_start"))
            : null;
        param["doc_date_end"] = getValues("doc_date_end")
            ? convertUTCDateToLocalDate(getValues("doc_date_end"))
            : null;
        param["doc_no"] = getValues("doc_no");
        param["member_info"] = getValues("member_info");
        param["status"] = getValues("status");
        param["delivery_status"] = getValues("delivery_status");
        param["payment_ref_no"] = getValues("payment_ref_no");
        param["product_info"] = getValues("product_info");
        param["company_id"] = getValues("company_id");

        const response = await fetchEcommerceOrderList2({
            api: api,
            data: param,
        });

        if (response) {
            if (response.status == 200) {
                const result = response.data.data;
                setExportData(
                    result && result.length > 0
                        ? result.map((x, index) => ({
                            "No.": index + 1,
                            "Doc Date": x.doc_date,
                            "Doc No.": x.doc_no,
                            "Member Name": x.name,
                            "Member Email": x.email,
                            "Product": x.items.replaceAll(")||", "), ").replaceAll("||", " - "),
                            "Status": x.status_desc,
                            "Delivery Status": x.delivery_status_desc,
                            "Total Amount": x.total_amount,
                            "Payment Type": x.payment_methods,
                            "Paid Amount": x.payment_amounts,
                            "Ref No.": x.payment_ref_nos,
                            "Sales Person": x.sales_person_name,
                            "Created Date": x.created_at,
                        }))
                        : []
                );
            }
        }
    };

    const getStatusData = async () => {
        const statusList = [];
        const response = await getSysGeneralData({
            api: api,
            type: "ecom-order-status",
        });
        if (response) {
            if (response.status == 200) {
                response.data.data.map((val) => {
                    statusList.push({
                        value: val.code,
                        desc: val.name,
                    });
                });
            }
        }
        setStatus(statusList);
    };

    const getDeliveryStatusData = async () => {
        const deliveryStatusList = [];
        const response = await getSysGeneralData({
            api: api,
            type: "ecom-delivery-status",
        });
        if (response) {
            if (response.status == 200) {
                response.data.data.map((val) => {
                    deliveryStatusList.push({
                        value: val.code,
                        desc: val.name,
                    });
                });
            }
        }
        setDeliveryStatus(deliveryStatusList);
    };

    const getSalesPersonData = async () => {
        const salesPersonList = [];
        const param = {};
        param["status"] = "A";
        const response = await fetchSalesPersonList({
          api: api,
          data: param,
        });
        if (response) {
          if (response.status == 200) {
            response.data.data.map((val) => {
              salesPersonList.push({
                value: val.id,
                desc: val.name,
              });
            });
          }
        }
        setSalesPerson(salesPersonList);
      };

    function convertUTCDateToLocalDate(date) {
        var formatDate = date.getFullYear() + "-" + parseInt(date.getMonth() + 1) + "-" + (date.getDate() < 10 ? '0' : '') + date.getDate();
        return formatDate;
    }

    const onSubmit = handleSubmit(async (param) => {
        param["doc_date_start"] = param["doc_date_start"]
            ? convertUTCDateToLocalDate(param["doc_date_start"])
            : null;
        param["doc_date_end"] = param["doc_date_end"]
            ? convertUTCDateToLocalDate(param["doc_date_end"])
            : null;
        param["page"] = startFrom;
        param["per_page"] = perPage;

        const response = await fetchEcommerceOrderList2({
            api: api,
            data: param,
        });

        setData(response.data.data);
        setTotalRows(response.data.total_records);
        getOrderListExportData();
    });

    const onReset = () => {
        reset();
        onSubmit();
    };

    const handlePageChange = async (page) => {
        const startPage = (page - 1) * perPage;
        setStart(startPage);
    };

    const handlePerRowsChange = async (newPerPage) => {
        const param = {};

        param["page"] = 0;
        param["per_page"] = newPerPage;
        param["member_info"] = getValues("member_info");

        const response = await handleDataTablePerRowsChange({
            api: api,
            url: "get-ecommerce-sales-list",
            data: param,
        });

        setData(response.data.data);
        setPerPage(newPerPage);
    };

    useEffect(() => {
        getOrderListData();
        getOrderListExportData();
        getStatusData();
        getDeliveryStatusData();
        getSalesPersonData();
    }, [startFrom]);

    return (
        <>
            <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]}>
                <CollapsedBreadcrumbs />
            </BreadcrumbsContext.Provider>
            <div className="row">
                <div className="col-md-12">
                    {/* <FormRow /> */}
                    <SearchField
                        control={control}
                        searchField={searchField}
                        onSubmit={onSubmit}
                        onReset={onReset}
                        getValues={getValues}
                        setValue={setValue}
                        exportData={exportData}
                        exportButton={true}
                        onExportFileName={"ecommerce_orders_listing.csv"}
                    />

                    <AppBarSpacer />
                    <Card>
                        <CardContent>
                            <EcommerceOrderListDatatable
                                totalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                fileteredData={data}
                                startFrom={startFrom}
                                pending={pending}
                            ></EcommerceOrderListDatatable>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </>
    );
}
