import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default function RadarChart(props){
    useLayoutEffect(() => {
        let root = am5.Root.new("chartdiv");
    
        root.setThemes([
          am5themes_Animated.new(root)
        ]);
        
        var chart = root.container.children.push(am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "none",
        //   wheelY: "zoomX",
          innerRadius: am5.percent(20),
          startAngle: -90,
          endAngle: 180
        }));
        
        
        // Data
        var data = [{
          category: "Blacklisted",
          value: Object.keys(props.chartData).length > 0 ? props.chartData.B.percentage : 0,
          full: 100,
          columnSettings: {
            fill: '#1A1A27'
          }
        }, {
          category: "Attractive",
          value: Object.keys(props.chartData).length > 0 ? props.chartData.H.percentage : 0,
          full: 100,
          columnSettings: {
            fill: '#FF5A5A'
          }
        }, {
          category: "Potential",
          value: Object.keys(props.chartData).length > 0 ? props.chartData.W.percentage : 0,
          full: 100,
          columnSettings: {
            fill: '#5AB9FF'
          }
        }, {
          category: "New",
          value: Object.keys(props.chartData).length > 0 ? props.chartData.C.percentage : 0,
          full: 100,
          columnSettings: {
            fill: '#20C997'
          }
        }];
        
        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
        // var cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {
        //   behavior: "zoomX"
        // }));
        
        // cursor.lineY.set("visible", false);
        
        // Create axes and their renderers
        // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
        var xRenderer = am5radar.AxisRendererCircular.new(root, {
          //minGridDistance: 50
        });
        
        xRenderer.labels.template.setAll({
          radius: 10
        });
        
        xRenderer.grid.template.setAll({
          forceHidden: true
        });
        
        var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
          renderer: xRenderer,
          min: 0,
          max: 100,
          strictMinMax: true,
          numberFormat: "#'%'",
          tooltip: am5.Tooltip.new(root, {})
        }));
        
        
        var yRenderer = am5radar.AxisRendererRadial.new(root, {
          minGridDistance: 20
        });
        
        yRenderer.labels.template.setAll({
          centerX: am5.p100,
          fontWeight: "400",
          fontSize: 12,
          templateField: "columnSettings"
        });
        
        yRenderer.grid.template.setAll({
          forceHidden: true
        });
        
        var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
          categoryField: "category",
          renderer: yRenderer
        }));
        
        yAxis.data.setAll(data);
        
        
        // Create series
        // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
        var series1 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          clustered: false,
          valueXField: "full",
          categoryYField: "category",
          fill: root.interfaceColors.get("alternativeBackground")
        }));
        
        series1.columns.template.setAll({
          width: am5.p100,
          fillOpacity: 0.08,
          strokeOpacity: 0,
          cornerRadius: 20
        });
        
        series1.data.setAll(data);
        
        
        var series2 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          clustered: false,
          valueXField: "value",
          categoryYField: "category"
        }));
        
        series2.columns.template.setAll({
          width: am5.p100,
          strokeOpacity: 0,
          tooltipText: "{category}: {valueX}%",
          cornerRadius: 20,
          templateField: "columnSettings"
        });
        
        series2.data.setAll(data);
        
        // Animate chart and series in
        // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
        series1.appear(1000);
        series2.appear(1000);
        chart.appear(1000, 100);
  
        return () => {
          root.dispose();
        };
    }, []);
      
    return (
        <div id="chartdiv" style={{width: '100%', height: '300px', marginTop: '4rem'}}></div>
    );
}