import React, { useContext } from "react";
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import TranslateIcon from '@mui/icons-material/Translate';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AxiosContext } from "../../app/assets/axios/admin/AxiosComponent";
import { LogoutAction } from "../../actions/admin/actionCreators";

export default function HeaderComponent(props) {
    const dispatch = useDispatch();
    const api = useContext(AxiosContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dropopen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(LogoutAction({ api }));
    };

    const handleLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem("language", lang);
        handleClose();
    };

    const [t, i18n] = useTranslation('common');

    return(
        <Toolbar sx={{ justifyContent: 'space-between'}}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={props.action}
                edge="start"
                sx={{ mr: 2, ...(props.status && { display: 'none' }) }}
            >
                <MenuIcon />
            </IconButton>
            <div></div>
            <Box>
                <Button
                    id="demo-customized-button"
                    aria-controls={dropopen ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={dropopen ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    color="white"
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    <TranslateIcon />
                </Button>
                <Menu
                    elevation={0}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    id="demo-customized-menu"
                    MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={dropopen}
                    onClose={handleClose}
                    xs={{boxShadow:
                        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'}}
                >
                    <MenuItem onClick={() => handleLanguage('en')} disableRipple>
                        English
                    </MenuItem>
                    <MenuItem onClick={() => handleLanguage('zh')} disableRipple>
                        中文
                    </MenuItem>
                    <MenuItem onClick={() => handleLanguage('ms')} disableRipple>
                        Malay
                    </MenuItem>
                </Menu>
                <IconButton
                    //color="inherit"
                    edge="end"
                    sx={{ alignContent: 'center', color: '#AD8E64'}}
                    onClick={handleLogout}
                >
                    <LogoutIcon/>
                </IconButton>
            </Box>
        </Toolbar>
    );
}