import * as action_types from "../../../../../actions/salesPerson/actionTypes";

const initialLoginState = {
    token_expired: false,
};

export default function errorReducer(state = initialLoginState, action) {
    switch (action.type) {
        case action_types.userSession:
            return userSession(state, action.payload, action.enqueueSnackbar);
        case action_types.userSessionAuthenticate:
            return userSessionAuthenticate(state);
        default:
            return state;
    }
}

const userSessionAuthenticate = (state) => {
    state = {
        token_expired: false,
    };
    return state;
};

const userSession = (state, payload, enqueueSnackbar) => {
    if (payload) {
        if (payload.data.status === 401) {
            localStorage.removeItem("sales_person_token");
            localStorage.removeItem("sales_person_name");
            localStorage.removeItem("sales_person_nick_name");
            return {
                token_expired: true,
            };
        } else {
            enqueueSnackbar(payload.statusText, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
            return state;
        }
    }
};
