import React, { useContext, useState, useEffect } from "react";

import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";
import { Card, CardHeader, CardContent, Typography, Button, Grid } from "@mui/material";
import { AppBarSpacer, CardHeaderCss } from "../../../css/Admin-css/listing-css";
import "../../../css/Admin-css/tree-style.css";
import Tree from 'rc-tree';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { getUserGroupTree, getUserGroupMenuData, updateUserGroupMenu } from "../../../actions/admin/actionCreators";

export default function UserGroup() {
  const api = useContext(AxiosContext);
  const { enqueueSnackbar } = useSnackbar();
  const treeRef = React.createRef();
  const [treeData, setTreeData] = useState([]);
  const [treeMenuData, setTreeMenuData] = useState([]);
  const [selectedNode, setSelectedNode] = useState('');
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([1]);
  const [expandedMenuKeys, setExpandedMenuKeys] = useState([1]);
  const [parentArray, setParentArray] = useState([]);
  const navigate = useNavigate();
  const [breadcrumbArr, setBreadcrumbArr] = React.useState([
    {
      text: "Admin",
      link: "/admin/user-list",
    },
    {
      text: "User Group Menu",
      link: "/admin/user-group",
    },
  ]);

  const getUserGroupData = async () => {
    const response = await getUserGroupTree({
      api: api,
      data: [],
    });

    if (response) {
      if (response.status == 200) {
        setTreeData(buildTree(response.data, 0, 'user'));
        setExpandedKeys([1]);
      }
    }
  }

  const getUserMenuData  = async (selectedNode) => {
    const response = await getUserGroupMenuData({
      api: api, 
      data: {
        sys_group_id: selectedNode
      }
    });

    if (response) {
      if (response.status == 200) {
        setTreeMenuData(await buildTree(response.data, 0, 'menu'));
        setExpandedMenuKeys([1]);
      }
    }
  }

  const postUserGroupMenu = async () => {
    const response = await updateUserGroupMenu({
      api: api, 
      data: {
        sys_group_id: selectedNode,
        menus: checkedKeys,
      },
      enqueueSnackbar,
    });

    if (response) {
        enqueueSnackbar(response.msg, {
          variant: response.msgType,
          anchorOrigin: {
              vertical: "top",
              horizontal: "center",
          },
      });
    }
  }

  function buildTree(data, parentId, type) {
    const tree = [];
    const checked_keys_array = [];
    const parent_node = [];

    data.map((item, index) => {
      if (type == 'menu') {
        parent_node[item.id] = item.parent_id;
      }
      
      if (item.parent_id === parentId || (parentId == 0 && index == 0)) {
        item.key = item.id;
        item.title = <div style={{display:'flex', alignItems:'center'}}><Typography>{item.name}</Typography> <Typography style={{fontSize: 12}}>({item.seq_no})</Typography></div>;

        const children = buildTree(data, item.id, type);
        if (children.length) {
          item.children = children;
        }
        tree.push(item);
      }
      if (item.selected === 1) {
        checked_keys_array.push(item.key);
      }
      
    });
    
    setParentArray(parent_node);
    setCheckedKeys(checked_keys_array);

    return tree;
  } 

  function checkChild(newCheckedKeys, checkedId, checked) {
    parentArray.map((parentId, childId) => {
      if (parentId == checkedId) {
        if (checked) {
          // check child
          if(!newCheckedKeys.includes(childId)){
            newCheckedKeys.push(childId);
            newCheckedKeys = checkChild(newCheckedKeys, childId, checked);
          }
        }else{
          // uncheck child
          var childIndex = newCheckedKeys.indexOf(childId)
          if (childIndex !== -1) {
            newCheckedKeys.splice(childIndex, 1);
            newCheckedKeys = checkChild(newCheckedKeys, childId, checked);
          }
        }
      }
    });
    return newCheckedKeys;
  } 

  const  onSelect = async info => {
    await getUserMenuData(info[0]);
    setSelectedNode(info[0]);
  };

  const onCheck = (checkedKeys, checkedNodes) => {
    let checkedId = checkedNodes.node.id
    let newCheckedKeys = checkedKeys.checked;

    // parents
    let parentId = parentArray[checkedId];
    if (!checkedNodes.node.checked) { //checked
      // check parent if not checked
      while (parentId != 0 && !newCheckedKeys.includes(parentId)){
        newCheckedKeys.push(parentId);
        parentId = parentArray[parentId];
      }
    }

    // children
    newCheckedKeys = checkChild(newCheckedKeys, checkedId, !checkedNodes.node.checked);
    
    setCheckedKeys(newCheckedKeys);
  };

  const onExpand = expandedKeys => {
    setExpandedKeys(expandedKeys);
  };

  const onExpandMenu = expandedKeys => {
    setExpandedMenuKeys(expandedKeys);
  };

  const onSubmit = () => {
    postUserGroupMenu();
  }

  useEffect(()=> {
    getUserGroupData();
  },[]);

  return (
    <>
      <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]}>
        <CollapsedBreadcrumbs />
      </BreadcrumbsContext.Provider>
      <Card>
        <CardHeaderCss title="User Group Menu" />
        <CardContent>
          <Grid container>
            <Grid item md={6} xs={12} >
            <Typography variant="h6">User Group List</Typography>
            <Tree
              ref={treeRef}
              className="myCls"
              onExpand={onExpand}
              defaultExpandAll
              expandedKeys={expandedKeys}
              treeData={treeData}
              onSelect={onSelect}
              showLine
              showIcon={false}
            />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h6">Menu List</Typography>
              {treeMenuData ? <Tree
                ref={treeRef}
                className="myCls"
                onExpand={onExpandMenu}
                defaultExpandAll
                expandedKeys={expandedMenuKeys}
                showLine
                treeData={treeMenuData}
                onCheck={onCheck}
                checkable
                checkedKeys={checkedKeys}
                checkStrictly
                // checkedKeys={[1,2]}
              /> : ''}
              <div style={{marginTop: '1rem'}}>
                <Button variant="contained" color="primary" onClick={onSubmit}>Update</Button>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
