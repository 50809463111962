import { Controller } from "react-hook-form";
import React, { useContext, useEffect, useState, memo, useRef } from "react";
import { Autocomplete } from "@mui/material";
import { ErrorMsgCss } from "../../css/Admin-css/listing-css";

export default function AutocompleteComponent(props) {
  console.log(props);
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Autocomplete
        id={props.id}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={props.isOptionEqualToValue}
        getOptionLabel={props.getOptionLabel}
        options={props.options}
        loading={props.loading}
        onChange={props.onChange}
        renderOption={props.renderOption}
        renderInput={props.renderInput}
      />
    </>
  );
}
