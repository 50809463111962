import React, { useContext, useState, useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { AppBarSpacer } from "../../../css/Admin-css/listing-css";
import ProductListDatatable from "../DataTable/dt-productList";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import { useForm } from "react-hook-form";
import SearchField from "../DataTable/SearchField/SearchField";
import { useDispatch } from "react-redux";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";
import {
    fetchProductList,
    handleDataTablePerRowsChange,
} from "../../../actions/admin/actionCreators";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export default function ProductList() {
    // const classes = SalesListStyles();
    const api = useContext(AxiosContext);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [startFrom, setStart] = useState(0);
    const [pending, setPending] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [breadcrumbArr, setBreadcrumbArr] = React.useState([
		{
			text: "Campaign / Product",
			link: "/admin/product-list",
		},
	]);
    const { t } = useTranslation('common');

    const searchField = [
        {
            name: "code",
            label: "Code",
            placeholder: "Code",
            marginType: "normal",
            type: "text",
        },
        {
            name: "name",
            label: "Name",
            placeholder: "Name",
            marginType: "normal",
            type: "text",
        },
    ];

    const { handleSubmit, control, reset, getValues } = useForm({
        defaultValues: {
            code: "",
            name: "",
        },
    });

    const onSubmit = handleSubmit(async (param) => {
        param["page"]     = startFrom;
        param["per_page"] = perPage;

        const response = await fetchProductList({
            api: api,
            data: param,
        });

        if (response) {
            if (response.status === 200) {
                if (response.data.total_records > 0) {
                    setData(response.data.data);
                    setTotalRows(response.data.total_records);
                }
            }
        }
    });

    const onReset = () => {
        reset();
        onSubmit();
    };

    const getProductListData = async () => {
        setPending(true);

        const param = {};
        param["page"]     = startFrom;
        param["per_page"] = perPage;
        param["code"]     = getValues("code");
        param["name"]     = getValues("name");

        const response = await fetchProductList({
            api: api,
            data: param,
            dispatch,
            enqueueSnackbar,
        });

        if (response) {
            if (response.status === 200) {
                setPending(false);
                if (response.data.total_records > 0) {
                    setData(response.data.data);
                    setTotalRows(response.data.total_records);
                }
            }
        }
    };

    const handlePageChange = async (page) => {
        const startPage = (page - 1) * perPage;
        setStart(startPage);
    };

    const handlePerRowsChange = async (newPerPage) => {
        const param = {};
        param["page"]     = 0;
        param["per_page"] = newPerPage;
        param["code"]     = getValues("code");
        param["name"]     = getValues("name");

        const response = await handleDataTablePerRowsChange({
            api: api,
            url: "getProductList?",
            data: param,
        });

        setData(response.data.data);
        setPerPage(newPerPage);
    };

    useEffect(() => {
        getProductListData();
    }, [startFrom]);

    return (
        <>
        <BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]} >
			<CollapsedBreadcrumbs />
		</BreadcrumbsContext.Provider>
        <div className="row">
            <div className="col-md-12">
                <SearchField
                    control={control}
                    searchField={searchField}
                    onSubmit={onSubmit}
                    onReset={onReset}
                    onCreate="/admin/product/create"
                />
                <AppBarSpacer/>
                <Card>
                    <CardContent>
                        <ProductListDatatable
                            totalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fileteredData={data}
                            startFrom={startFrom}
                            pending={pending}
                        ></ProductListDatatable>
                    </CardContent>
                </Card>
            </div>
        </div>
        </>
    );
}
