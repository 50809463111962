import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Alert, Card, CardContent, CardActions, Box, Grid, Button } from "@mui/material";
import { AppBarSpacer } from "../../../css/Admin-css/listing-css";
import TextFieldComponent from "../../FormAddInputComponent/TextField";
import SelectOptionComponent from "../../FormAddInputComponent/SelectOption";
import { fetchSalesPersonList, fetchCustomerList, postCreateForm } from "../../../actions/admin/actionCreators";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { AxiosContext } from "../../../app/assets/axios/admin/AxiosComponent";
import CollapsedBreadcrumbs, { BreadcrumbsContext } from "../../../layouts/admin/breadcrumb";
import { CardHeaderCss } from "../../../css/Admin-css/listing-css";

export default function AssignSalesPerson() {
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const api = useContext(AxiosContext);
	const [state, setState] = useState({
		unAssignRecords: "",
		salesPerson: [],
		frmNumberObj: [],
		toNumberObj: [],
		salesPersonIdsObj: [],
		assignObj: [],
	});
	const [breadcrumbArr, setBreadcrumbArr] = React.useState([
		{
			text: "Customer",
			link: "/admin/customer-list",
		}, 
		{
			text: "Assign to Sales Person",
			link: "/admin/assign-salesperson",
		}, 
	]);
	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		shouldFocusError: true,
		reValidateMode: "onChange",
		defaultValues: {
			// first_name: "",
			// last_name: "",
			// email: "",
			// mobile: "",
		},
	});

	useEffect(() => {
		getCustomerListData();
		getSalesPersonData();
		assignSalesPersonRow();
	}, []);
	
	const getCustomerListData = async () => {
		const param = {};
		param["company_id"] = 0;
		
		const response = await fetchCustomerList({
			api: api,
			data: param,
		});

		if (response) {
			if (response.status == 200) {
				setState((prevState) => {
					return {
						...prevState,
						unAssignRecords: response.data.total_records,
					};
				});
			}
		}
	};
	
	const getSalesPersonData = async () => {
		const param = {};
		param["status"] = "A";
		const response = await fetchSalesPersonList({
			api: api,
			data: param,
		});
		setState((prevState) => {
			return {
				...prevState,
				salesPerson: response.data.data,
			};
		});
	};

	const assignSalesPersonRow = function () {
		let newAssignObj = state.assignObj;
		let assignRow = { frm_number: 0, to_number: 0, sales_person: 0 };
		newAssignObj.push(assignRow);
		setState((prevState) => {
			return {
				...prevState,
				assignObj: newAssignObj,
			};
		});

		// let newAssignObj = assignObj;
		// let frm_number = 0;
		// // set next frm_number if previous row existed
		// if (newAssignObj.length > 0) {
		// 	let lastIndex = newAssignObj.length - 1;
		// 	frm_number = newAssignObj[lastIndex].to_qty + 1;
		// } else {
		// 	frm_number = 1;
		// }
		// let assignRow = { frm_number: frm_number, to_number: 0, sales_person: 0 };
		// newAssignObj.push(assignRow);
		// setAssignObj(newAssignObj);
	}

	const handleAddNextAssignPerson = function (e) {
		assignSalesPersonRow();
	};

	const handleRedirectCustomerList = function (e) {
		navigate("/admin/customer-list");
	}

	const onChangedFrmNumber = (name, index) => (event) => {
		const value = event.target.value;

		if (name == 'frm_number') {
			let currentFrmNumber = state.frmNumberObj;

			if (currentFrmNumber[index]) {
				currentFrmNumber[index].value = value;
			} else {
				let frm_number = { value: value };
				currentFrmNumber[index] = frm_number;
				// let frm_number = [];
				// frm_number['value'] = value;
				// currentFrmNumber[index] = frm_number;
			}

			setState((prevState) => {
				return {
					...prevState,
					frmNumberObj: currentFrmNumber,
				};
			});
			
			setValue(`assign[${index}].frm_number`, value);
		}
	}
	
	const onChangedToNumber = (name, index) => (event) => {
		const value = event.target.value;

		if (name == 'to_number') {
			let currentToNumber = state.toNumberObj;

			if (currentToNumber[index]) {
				currentToNumber[index].value = value;
			} else {
				let to_number = { value: value };
				currentToNumber[index] = to_number;
				// let to_number = [];
				// to_number['value'] = value;
				// currentToNumber[index] = to_number;
			}

			setState((prevState) => {
				return {
					...prevState,
					toNumberObj: currentToNumber,
				};
			});
			
			setValue(`assign[${index}].to_number`, value);
		}
	}

	const onSelectChangeHandler = (name, index) => (event) => {
		const value = event.target.value;

		if (name == 'sales_person') {
			let currentSalesPersonIds = state.salesPersonIdsObj;

			if (currentSalesPersonIds[index]) {
				currentSalesPersonIds[index].value = value;
			} else {
				let sales_person_id = { value: value };
				currentSalesPersonIds[index] = sales_person_id;
				// let sales_person_id = [];
				// sales_person_id['value'] = value;
				// currentSalesPersonIds[index] = sales_person_id;
			}

			setState((prevState) => {
				return {
					...prevState,
					salesPersonIdsObj: currentSalesPersonIds,
				};
			});

			setValue(`assign[${index}].sales_person`, value);
		}
	};

	// const handleRemoveAssignRow = function (index) {
	const handleRemoveAssignRow = (index) => {
		let newToNumberObj       = state.toNumberObj;
		let newFrmNumberObj      = state.frmNumberObj;
		let newSalesPersonIdsObj = state.salesPersonIdsObj;
		let oldAssignObj         = state.assignObj;
		let newAssignObj         = state.assignObj;

		oldAssignObj.map((value, i) => {
			setValue(`assign[${i}].frm_number`, "");
			setValue(`assign[${i}].to_number`, "");
			setValue(`assign[${i}].sales_person`, "");
		});

		newToNumberObj.splice(index, 1);
        newFrmNumberObj.splice(index, 1);
        newSalesPersonIdsObj.splice(index, 1);
		newAssignObj.splice(index, 1);

        setState((prevState) => {
            return {
                ...prevState,
				frmNumberObj: newFrmNumberObj,
				toNumberObj: newToNumberObj,
				salesPersonIdsObj: newSalesPersonIdsObj,
				assignObj: newAssignObj,
            };
        });

        // newAssignObj.forEach(resetInputsValue);
		newAssignObj.map((value, i) => {
			setValue(`assign[${i}].frm_number`, state.frmNumberObj[i]?.value ? state.frmNumberObj[i].value : "");
			setValue(`assign[${i}].to_number`, state.toNumberObj[i]?.value ? state.toNumberObj[i].value : "");
			setValue(`assign[${i}].sales_person`, state.salesPersonIdsObj[i]?.value ? state.salesPersonIdsObj[i].value : "");
		});
	}

	const onSubmitHandler = handleSubmit(async () => {
		document.getElementById("splash-screen").classList.remove("hidden");
		let formData = new FormData();
		let err_msg = null;

		if (state.assignObj && state.assignObj.length > 0) {
			for (var index = 0; index < state.assignObj.length; index++) {
				if (!state.frmNumberObj[index] || !state.toNumberObj[index] || !state.salesPersonIdsObj[index]) {
					err_msg = "Please fill in all the input";
					break;
				}
				formData.append(`assign[${index}][frm_number]`, state.frmNumberObj[index]?.value ? state.frmNumberObj[index].value : 0);
				formData.append(`assign[${index}][to_number]`, state.toNumberObj[index]?.value ? state.toNumberObj[index].value : 0);
				formData.append(`assign[${index}][sales_person]`, state.salesPersonIdsObj[index]?.value ? state.salesPersonIdsObj[index].value : 0);
			}
		} else {
			err_msg = "Please assign at least 1 contact to 1 sales person";
		}

		if (err_msg != null) {
			enqueueSnackbar(err_msg, {
				variant: 'error',
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
			});
			document.getElementById("splash-screen").classList.add("hidden");
			return false;
		}

		const response = await postCreateForm({
			api: api,
			data: formData,
			method: "POST",
			url: "postAssignSalesPerson",
			enqueueSnackbar,
		});
		if (response) {
			if (response.status === 200) {
				enqueueSnackbar(response.data.data.msg, {
					variant: response.data.data.msgType,
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
				});
				document.getElementById("splash-screen").classList.add("hidden");
				if (response.data.data.msgType == "success") {
					navigate("/admin/customer-list");
				}
			}
		}
	});

	return (
		<>
			<BreadcrumbsContext.Provider value={[breadcrumbArr, setBreadcrumbArr]} >
				<CollapsedBreadcrumbs />
			</BreadcrumbsContext.Provider>
			<Card>
				<CardHeaderCss title="Assigning a salesperson to a list of contacts"/>
				<CardContent>
					<form>
						<Grid container spacing={2}>
							<Grid item xs={12} md={12}>
								<Alert severity="info">
									Total{" "}
									<Box
										component="span"
										fontWeight="fontWeightBold"
										
									>
										{state.unAssignRecords}
									</Box>{" "}
									records of contacts is no salesperson assigned.
								</Alert>
							</Grid>
						</Grid>
						{state.unAssignRecords > 0 ?
						state.assignObj.map((item, index) => {
							return (
								<div key={"assignObj_" + index}>
									<Grid container spacing={2}>
										<Grid item xs={2} md={2}>
											<TextFieldComponent
												control={control}
												name={`assign[${index}].frm_number`}
												placeholder="Number From"
												marginType="normal"
												label="Number From"
												type="text"
												// errors={errors}
												errors={errors.assign?.[index]?.frm_number ? errors.assign?.[index]?.frm_number : ""}
												setValue={setValue}
												onChangeHandler={onChangedFrmNumber(
													"frm_number", index
												)}
											/>
										</Grid>
										<Grid item xs={2} md={2}>
											<TextFieldComponent
												control={control}
												name={`assign[${index}].to_number`}
												placeholder="Number To"
												marginType="normal"
												label="Number To"
												type="text"
												// errors={errors}
												errors={errors.assign?.[index]?.to_number ? `errors.assign[${index}].to_number` : ""}
												setValue={setValue}
												onChangeHandler={onChangedToNumber(
													"to_number", index
												)}
											/>
										</Grid>
										<Grid item xs={2} md={2}>
											<SelectOptionComponent
												control={control}
												name={`assign[${index}].sales_person`}
												placeholder="Sales Person"
												label="Sales Person"
												option={state.salesPerson}
												// errors={errors}
												errors={errors.assign?.[index]?.sales_person ? errors.assign?.[index]?.sales_person : ""}
												setValue={setValue}
												onChangeHandler={onSelectChangeHandler(
													"sales_person", index
												)}
												value={state.salesPersonIdsObj?.[index]?.value ? state.salesPersonIdsObj[index].value : ""}
											/>
										</Grid>
										<Grid item xs={4} md={4} marginTop={3}>
											<Button
												type="button"
												// className="btn btn-icon btn-outline-danger mr-2"
												onClick={(e) => handleRemoveAssignRow(index)}>
												<i className="fas fa-trash-alt"></i>
											</Button>
										</Grid>
									</Grid>
								</div>
							);
						}) : ""}

						{state.unAssignRecords > 0 ?
						<Grid item xs={12} md={12} marginTop={2}>
							<Button
								variant="contained"
								color="primary"
								type="button"
								onClick={handleAddNextAssignPerson}
							>
								+ Add
							</Button>
						</Grid>
						: <Grid item xs={12} md={12} marginTop={2}>
							{/* <Link to="/customer-list" className="btn btn-primary">Back to Customer List</Link> */}
							<Button
								variant="contained"
								color="primary"
								type="button"
								onClick={handleRedirectCustomerList}
							>
								Back to Customer List
							</Button>
						</Grid>}
					</form>
				</CardContent>
			</Card>
            <AppBarSpacer/>
			{state.unAssignRecords > 0 ?
			<CardActions style={{ justifyContent: "center", marginBottom: "16px" }}>
				<Button
					variant="contained"
					color="primary"
					type="submit"
					onClick={onSubmitHandler}
				>
					Submit
				</Button>
			</CardActions>
			: ""}
		</>
	);
}
