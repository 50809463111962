import React from "react";
import DataTable from "react-data-table-component";
import customStyles from "./dt-main-style";
import { Typography } from "@mui/material";

export default function DataTable2(props) {
    const columns = [
        {
            name: "#",
            sortable: true,
            grow: 0,
            minWidth: "80px",
            cell: function (data, index) {
                return index + 1 + parseFloat(props.startFrom);
            },
        },
        {
            name: "Event Type",
            sortable: true,
            minWidth: "160px",
            selector: row => row.event_type,
        },
        {
            name: "Event Table",
            sortable: true,
            minWidth: "200px",
            selector: row => row.event_table,
        },
        {
            name: "Old Data",
            sortable: true,
            minWidth: "250px",
            selector: row => row.old,
            cell: (row) => {
                let str = [];
                let oldData = JSON.parse(row.old);

                if (oldData) {
                    Object.keys(oldData).map(function (key) {
                        str.push(
                            <div key={key} style={{ paddingBottom: "15px" }}>
                                {key}: {oldData[key]}
                            </div>
                        );
                    });
                }

                return (
                    <div key={row.id} className="row" style={{ paddingTop: "15px", width: "100%" }}>
                        {str}
                    </div>
                );
            },
        },
        {
            name: "New Data",
            sortable: true,
            minWidth: "250px",
            selector: row => row.new,
            cell: (row) => {
                let str = [];
                let newData = JSON.parse(row.new);

                if (newData) {
                    Object.keys(newData).map(function (key) {
                        str.push(
                            <div key={key} style={{ paddingBottom: "15px" }}>
                                {key}: {newData[key]}
                            </div>
                        );
                    });
                }

                return (
                    <div key={row.id} className="row" style={{ paddingTop: "15px", width: "100%" }}>
                        {str}
                    </div>
                );
            },
        },
        {
            name: "Affected Member",
            sortable: true,
            minWidth: "300px",
            selector: row => row.member_details,
        },
        {
            name: "Updated By",
            sortable: true,
            minWidth: "300px",
            selector: row => row.updated_by,
        },
        {
            name: "Updated Date",
            sortable: true,
            width: "160px",
            selector: row => row.updated_at,
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={props.fileteredData}
            customStyles={customStyles}
            noHeader
            pagination
            paginationServer
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50, 100, 150]}
            paginationTotalRows={props.totalRows}
            onChangeRowsPerPage={props.onChangeRowsPerPage}
            onChangePage={props.onChangePage}
            progressPending={props.pending}
        />
    );
}
