import React from "react";
import DataTable from "react-data-table-component";
import customStyles from "./dt-main-style";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton, Tooltip, Chip } from "@mui/material";

export default function DataTable2(props) {
    const status = {
        A: { class: "success" },
        I: { class: "error" },
    };

    const columns = [
        {
          name: "Actions",
          sortable: false,
          selector: row => row.nick_name,
          center: true,
          maxWidth: "100px",
          cell: (row) => (
            <>
              <Link
                to={{
                  pathname: "/admin/user/update",
                }}
                state={{ 
                    id: row.id 
                }}
              >
                <Tooltip title="Update Admin">
                  <IconButton color="primary">
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Link>
            </>
          ),
        },
        {
            name: "#",
            sortable: true,
            grow: 0,
            cell: function(data, index) {
                return index + 1 + parseFloat(props.startFrom);
            },
        },
        {
            name: "User Group",
            selector: row => row.sys_group_name,
            sortable: true,
        },
        {
            name: "Username",
            selector: row => row.nick_name,
            sortable: true,
        },
        {
            name: "Name",
            selector: row => row.name,
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row.email,
            sortable: true,
        },
        {
            name: "Status",
            selector: row => row.status,
            sortable: true,
            center: true,
            maxWidth: "120px",
            cell: (row) => (
                <Chip color={status[row["status"]].class} label={row["status_desc"]}></Chip>
            ),
        },
    ];

    function convertArrayOfObjectsToCSV() {
        let result;

        const columnDelimiter = ",";
        const lineDelimiter = "\n";

        const header = ["No", "User Group", "Username", "Name", "Email", "Status"];
        const keys = ["row_number", "sys_group_name", "nick_name", "name", "email", "status"];

        result = "";
        result += header.join(columnDelimiter);
        result += lineDelimiter;

        props.fileteredData.forEach(function(item) {
          let ctr = 0;
          keys.forEach(function(key) {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];
            ctr++;
          });

          result += lineDelimiter;
        });

        return result;
    }

    function downloadCSV() {
        const link = document.createElement("a");
        let csv = convertArrayOfObjectsToCSV();

        if (csv == null) return;

        const filename = "users-list.csv";

        if (!csv.match(/^data:text\/csv/i)) {
          csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    }

    const Export = ({ onExport }) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => onExport(e.target.value)}
        >
          Export
        </Button>
    );

    const actionsMemo = <Export onExport={() => downloadCSV()} />;

    return (
        <DataTable
            columns={columns}
            data={props.fileteredData}
            customStyles={customStyles}
            pagination
            paginationServer
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50, 100, 150]}
            paginationTotalRows={props.totalRows}
            onChangeRowsPerPage={props.onChangeRowsPerPage}
            onChangePage={props.onChangePage}
            progressPending={props.pending}
            actions={actionsMemo}
            export
        />
    );
}
