import React from "react";
import DataTable from "react-data-table-component";
import customStyles from "./dt-main-style";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { IconButton, Tooltip, Chip } from "@mui/material";

export default function DataTable2(props) {

  const status = {
    I: { class: "error" },
    A: { class: "success" },
  };

  const columns = [
    {
      name: "Actions",
      sortable: false,
      selector: row => row.country_id,
      center: true,
      maxWidth: "100px",

      cell: (row) => (

        <>
          <Link
            to={{
              pathname: "/admin/country/update",

            }}
            state={{
              country_id: row.country_id
            }}
          >
            <Tooltip title="Update Country">
              <IconButton color="primary">
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Link>
        </>
      ),
    },
    {
      name: "#",
      sortable: true,
      grow: 0,
      cell: function (data, index) {
        return index + 1 + parseFloat(props.startFrom);
      },
    },
    {
      name: "Code",
      sortable: true,
      minWidth: "100px",
      selector: row => row.code,
    },
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "NSN Length",
      selector: row => row.size_nsn,
      sortable: true,
      minWidth: "100px",
    },
    {
      name: "Calling Codes",
      selector: row => row.prefix_calling_code,
      sortable: true,
      minWidth: "100px",
    },
    {
      name: "Status",
      selector: row => row.status,
      sortable: true,
      center: true,
      maxWidth: "120px",
      cell: (row) => (
        <Chip color={status[row["status"]].class} label={row["status_desc"]}></Chip>
      ),
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={props.fileteredData}
      customStyles={customStyles}
      noHeader
      pagination
      paginationServer
      paginationPerPage={50}
      paginationRowsPerPageOptions={[50, 100, 150]}
      paginationTotalRows={props.totalRows}
      onChangeRowsPerPage={props.onChangeRowsPerPage}
      onChangePage={props.onChangePage}
      progressPending={props.pending}
    />
  );
}
