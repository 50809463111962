import { Controller } from "react-hook-form";
import React, { useContext, useEffect, useState, memo, useRef } from "react";
import { TextField } from "@mui/material";
import { ErrorMsgCss } from "../../css/Admin-css/listing-css";

export default function TextFieldComponent(props) {
	return (
		<>
			<Controller
				rules={props.rules}
				name={props.name}
				control={props.control}
				defaultValue=""
				label={props.label}
				render={({ field }) =>
					props.onChangeHandler ? (
						<TextField
							{...field}
							placeholder={props.placeholder}
							margin={props.marginType}
							variant="outlined"
							autoComplete="new-password"
							fullWidth
							label={props.label}
							helperText={props.helperText}
							type={props.type}
							error={props.errors[props.name] ? true : false}
							InputProps={
								props.InputProps ? props.InputProps : {}
							}
							disabled={props.disabled ? true : false}
							onChange={props.onChangeHandler}
						/>
					) : (
						<TextField
							{...field}
							placeholder={props.placeholder}
							margin={props.marginType}
							variant="outlined"
							autoComplete="new-password"
							fullWidth
							label={props.label}
							helperText={props.helperText}
							type={props.type}
							error={props.errors[props.name] ? true : false}
							InputProps={
								props.InputProps ? props.InputProps : {}
							}
							disabled={props.disabled ? true : false}
						/>
					)
				}
			/>
			{props.errors[props.name] &&
				(props.errors[props.name].type === "required" ||
					props.errors[props.name].type === "minLength" ||
					props.errors[props.name].type === "pattern") && (
						<ErrorMsgCss>
							<span>
								{props.errors[props.name].message
									? props.errors[props.name].message
									: "This field is required"}
							</span>
						</ErrorMsgCss>
				)}
			{props.errors[props.name] &&
				props.errors["aim_username"] &&
				props.errors[props.name].type === "validate" && (
					<ErrorMsgCss>
						<span>Invalid AIM Username </span>
					</ErrorMsgCss>
				)}
			{props.errors[props.name] &&
				(props.errors["password"] ||
					props.errors["confirm_password"]) &&
				props.errors[props.name].type === "validate" && (
					<ErrorMsgCss>
						<span>Password Must be Same! </span>
					</ErrorMsgCss>
				)}
		</>
	);
}
